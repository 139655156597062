import AdminComponent from "@/components/dashboard/AdminComponent";
import OSSearch from "@/views/search/OSSearch";
import OSCreate from "@/views/create/OSCreate";
import OSUpdate from "@/views/update/OSUpdate";

import OSCreateBath from "@/views/create/OSCreateBath";
import OSCreateBathService from "@/views/create/OSCreateBathService";
import OSUpdateBathService from "@/views/update/OSUpdateBathService";
import OSCreateDiscountPayment from "@/views/create/OSCreateDiscountPayment";
import OSCreateShipping from "@/views/create/OSCreateShipping";
import OSUpdateShipping from "@/views/update/OSUpdateShipping";
import OSCreateServiceGroup from "@/views/create/OSCreateServiceGroup";

export default {
    path: '/os',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'os-search', component: OSSearch},
        {path: 'create', name: 'os-create', component: OSCreate},
        {path: 'edit/:id', name: 'os-edit', component: OSUpdate, props: true},

        {path: 'create-bath/:id', name: 'os-bath-create', component: OSCreateBath, props: true},
        {path: 'create-shipping/:id', name: 'os-shipping-create', component: OSCreateShipping, props: true},
        {path: 'edit-shipping/:id', name: 'os-shipping-edit', component: OSUpdateShipping, props: true},
        {
            path: 'create-discount-payment/:id',
            name: 'os-create-discount-payment',
            component: OSCreateDiscountPayment,
            props: true
        },
        {
            path: 'create-bath-service/:id/:id_bath/:id_table',
            name: 'os-bath-service-create',
            component: OSCreateBathService,
            props: true
        },
        {
            path: 'update-bath-service/:id/:id_bath/:id_table/:id_service',
            name: 'os-bath-service-edit',
            component: OSUpdateBathService,
            props: true
        },
        {
            path: 'create-service-group/:id',
            name: 'os-service-group-create',
            component: OSCreateServiceGroup,
            props: true
        },
    ]
}