<template>
    <painel-component>
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Código:</label>
                    <input-component v-model="formData.code" :type="'text'" placeholder="Código"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" :type="'text'" placeholder="Nome"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectStatus" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Status"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Classificação:</label>
                    <Multiselect v-model="formData.classification" :options="multiselectClassification"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Classificação"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <button-submit @submit="submit" class="me-1" name="Pesquisar" icon="fa-solid fa-magnifying-glass"/>
                    <button-route v-if="buttonCheck.create" class="ms-1" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>

        <table class="table">
            <thead>
            <tr class="border-top">
                <th scope="col" style="width: 20%">Código</th>
                <th scope="col" style="width: 20%">Nome</th>
                <th scope="col" style="width: 20%">Criador</th>
                <th scope="col" style="width: 20%">Preço</th>
                <th scope="col" style="width: 10%">Ações</th>
            </tr>
            </thead>
            <tbody class="table-group-divider">
            <tr v-for="(data, index) in product.data" :key="index">
                <td data-label="Código">{{ data.code }}</td>
                <td data-label="Nome">{{ data.name }}</td>
                <td data-label="Criador">{{ data.creator_user_name }}</td>
                <td data-label="Preço">{{ data.price }}</td>
                <td data-label="Ações">
                    <button-edit route="product-edit" :id="data.id" v-if="buttonCheck.edit"/>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="product" :offset="3" @paginate="loadItems"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import Multiselect from "@vueform/multiselect";
import InputComponent from "@/components/forms/InputComponent";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonEdit from "@/components/buttons/ButtonEdit";
import {useToast} from "vue-toastification";
import ButtonPagination from "@/components/buttons/ButtonPagination";

const toast = useToast()
export default {
    name: 'ProductSearch',
    components: {
        ButtonPagination,
        ButtonEdit,
        ButtonSubmit,
        ButtonRoute,
        InputComponent,
        Multiselect,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Produto Pesquisa')
        this.$store.dispatch('loadMultiSelectClassification')
        this.checkACL()
        this.loadItems(1)
    },

    data() {
        return {
            itemsRoute: {
                name: 'Adicionar',
                route: '/product/create',
                icon: 'fa-solid fa-circle-plus'
            },

            formData: {
                code: '',
                name: '',
                status: '',
                classification: ''
            },

            buttonCheck: {
                create: false,
                edit: false
            },

            searchSubmit: false,
        }
    },

    methods: {

        submit() {
            this.loadItems(1)
            this.searchSubmit = true
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadProducts', {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data.length === 0) {
                                toast.info('Nada foi encontrado com os parâmetros informados!', {timeout: 1500})
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 15)
                .then((r) => {
                    if (r.data.indexOf('product-search') !== -1) {

                        if (r.data.indexOf('product-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                        if (r.data.indexOf('product-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        statusString(value) {
            if (parseInt(value) === 1)
                return "Ativo"
            return "Inativo"
        }

    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        selectStatus() {
            return this.$store.state.var.selectStatus
        },

        multiselectClassification() {
            return this.$store.state.var.multiselectClassification
        },

        product() {
            return this.$store.state.product.items
        },

        params() {
            return {
                page: this.product.current_page,
                code: this.formData.code,
                name: this.formData.name,
                status: this.formData.status,
                classification: this.formData.classification
            }
        }
    }
}
</script>