<template>
    <input class="form-control" :type="type" :value="modelValue"
           :maxlength="maxlength"
           @input="$emit('update:modelValue', $event.target.value)">
</template>

<script>
export default {
    name: 'InputComponent',

    props: {
        type: {
            type: String,
            default: 'text',
        },

        modelValue: {
            default: '',
        },

        maxlength: {
            default: ''
        }
    }
}
</script>

<style scoped>
input {
    height: 42px;
    outline: none;
    border-radius: 5px;
    padding-left: 10px;
    border: 1px solid #cdcdcd;
}

input:focus {
    box-shadow: none;
    border-color: #000;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
}
</style>