<template>
    <vue-easy-lightbox :visible="visibleRef" :imgs="this.formData.photo" :index="indexRef" @hide="onHide"/>

    <painel-component>
        <error-component :errors="errors" v-if="showError"/>
        <div class="alert alert-warning" role="alert" v-if="parseInt(formData.parts) === 0">
            Todas as peças já foram adicionados banho.
        </div>

        <form v-else>
            <div class="aux my-4 position-relative">
                <div class="btn-avatar-view">
                    <button-submit @click="showSingle" icon="fa-solid fa-camera-retro"/>
                </div>
                <div class="avatar-form rounded-0">
                    <img v-if="formData.photo_thumbnail" :src="formData.photo_thumbnail" class="avatar__image rounded-0" alt="">
                    <img v-else src="@/assets/avatar.png" class="avatar__image rounded-0" alt="">
                </div>
            </div>

            <div class="clearfix mb-3">
                <span class="badge text-bg-primary me-2">Tabela {{ table }}</span>
                <span class="badge text-bg-success me-2" v-if="formData.parts >= 1">
                    Peças disponíveis para banho: {{ formData.parts }}
                </span>

                <span class="badge text-bg-success" v-else>
                    Peças disponíveis para banho: 0
                </span>

                <div class="badge text-bg-warning float-end" style="padding:0 0 0 0.75rem">
                    Ouro dia: R${{ formData.priceOuro }}
                    <button type="button" @click="updateGoldPrice" class="btn ms-2"
                            style="background-color: #EDB300FF;">
                        <i class="fa-solid fa-rotate"></i>
                    </button>
                </div>
            </div>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>OS:</label>
                    <input-component v-model="formData.os" :type="'text'" disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.service_id}">
                    <label>Serviço:</label>
                    <Multiselect v-model="formData.service_id" :options="multiselectServiceWithTable"
                                 :noOptionsText="noOptionsText"
                                 @select="getServicePrice(formData.service_id);"
                                 @clear="clsGetServicePrice()"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.base_id}">
                    <label>Base:</label>
                    <Multiselect v-model="formData.base_id" :options="multiselectServiceOne"
                                 :noOptionsText="noOptionsText"
                                 @select="getServiceAddPrice(formData.base_id, 1)"
                                 @clear="clsGetServiceAddPrice(1)"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.cor_id}">
                    <label>Cor:</label>
                    <Multiselect v-model="formData.cor_id" :options="multiselectServiceTwo"
                                 :noOptionsText="noOptionsText"
                                 @select="getServiceAddPrice(formData.cor_id, 2)"
                                 @clear="clsGetServiceAddPrice(2)"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.verniz_id}">
                    <label>Verniz:</label>
                    <Multiselect v-model="formData.verniz_id" :options="multiselectServiceThree"
                                 @select="getServiceAddPrice(formData.verniz_id, 3)"
                                 @clear="clsGetServiceAddPrice(3)"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.the_amount}">
                    <label>Quantidade Original:</label>
                    <input-component v-model="the_amount" :type="'number'" disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.parts}">
                    <label>Quantidade Banho:</label>
                    <input-component @keyup="calcAll"
                                     v-model="formData.the_amount" :type="'number'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.kilo}">
                    <label>Peso:</label>
                    <input-component v-model="formData.kilo" :type="'text'" disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>Total Peso:</label>
                    <input-component v-model="formData.kilo_total" :type="'text'" disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.layer}">
                    <label>Milésimos:</label>
                    <input-component maxlength="4" @keyup="calcAll" v-money="money3" :disabled="show_ml !== '2'"
                                     v-model="formData.layer"
                                     :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.thousandth}">
                    <label>M.O.:</label>
                    <input-component maxlength="4" @keyup="calcAll" :disabled="show_mo !== '2'" v-money="money3"
                                     v-model="formData.thousandth"
                                     :type="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.price_base}">
                    <label>Preço Base:</label>
                    <input-component maxlength="12" v-money="money" @keyup="sumBase(); calcAll();"
                                     v-model="formData.price_base" :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.price_base_total}">
                    <label>Preço Total Base:</label>
                    <input-component maxlength="12" v-money="money" v-model="formData.price_base_total" :type="'text'"
                                     disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.price_cor}">
                    <label>Preço Cor:</label>
                    <input-component maxlength="12" v-money="money" @keyup="sumColor(); ; calcAll()"
                                     v-model="formData.price_cor" :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.price_cor_total}">
                    <label>Preço Total Cor:</label>
                    <input-component maxlength="12" v-money="money" v-model="formData.price_cor_total" :type="'text'"
                                     disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.price_verniz}">
                    <label>Preço Verniz:</label>
                    <input-component maxlength="12" v-money="money" @keyup="sumVerniz(); ; calcAll()"
                                     v-model="formData.price_verniz" :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.price_verniz_total}">
                    <label>Preço Total Verniz:</label>
                    <input-component maxlength="12" v-money="money" v-model="formData.price_verniz_total" :type="'text'"
                                     disabled/>
                </row-col-component>
            </row-component>

            <row-component>
                <hr>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.price_kilo}">
                    <label>Preço Serviço:</label>
                    <input-component maxlength="12" v-money="money" @keyup="calcAll();" v-model="formData.price_kilo"
                                     :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.price_bath_kg}">
                    <label>Custo Banho por KG:</label>
                    <input-component v-model="formData.price_bath_kg" :type="'text'" disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.price_ml_mo}">
                    <label>Custo Serviço (ML + MO):</label>
                    <input-component maxlength="12" v-money="money" v-model="formData.price_ml_mo" :type="'text'"
                                     disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.price_subtotal}">
                    <label>Subtotal:</label>
                    <input-component maxlength="12" v-money="money" v-model="formData.price_subtotal" :type="'text'"
                                     disabled/>
                </row-col-component>
                <hr>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @submit="submit" class="w-100" :disabled="sendoForm" name="Adicionar"
                                   icon="fa-solid fa-circle-plus"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route @click="routeBack" class="w-100" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>

        <div class="my-3">
            <label>Banhos Adicionados:</label>
        </div>

        <div v-for="(dataTwo, indexTwo) in services" :key="indexTwo">
            <div class="bg-body-secondary p-2 mb-3">

                <div class="d-flex justify-content-between align-items-center">
                    <div>
                        <strong>Serviço: {{ dataTwo.name_service }}</strong>
                    </div>
                </div>

                <hr>
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th scope="col" style="width: 15%">Base</th>
                        <th scope="col" style="width: 15%">Preço</th>
                        <th scope="col" style="width: 15%">Cor</th>
                        <th scope="col" style="width: 15%">Preço</th>
                        <th scope="col" style="width: 15%">Verniz</th>
                        <th scope="col" style="width: 10%">Preço</th>
                    </tr>
                    </thead>
                    <tbody class="table-group-divider">
                    <tr>
                        <td data-label="Base">{{ dataTwo.name_base }}</td>
                        <td data-label="Preço">R$ {{ dataTwo.price_base }}</td>
                        <td data-label="Cor">{{ dataTwo.name_cor }}</td>
                        <td data-label="Preço">R$ {{ dataTwo.price_cor }}</td>
                        <td data-label="Verniz">{{ dataTwo.name_verniz }}</td>
                        <td data-label="Preço">R$ {{ dataTwo.price_verniz }}</td>
                    </tr>
                    </tbody>
                </table>

                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th scope="col" style="width: 20%">Quantidade</th>
                        <th scope="col" style="width: 20%">Peso</th>
                        <th scope="col" style="width: 20%">Total Peso</th>
                        <th scope="col" style="width: 20%">Milésimos</th>
                        <th scope="col" style="width: 20%">M.O.</th>
                    </tr>
                    </thead>
                    <tbody class="table-group-divider">
                    <tr>
                        <td data-label="Quantidade">{{ dataTwo.the_amount }}</td>
                        <td data-label="Peso">{{ dataTwo.kilo }}</td>
                        <td data-label="Total Peso">{{ dataTwo.kilo_total }}</td>
                        <td data-label="Milésimos">{{ dataTwo.layer }}</td>
                        <td data-label="M.O.">{{ dataTwo.thousandth }}</td>
                    </tr>
                    </tbody>
                </table>

                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th scope="col" style="width: 20%">Preço do Serviço</th>
                        <th scope="col" style="width: 20%">Custo do Banho por KG</th>
                        <th scope="col" style="width: 30%">
                            Custo do Serviço (MO + ML)
                        </th>
                        <th scope="col" style="width: 10%">Subtotal</th>
                    </tr>
                    </thead>
                    <tbody class="table-group-divider">
                    <tr>
                        <td data-label="Preço do Serviço">{{ dataTwo.price_kilo }}</td>
                        <td data-label="Custo do Banho por KG">
                            {{ dataTwo.price_bath_kg }}
                        </td>
                        <td data-label="Custo do Serviço (MO + ML)">
                            {{ dataTwo.price_ml_mo }}
                        </td>
                        <td data-label="Subtotal">{{ dataTwo.price_subtotal }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <row-component v-if="parseInt(formData.parts) === 0">
            <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <button-route @click="routeBack" class="w-100" :items="itemsRoute"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {useToast} from "vue-toastification";
import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import VueEasyLightbox from "vue-easy-lightbox";
import {ref} from "vue";

const toast = useToast()
export default {
    name: 'OSCreateBathService',
    components: {
        VueEasyLightbox,
        ErrorComponent,
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        money: VMoney
    },

    props: {
        id: {
            required: true
        },

        id_bath: {
            required: true
        },

        id_table: {
            required: true
        }
    },

    created() {
        this.loadAll()
    },

    setup() {
        const visibleRef = ref(false)
        const indexRef = ref(0) // default 0

        const onShow = () => {
            visibleRef.value = true
        }
        const showSingle = () => {
            onShow()
        }
        const onHide = () => (visibleRef.value = false)

        return {
            visibleRef,
            indexRef,
            showSingle,
            onHide
        }
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                // route: `/os/edit/${this.id}`,
                route: `/os/edit/${this.id}`,
                icon: 'fa-solid fa-rotate-left'
            },

            value: '',

            formData: {
                os: '',
                id: this.id_bath,
                service_id: '',
                kilo: '',
                the_amount: '',
                parts: '',
                thousandth: '',
                layer: '',
                price_kilo: '',
                price_result: '',
                base_id: '',
                cor_id: '',
                verniz_id: '',
                price_base: '',
                priceOuro: '',
                photo: '',
                kilo_total: '',
                photo_thumbnail: '',
                price_base_total: 0,
                price_cor: '',
                price_cor_total: 0,
                price_verniz: '',
                price_verniz_total: '',
                price_bath_kg: 0,
                price_ml_mo: 0,
                price_subtotal: 0
            },

            money: MONEY,
            money3: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 1,
                allowBlank: false,
                max: 99,
                minimumNumberOfCharacters: 3,
                masked: true /* doesn't work with directive */
            },

            table: '',
            totalPeso: '',
            otherTotalPeso: '',
            the_amount: '',
            show_ml: '',
            show_mo: '',

            services: [],

            errors: [],
            sendoForm: false,
            showError: false
        }
    },

    methods: {
        routeBack(){
            this.$router.push({name: 'os-edit', params: {id: this.id}})
            this.$store.commit("CHECKED_TAB", true)
            this.$store.commit("SECTION_ID", this.id_bath)
            console.log(this.id_bath)
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeOrderServiceBathService', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro efetuado com sucesso', {timeout: 1500})
                        this.$store.commit("CHECKED_TAB", true)
                        this.$store.commit("SECTION_ID", this.id_bath)
                        this.clearAll()
                        this.loadAll()
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        loadItems() {
            this.$store.dispatch('loadOrderServiceOsBath', this.id_bath)
                .then((r) => {
                    this.formData.os = r.os
                    this.formData.priceOuro = r.priceOuro
                    this.formData.kilo = r.kilo
                    this.formData.parts = r.parts
                    this.formData.the_amount = r.parts
                    this.formData.photo = r.photo
                    this.formData.kilo_total = r.kilo_total
                    this.formData.photo_thumbnail = r.photo_thumbnail
                    this.the_amount = r.the_amount
                    this.table = r.table
                    this.services = r.service
                }).catch(() => {
                toast.error("Erro desconhecido", {timeout: 1500})
            })
        },

        loadAll() {
            this.$store.dispatch('updateNamePage', 'OS Adicionar Serviço')
            this.$store.dispatch('loadMultiSelectServiceWithTable', this.id_table)
            this.$store.dispatch('loadMultiSelectServiceOne')
            this.$store.dispatch('loadMultiSelectServiceTwo')
            this.$store.dispatch('loadMultiSelectServiceThree')
            this.loadItems()
        },

        getServicePrice(id) {
            this.$store.dispatch('loadGetServicePrice', id)
                .then((r) => {
                    this.formData.price_kilo = r.price
                    this.formData.thousandth = r.mo_value
                    this.formData.layer = r.ml_value
                    this.show_ml = r.show_ml
                    this.show_mo = r.show_mo
                })
            setTimeout(() => {
                this.calcAll()
            }, 1000)
        },

        getServiceAddPrice(id, type) {
            this.$store.dispatch('loadGetServiceAddPrice', id)
                .then((r) => {
                    if (type === 1) {
                        this.formData.price_base = r
                        this.sumBase()
                        this.calcAll()
                    }
                    if (type === 2) {
                        this.formData.price_cor = r
                        this.sumColor()
                        this.calcAll()
                    }
                    if (type === 3) {
                        this.formData.price_verniz = r
                        this.sumVerniz()
                        this.calcAll()
                    }
                })
        },

        clsGetServicePrice() {
            this.formData.service_id = ''
            this.formData.price_kilo = ''
        },

        clsGetServiceAddPrice(type) {
            if (type === 1) {
                this.formData.base_id = ''
                this.formData.price_base = ''
            }

            if (type === 2) {
                this.formData.cor_id = ''
                this.formData.price_cor = ''
            }

            if (type === 3) {
                this.formData.verniz_id = ''
                this.formData.price_verniz = ''
            }
        },

        // Calcular todos
        calcAll() {
            this.sumTotal()
            this.sumTotalKG()
            this.sumService()
            this.sumSubTotal()
            this.sumBase()
            this.sumColor()
            this.sumVerniz()
        },

        // Total Peso
        sumTotal() {
            let n1 = this.formData.kilo.replace(/[.,]/g, '')
            let n2 = n1 * this.formData.the_amount

            if (parseFloat(this.formData.price_base) > 0)
                this.sumBase()

            if (parseFloat(this.formData.price_cor) > 0)
                this.sumColor()

            if (parseFloat(this.formData.price_verniz) > 0)
                this.sumVerniz()

            return this.formData.kilo_total = this.kilo(this.pad(n2, 7))
        },

        // Calcular serviços adicionais
        sumBase() {
            let n1 = this.formData.price_base
            let n2 = this.formData.kilo_total
            let result = ''

            if (n1.length < 8) {
                result = parseFloat(n1.replace(',', '.')) * parseFloat(n2.replace(',', '')) / 1000;
            } else {
                result = parseFloat(n1.replace(',', '')) * parseFloat(n2.replace(',', ''))
            }

            result = result.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return this.formData.price_base_total = result
        },

        sumColor() {
            let n1 = this.formData.price_cor
            let n2 = this.formData.kilo_total
            let result = ''

            if (n1.length < 8) {
                result = parseFloat(n1.replace(',', '.')) * parseFloat(n2.replace(',', '')) / 1000;
            } else {
                result = parseFloat(n1.replace(',', '')) * parseFloat(n2.replace(',', ''))
            }

            result = result.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return this.formData.price_cor_total = result
        },

        sumVerniz() {
            let n1 = this.formData.price_verniz
            let n2 = this.formData.kilo_total
            let result = ''

            if (n1.length < 8) {
                result = parseFloat(n1.replace(',', '.')) * parseFloat(n2.replace(',', '')) / 1000;
            } else {
                result = parseFloat(n1.replace(',', '')) * parseFloat(n2.replace(',', ''))
            }

            result = result.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });

            return this.formData.price_verniz_total = result
        },

        // Calcular custo banho por KG
        sumTotalKG() {
            let mo = this.formData.thousandth.replace(',', '.')
            let ml = this.formData.layer.replace(',', '.')
            let kg = this.formData.price_kilo.replace(/[.,]/g, '')

            if (parseFloat(mo) > 0 || parseFloat(ml) > 0) {
                let n1 = parseFloat(mo) + parseFloat(ml)
                return this.formData.price_bath_kg = ((kg * n1) / 100).toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            } else {
                return this.formData.price_bath_kg = (kg / 100).toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });
            }
        },

        // Calcular custo serviço (ML + MO):
        sumService() {
            let price_kg_real = this.formData.price_bath_kg;
            let price_kg;
            let price_kg_real_formact = price_kg_real.replace(/[.,]/g, '');

            if (parseInt(price_kg_real_formact) > parseInt('99999')) {
                price_kg = parseFloat(price_kg_real.replace(',', ''));
            } else {
                price_kg = parseFloat(price_kg_real.replace(',', '.')) / 1000;
            }

            let n1 = this.formData.kilo_total
            let total_kg;
            if (n1.length < 8) {
                total_kg = parseFloat(n1.replace(',', '.'));
            } else {
                total_kg = parseFloat(n1.replace(',', ''))
            }

            let result = (price_kg * total_kg)
            return this.formData.price_ml_mo = result.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },

        // Calcular subtotal
        sumSubTotal() {
            let n1 = parseFloat(this.formData.price_ml_mo.replace(/\./g, '').replace(',', '.'))
            let n2 = parseFloat(this.formData.price_base_total.replace(/\./g, '').replace(',', '.'))
            let n3 = parseFloat(this.formData.price_cor_total.replace(/\./g, '').replace(',', '.'))
            let n4 = parseFloat(this.formData.price_verniz_total.replace(/\./g, '').replace(',', '.'))
            let value = n1 + (n2 + n3 + n4)

            return this.formData.price_subtotal = value.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },

        pad(str, max) {
            str = str.toString();
            str = str.length < max ? this.pad("0" + str, max) : str; // zero à esquerda
            str = str.length > max ? str.substring(0, max) : str; // máximo de caracteres
            return str;
        },

        kilo(v) {
            v = v.replace(/(\d{2})(\d{1,2})$/, "$1.$2") //Coloca um hífen entre o terceiro e o quarto dígitos
            v = v.replace(/(\d{2})(\d)/, "$1,$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
            return v
        },

        updateGoldPrice() {
            this.$store.dispatch('loadOrderService', this.id)
                .then(r => {
                    this.formData.priceOuro = r.priceOuro
                    this.formData.price_kilo = r.priceOuro
                    this.calcAll()
                })
        },

        clearAll(){
            this.formData =  {
                os: '',
                id: this.id_bath,
                service_id: '',
                kilo: '',
                the_amount: '',
                parts: '',
                thousandth: '',
                layer: '',
                price_kilo: '',
                price_result: '',
                base_id: '',
                cor_id: '',
                verniz_id: '',
                price_base: '',
                priceOuro: '',
                photo: '',
                kilo_total: '',
                photo_thumbnail: '',
                price_base_total: 0,
                price_cor: '',
                price_cor_total: 0,
                price_verniz: '',
                price_verniz_total: '',
                price_bath_kg: 0,
                price_ml_mo: 0,
                price_subtotal: 0
            }
        }
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        multiselectServiceWithTable() {
            return this.$store.state.var.multiselectServiceWithTable
        },

        multiselectServiceTwo() {
            return this.$store.state.var.multiselectServiceTwo
        },

        multiselectServiceThree() {
            return this.$store.state.var.multiselectServiceThree
        },

        multiselectServiceOne() {
            return this.$store.state.var.multiselectServiceOne
        },
    }
}
</script>

<style scoped>
.avatar-form::before,
.avatar-form::after {
    display: none;
}

.avatar-form {
    width: 115px;
    height: 150px;
}

.btn-avatar-view {
    opacity: 0;
    width: 115px;
    z-index: 100;
    height: 150px;
    display: grid;
    position: absolute;
    place-items: center;
    transition: all 0.2s ease-in-out;
    background-color: rgba(0, 0, 0, 0.3);
}

.btn-avatar-view:hover {
    opacity: 1;
}

@media (max-width: 428px) {
    .avatar-form {
        width: 115px;
        height: 150px;
    }

    .avatar-form img {
        height: 150px;
        display: block;
    }
}
</style>