<template>
    <div class="modal-window top-50 start-50 translate-middle" v-if="modal">
        <entrance-exit-show :id="id_modal" v-if="modal" @close-modal="close"/>
    </div>

    <painel-component>
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Código</label>
                    <input-component v-model="formData.code" :type="'text'" placeholder="Código"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Mês:</label>
                    <Multiselect v-model="formData.month" :options="multiselectMonth" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Mês"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Ano:</label>
                    <Multiselect v-model="formData.year" :options="multiselectYear()" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Ano"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Conta Bancária:</label>
                    <Multiselect label="name" v-model="formData.bank_accounts_id" :options="multiselectAccountBank"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText" :searchable="true"
                                 placeholder="Conta Bancária"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Tipo de Conta:</label>
                    <Multiselect v-model="formData.account_types_id" :options="multiselectAccountTypeAPI"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Tipo de Conta"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Entrada/Saida:</label>
                    <Multiselect v-model="formData.type" :options="multiselectEntranceExit"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Entrada/Saida"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Antecipado:</label>
                    <Multiselect v-model="formData.attended" :options="multiselectYesNo"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Antecipado"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4 mt-xl-0">
                    <button-submit @submit="submit" class="me-1" name="Pesquisar" icon="fa-solid fa-magnifying-glass"/>
                    <button-route v-if="buttonCheck.create" class="ms-1" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>

        <table class="table mt-3">
            <thead>
            <tr class="border-top">
                <th scope="col" style="width: 10%">Código</th>
                <th scope="col" style="width: 10%">Conta Bancária</th>
                <th scope="col" style="width: 10%">Tipo de Conta <br> Tipo de Sub Conta</th>
                <th scope="col" style="width: 10%">Preço</th>
                <th scope="col" style="width: 10%">Entrada/Saída</th>
                <th scope="col" style="width: 10%">Data de Vencimento <br> Data de Efetivação</th>
                <th scope="col" style="width: 5%">Ações</th>
            </tr>
            </thead>
            <tbody class="table-group-divider">
            <tr v-for="(data, index) in items.data" :key="index">
                <td data-label="Código">{{ data.code }}</td>
                <td data-label="Conta Bancária">{{ data.bank_accounts_name }}</td>
                <td data-label="Tipo de Conta / Tipo de Sub Conta">
                    {{ data.account_types_name }} <br> {{ data.account_types_sub_name }}
                </td>
                <td data-label="Preço">{{ data.price_paid }}</td>
                <td data-label="Entrada/Saída">{{ typeString(data.type) }}</td>
                <td data-label="Data de Vencimento / Data de Efetivação">
                    {{ data.due_date }} <br> {{ data.effective_date }}
                </td>
                <td data-label="Ações">
                    <button-submit tooltip="Ver" @click="open(data.id)" class="bg-secondary border-secondary" @submit="submit"
                                   icon="fa-solid fa-eye"/>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="items" :offset="3" @paginate="loadItems"/>
    </painel-component>

</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import Multiselect from "@vueform/multiselect";
import {useToast} from "vue-toastification";
import InputComponent from "@/components/forms/InputComponent";
import EntranceExitShow from "@/views/show/EntranceExitShow.vue";
import ButtonPagination from "@/components/buttons/ButtonPagination";

const toast = useToast()
export default {
    name: 'EntranceExitSearch',
    components: {
        ButtonPagination,
        EntranceExitShow,
        InputComponent,
        Multiselect,
        ButtonRoute,
        ButtonSubmit,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Entrada/Saída Pesquisa')
        this.$store.dispatch('loadMultiSelectAccountBank')
        this.$store.dispatch('loadMultiSelectAccountType')
        this.checkACL()
        this.loadItems()
    },

    data() {
        return {
            itemsRoute: {
                name: 'Adicionar',
                route: '/entrance-exit/create',
                icon: 'fa-solid fa-circle-plus'
            },

            formData: {
                month: '',
                year: this.currentYear(),
                code: '',
                bank_accounts_id: '',
                account_types_id: '',
                type: '',
                business: '',
                attended: ''
            },

            buttonCheck: {
                create: false
            },

            items: {},

            id_modal: '',
            modal: false,

            searchSubmit: false,
        }
    },

    methods: {

        submit() {
            this.loadItems(1)
            this.searchSubmit = true
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadEntriesDepartures', {...this.params, page})
                    .then((r) => {
                        this.items = r.itens

                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (this.items.data.length === 0) {
                                toast.info('Nada foi encontrado com os parâmetros informados!', {timeout: 1500})
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 12)
                .then((r) => {
                    if (r.data.indexOf('entrance-exit-search') !== -1) {
                        if (r.data.indexOf('entrance-exit-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        multiselectYear() {
            let years = []
            let date = new Date()
            let year = date.getFullYear() + 2
            for (let i = 2020; i <= year; i++) {
                years.push(i)
            }
            return years
        },

        currentYear() {
            let date = new Date()
            return date.getFullYear()
        },

        typeString(value) {
            if (parseInt(value) === 1)
                return 'Entrada'
            return 'Saida'
        },

        open(id) {
            this.id_modal = id
            this.modal = true
        },

        close() {
            this.modal = false
        }
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        multiselectMonth() {
            return this.$store.state.var.multiselectMonth
        },

        multiselectEntranceExit() {
            return this.$store.state.var.multiselectEntranceExit
        },

        multiselectAccountBank() {
            return this.$store.state.var.multiselectAccountBank
        },

        multiselectAccountTypeAPI() {
            return this.$store.state.var.multiselectAccountTypeAPI
        },

        multiselectYesNo() {
            return this.$store.state.var.multiselectYesNo
        },

        params() {
            return {
                page: this.items.current_page,
                month: this.formData.month,
                year: this.formData.year,
                code: this.formData.code,
                bank_accounts_id: this.formData.bank_accounts_id,
                account_types_id: this.formData.account_types_id,
                type: this.formData.type,
                business: this.formData.business,
                attended: this.formData.attended
            }
        }
    }
}
</script>

<style scoped>

.modal-window {
    width: 50%;
    z-index: 11;
    position: fixed;
    min-height: 40%;
    overflow-y: auto;
    padding: 60px 20px 0;
    background-color: #FFFFFF;
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

@media (max-width: 1366px) {
    .modal-window {
        width: 60%;
        height: 70%;
    }
}

@media (max-width: 991px) {
    .modal-window {
        width: 90%;
        height: 70%;
    }
}

@media (max-width: 428px) {
    .modal-window {
        width: 95%;
        height: 70%;
    }
}

</style>