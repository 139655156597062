import AdminComponent from "@/components/dashboard/AdminComponent";
import ShippingSearch from "@/views/search/ShippingSearch";
import ShippingCreate from "@/views/create/ShippingCreate";
import ShippingUpdate from "@/views/update/ShippingUpdate";
import ShippingShow from "@/views/show/ShippingShow";

export default {
    path: '/shipping',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'shipping-search', component: ShippingSearch},
        {path: 'create/:id', name: 'shipping-create', props: true, component: ShippingCreate},
        {path: 'edit/:id', name: 'shipping-edit', props: true, component: ShippingUpdate},
        {path: 'show/:id', name: 'shipping-show', props: true, component: ShippingShow}
    ]
}