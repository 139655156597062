export default {
    name: 'Administrativo',
    icon: 'fa-regular fa-building',
    subLinks: [
        {
            name: 'Base/Cor/Verniz',
            route: '/additional-service/search',
            permissions: [
                "service-add-search",
                "service-add-create",
                "service-add-edit",
                "service-add-delete"
            ]
        },
        {
            name: 'Cadastro de OS',
            route: '/os/search',
            permissions: [
                "order-service-search",
                "order-service-create",
                "order-service-edit",
                "order-service-delete"
            ]
        },
        {
            name: 'Cliente',
            route: '/client/search',
            permissions: [
                "client-search",
                "client-create",
                "client-edit",
                "client-delete",
            ]
        },
        {
            name: 'Estoque',
            route: '/stock/search',
            permissions: []
        },
        {
            name: 'Estoque Cliente',
            route: '/stock-client/search',
            permissions: [
                "stock-client-search",
                "stock-client-create",
                "stock-client-edit",
                "stock-client-delete",
            ]
        },
        {
            name: 'Fornecedor',
            route: '/provider/search',
            permissions: [
                "provider-search",
                "provider-create",
                "provider-edit",
                "provider-delete",
            ]
        },
        {
            name: 'Funcionário',
            route: '/employee/search',
            permissions: [
                "employee-search",
                "employee-create",
                "employee-edit",
                "employee-delete",
            ]
        },
        {
            name: 'Grupo de Banho',
            route: '/bath-group/search',
            permissions: [
                "bath-group-search",
                "bath-group-create",
                "bath-group-edit",
                "bath-group-delete"
            ]
        },
        {
            name: 'Representante',
            route: '/guide/search',
            permissions: [
                "guide-search",
                "guide-create",
                "guide-edit"
            ]
        },
        {
            name: 'Serviço',
            route: '/service/search',
            permissions: [
                "service-search",
                "service-create",
                "service-edit",
                "service-delete"
            ]
        },
        {
            name: 'Usuário',
            route: '/user/search',
            permissions: [
                "user-search",
                "user-create",
                "user-edit",
                "user-delete",
            ]
        },
        {
            name: 'Grupo de Usuário',
            route: '/user-group/search',
            permissions: [
                "group-user-search",
                "group-user-create",
                "group-user-edit",
                "group-user-delete",
            ]
        },
    ],
    pagePermissions: [
        "service-add-search",
        "service-add-create",
        "service-add-edit",
        "service-add-delete",
        "order-service-search",
        "order-service-create",
        "order-service-edit",
        "order-service-delete",
        "provider-search",
        "provider-create",
        "provider-edit",
        "provider-delete",
        "employee-search",
        "employee-create",
        "employee-edit",
        "employee-delete",
        "bath-group-search",
        "bath-group-create",
        "bath-group-edit",
        "bath-group-delete",
        "group-user-search",
        "group-user-create",
        "group-user-edit",
        "group-user-delete",
        "client-search",
        "client-create",
        "client-edit",
        "client-delete",
        "service-search",
        "service-create",
        "service-edit",
        "service-delete",
        "user-search",
        "user-create",
        "user-edit",
        "user-delete",
        "guide-search",
        "guide-create",
        "guide-edit",
        "stock-client-search",
        "stock-client-create",
        "stock-client-edit",
        "stock-client-delete",
    ]
}