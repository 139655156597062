import axios from "axios";
import {URL_BASE} from "@/configs/constants";

export default {
    updateNamePage(context, namePage) {
        context.commit('UPDATE_NAME_PAGE', namePage)
    },

    loadSelectPermissions(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}page`)
                .then((response) => {
                    context.commit('UPDATE_SELECT_PERMISSION', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiSelectUserGroups(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}group-list`)
                .then((response) => {
                    context.commit('UPDATE_MULTISELECT_USER_GROUPS', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiSelectClient(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}client-list`)
                .then((response) => {
                    context.commit('UPDATE_MULTISELECT_CLIENT', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiSelectEmployee(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}employee-list`)
                .then((response) => {
                    context.commit('UPDATE_MULTISELECT_EMPLOYEE', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiSelectProvider(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}provider-list`)
                .then((response) => {
                    context.commit('UPDATE_MULTISELECT_PROVIDER', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiSelectAccountBank(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}account-bank-list`)
                .then((response) => {
                    context.commit('UPDATE_MULTISELECT_ACCOUNT_BANK', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiSelectPaymentMethods(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}payment-mehtods-list`)
                .then((response) => {
                    context.commit('UPDATE_MULTISELECT_PAYMENT_METHODS', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiSelectAccountType(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}account-type-list`)
                .then((response) => {
                    context.commit('UPDATE_MULTISELECT_ACCOUNT_TYPE', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiSelectAccountTypeSub(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}account-type-sub-list/${id}`)
                .then((response) => {
                    context.commit('UPDATE_MULTISELECT_ACCOUNT_TYPE_SUB', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiSelectClassification(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}product-list-classification`)
                .then((response) => {
                    context.commit('UPDATE_MULTISELECT_CLASSIFICATION', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiSelectProduct(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}product-list`)
                .then((response) => {
                    context.commit('UPDATE_MULTISELECT_PRODUCT', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiSelectBathGroup(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}bath-group-list`)
                .then((response) => {
                    context.commit('UPDATE_BATH_GROUP', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiSelectService(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}service-list/${id}`)
                .then((response) => {
                    context.commit('UPDATE_SERVICE', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiSelectServiceWithTable(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}service-list/1/${id}`)
                .then((response) => {
                    context.commit('UPDATE_SERVICE_WITH_TABLE', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiSelectServiceOne(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}service-add-list/1`)
                .then((response) => {
                    context.commit('UPDATE_SERVICE_ONE', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiSelectServiceTwo(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}service-add-list/2`)
                .then((response) => {
                    context.commit('UPDATE_SERVICE_TWO', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiSelectServiceThree(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}service-add-list/3`)
                .then((response) => {
                    context.commit('UPDATE_SERVICE_THREE', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadGetServicePrice(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}order-service-get-service-price/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadGetServiceAddPrice(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}order-service-get-service-add-price/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadClientListEmail(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}client-list-email/${id}`)
                .then((response) => {
                    context.commit("UPDATE_CLIENT_LIST_EMAIL", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadMultiSelectGuide(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}guide-list`)
                .then((response) => {
                    context.commit("UPDATE_MULTISELECT_GUIDE", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

}