<template>
    <painel-component>
        <error-component v-if="showError" :errors="errors"/>

        <form v-if="viewPage">
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.name}">
                    <label>Nome:</label>
                    <input-component :type="'text'" v-model="formData.name"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.status}">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectStatus" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{'formError' : errors.permissions}"
                                   v-for="(permission, index) in selectPermissions" :key="index">
                    <div class="form-check bg-secondary-subtle mb-2">
                        <input class="form-check-input" type="checkbox" :id="'checkBoxMaster' + index" :name="index"
                               @click="checkAll(index)">
                        <label class="form-check-label">{{ permission.label }}</label>
                    </div>

                    <div class="form-check mb-2" v-for="(option, indexTwo) in permission.options" :key="indexTwo">
                        <input class="form-check-input" type="checkbox" :value="option.value" :id="indexTwo"
                               :class="'form-check-input checkBoxAll' + index + ' checkOne' + option.value"
                               @click="checkOne(index, permission)">
                        <label class="form-check-label">{{ option.label }}</label>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" @submit="submit" class="w-100" name="Adicionar"
                                   icon="fa-solid fa-circle-plus"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import InputComponent from "@/components/forms/InputComponent";
import RowColComponent from "@/components/row/RowColComponent";
import Multiselect from "@vueform/multiselect";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: 'UserGroupCreate',
    components: {
        ErrorComponent,
        ButtonSubmit,
        ButtonRoute,
        Multiselect,
        RowColComponent,
        InputComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Grupo Adicionar')
        this.$store.dispatch('loadSelectPermissions')
        this.checkACL()
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: '/user-group/search',
                icon: 'fa-solid fa-rotate-left'
            },

            formData: {
                name: '',
                status: '',
                permissions: []
            },

            viewPage: false,

            errors: [],
            sendForm: false,
            showError: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeUserGroup', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro efetuado com sucesso', {timeout: 1500})
                        this.$router.push({name: 'user-group-edit', params: {id: r.id}})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 1)
                .then((r) => {
                    if (r.data.indexOf('group-user-create') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        checkOne(index, permission) {
            let listPermission = document.getElementsByClassName("checkBoxAll" + index);
            let cont = 0;
            for (let i = 0; i < listPermission.length; ++i) {
                if (listPermission[i].checked) {
                    listPermission[i].checked = true;
                    if (!this.formData.permissions.includes(parseInt(listPermission[i].value))) {
                        this.formData.permissions.push(parseInt(listPermission[i].value))
                    }
                    cont = cont + 1;
                } else {
                    listPermission[i].checked = false;
                    let indexArray = this.formData.permissions.indexOf(parseInt(listPermission[i].value));
                    if (indexArray !== -1) {
                        this.formData.permissions.splice(indexArray, 1);
                    }
                }
            }
            document.getElementById("checkBoxMaster" + index).checked = cont >= permission.options.length;
        },

        checkAll(index) {
            let listPermission = document.getElementsByClassName("checkBoxAll" + index);

            if (document.getElementById("checkBoxMaster" + index).checked) {
                for (let i = 0; i < listPermission.length; ++i) {
                    listPermission[i].checked = true;
                    if (!this.formData.permissions.includes(parseInt(listPermission[i].value))) {
                        this.formData.permissions.push(parseInt(listPermission[i].value))
                    }
                }
            } else {
                for (let cont = 0; cont < listPermission.length; ++cont) {
                    listPermission[cont].checked = false;
                    let indexArray = this.formData.permissions.indexOf(parseInt(listPermission[cont].value));
                    if (indexArray !== -1) {
                        this.formData.permissions.splice(indexArray, 1);
                    }
                }
            }
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        selectStatus() {
            return this.$store.state.var.selectStatus
        },

        selectPermissions() {
            return this.$store.state.var.selectPermissions
        }
    }
}
</script>

<style scoped>
.form-check-input:checked{
    border-color: #9FA692;
    background-color: #9FA692;
}

.form-check-input:focus{
    border-color: #9FA692;
}

</style>