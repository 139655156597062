<template>
    <div class="modal-window top-50 start-50 translate-middle" v-if="modal">
        <div role="button" class="btn-close" @click="modal = !modal"></div>

        <row-component>
            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <div class="form-check form-check-inline">
                    <input v-model="formDataClient.type" class="form-check-input" type="radio" value="1" name="docs"
                           id="type-one">
                    <label class="form-check-label" for="type-one">CPF</label>
                </div>
                <div class="form-check form-check-inline">
                    <input v-model="formDataClient.type" class="form-check-input" type="radio" value="2" name="docs"
                           id="type-two" checked>
                    <label class="form-check-label" for="type-two">CNPJ</label>
                </div>
            </row-col-component>
        </row-component>

        <row-component>
            <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <label>Nome:</label>
                <input-component v-model="formDataClient.name" :type="'text'"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                               v-if="formDataClient.type === '2'">
                <label>CNPJ:</label>
                <input-component v-model="formDataClient.cnpj" :type="'text'"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" v-else>
                <label>CPF:</label>
                <input-component v-model="formDataClient.cpf" :type="'text'"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-0 mt-lg-4">
                <button-submit @submit="submitSearch" icon="fa-solid fa-magnifying-glass"/>
            </row-col-component>
        </row-component>

        <div class="mt-4">
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   v-for="(data, index) in order_service_client.data" :key="index">
                    <div class="d-flex align-items-center bg-body-secondary p-2 position-relative">
                        <div class="h-100 avatar__image__div">
                            <img class="avatar__image" v-if="data.photo" :src="data.photo" alt="">
                            <img class="avatar__image" v-else src="@/assets/avatar.png" alt="">
                        </div>
                        <div class="ms-3 w-75">
                            <p class="mb-1 text-truncate">{{ data.name }}</p>
                            <p class="mb-1">{{ data.cnpj }} {{ data.cpf }}</p>
                        </div>
                        <div class="position-absolute end-0 me-1 me-lg-3">
                            <button-submit @click="selectClient(data)" icon="fa-solid fa-plus"/>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <button-pagination :pagination="order_service_client" :offset="3" @paginate="loadItems"/>
        </div>
    </div>

    <painel-component>
        <error-component :errors="errors" v-if="showError"/>

        <form v-if="viewPage">
            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.client_id}">
                    <label>Cliente:</label>
                    <div class="input-group" @click="modal = true">
                        <input-component v-model="name" :type="'text'" disabled/>
                        <div role="button" class="input-group-text my-background">
                            <i class="text-white fa-solid fa-magnifying-glass"></i>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-4">
                    <button-submit :disabled="sendForm" @submit="submit" class="w-100" name="Adicionar"
                                   icon="fa-solid fa-circle-plus"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-4">
                    <button-route class="w-100" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import InputComponent from "@/components/forms/InputComponent";
import ButtonPagination from "@/components/buttons/ButtonPagination";

import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: 'StockClientCreate',
    components: {
        ButtonPagination,
        InputComponent,
        ErrorComponent,
        ButtonSubmit,
        ButtonRoute,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Estoque Adicionar Adicionar')
        this.$store.dispatch('loadMultiSelectClient')
        this.checkACL()
        this.loadItems(1)
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: '/stock-client/search',
                icon: 'fa-solid fa-rotate-left'
            },

            formData: {
                client_id: '',
            },

            name: '',

            formDataClient: {
                page: '',
                type: '2',
                name: '',
                cnpj: '',
                cpf: ''
            },

            viewPage: false,

            searchSubmit: false,

            modal: false,

            errors: [],
            sendForm: false,
            showError: false
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeStock', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro efetuado com sucesso', {timeout: 1500})
                        this.$router.push({name: 'stock-client-edit', params: {id: r}})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 21)
                .then((r) => {
                    if (r.data.indexOf('stock-client-create') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        submitSearch() {
            this.loadItems(1)
            this.searchSubmit = true
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadOrderServicesClients', {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data.length === 0) {
                                toast.info('Nada foi encontrado com order-service parâmetros informados!', {timeout: 1500})
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                })
            }
        },

        selectClient(data) {
            this.modal = false
            this.formData.client_id = data.id
            this.name = data.name
        }
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        multiselectClient() {
            return this.$store.state.var.multiselectClient
        },

        order_service_client() {
            return this.$store.state.order_service.clients
        },

        params() {
            return {
                page: this.order_service_client.current_page,
                type: this.formDataClient.type,
                name: this.formDataClient.name,
                cnpj: this.formDataClient.cnpj,
                cpf: this.formDataClient.cpf
            }
        }
    }
}
</script>

<style scoped>
.modal-window {
    z-index: 6;
    width: 80%;
    padding: 2%;
    height: 60%;
    overflow: auto;
    position: fixed;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 5px 10px 12px 0 #A6A6A6;
}

.btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}

.avatar__image__div {
    width: 10%;
}

.my-background {
    border-color: #9FA692;
    background-color: #9FA692;
}

@media (max-width: 991px) {
    .modal-window {
        width: 95%;
        height: 80%;
    }
}
</style>