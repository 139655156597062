<template>
    <div class="sub-account-modal top-50 start-50 translate-middle" v-if="sub_account_modal">
        <div role="button" class="btn-close" @click="closeModal"></div>
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors_modal.name}">
                    <label>Nome:</label>
                    <input-component v-model="sub_account.name" :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors_modal.status}">
                    <label>Status:</label>
                    <Multiselect v-model="sub_account.status" :options="selectStatus"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <button-submit v-if="is_post" @submit="submitSubAccount" icon="fa-solid fa-plus" name="Adicionar"/>
            <button-submit v-else @submit="submitSubAccountPut" icon="fa-solid fa-floppy-disk" name="Salvar"/>
        </form>
    </div>

    <painel-component>
        <error-component :errors="errors" v-if="showError"/>

        <div class="clearfix">
            <button-route class="my-2 float-end" :items="itemsAdd"/>
        </div>

        <div class="tabs effect-3" v-if="viewPage">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="fa-solid fa-circle-info"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="fa-solid fa-money-bill"></i>
                <p>Sub Conta</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">

                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.name}">
                                <label>Nome:</label>
                                <input-component v-model="formData.name" :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.status}">
                                <label>Status:</label>
                                <Multiselect v-model="formData.status" :options="selectStatus"
                                             :noOptionsText="noOptionsText"
                                             :noResultsText="noResultText" :searchable="true"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <label>Observações:</label>
                            <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.status}">
                                <text-area v-model="formData.obs" rows="5"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="alert alert-light" role="alert" v-if="formData.account_type_subs.length === 0">
                        Não há nada cadastrado
                    </div>

                    <div class="clearfix my-3">
                        <button-submit class="float-end" icon="fa-solid fa-plus" @submit="openPost"/>
                    </div>

                    <div class="bg-light p-3 my-3"
                         v-for="(data, index) in formData.account_type_subs" :key="index">
                        <form>
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-6 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component disabled v-model="data.name" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                                    <label>Status:</label>
                                    <Multiselect disabled v-model="data.status" :options="selectStatus"
                                                 :noOptionsText="noOptionsText" :noResultsText="noResultText"
                                                 :searchable="true"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-1 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                                    <div class="btn-group mt-4" role="group">
                                        <button-submit @click="openPut(data)" class="bg-secondary border-secondary"
                                                       icon="fa-solid fa-pen-to-square"/>
                                        <button-submit @click="destroy(data)" class="bg-secondary border-secondary"
                                                       icon="fa-solid fa-trash"/>
                                    </div>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>
                </section>

                <row-component>
                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-submit :disabled="sendForm" @submit="submit" class="w-100"
                                       icon="fa-solid fa-floppy-disk" name="Salvar"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-route class="w-100" :items="itemsRoute"/>
                    </row-col-component>
                </row-component>
            </div>
        </div>
    </painel-component>
</template>
<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import TextArea from "@/components/forms/TextArea";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {useToast} from "vue-toastification";
import swal from "sweetalert";

const toast = useToast()
export default {
    name: 'AccountTypeUpdate',
    components: {
        ErrorComponent,
        Multiselect,
        TextArea,
        ButtonSubmit,
        RowComponent,
        InputComponent,
        RowColComponent,
        ButtonRoute,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Tipo de Conta Editar')
        this.checkACL()
        this.loadItems()
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: '/account-type/search',
                icon: 'fa-solid fa-rotate-left'
            },

            itemsAdd: {
                name: '',
                route: '/account-type/create',
                icon: 'fa-solid fa-circle-plus'
            },

            formData: {
                name: '',
                status: '',
                obs: '',
                account_type_subs: []
            },

            sub_account: {
                account_type_id: this.id,
                name: "",
                status: ""
            },

            id_sub: '',
            is_post: true,
            errors_modal: [],
            sub_account_modal: false,

            viewPage: false,

            errors: [],
            sendForm: false,
            showError: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadAccountType', this.id)
                .then((r) => {
                    this.formData = r
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateAccountType', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro atualizado com sucesso', {timeout: 1500})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        submitSubAccount() {
            this.$store.dispatch('storeSubAccountType', this.sub_account)
                .then(() => {
                    this.sub_account_modal = false
                    this.loadItems()
                    this.sub_account = {id: this.id, name: '', status: ''}
                    this.errors_modal = []
                    toast.success('Cadastro adicionado com sucesso', {timeout: 1500})
                }).catch((e) => {
                this.errors_modal = e.response.data.errors
            })
        },

        submitSubAccountPut() {
            this.sub_account['id'] = this.id_sub
            this.$store.dispatch('updateSubAccountType', this.sub_account)
                .then(() => {
                    this.sub_account_modal = false
                    this.loadItems()
                    this.sub_account = {id: this.id, name: '', status: ''}
                    this.errors_modal = []
                    toast.success('Cadastro atualizado com sucesso', {timeout: 1500})
                }).catch((e) => {
                this.errors_modal = e.response.data.errors
            })
        },


        destroy(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name}, ${this.me.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroySubAccountType', item.id).then(() => {
                        this.loadItems(1)
                        toast.success('Cadastro deletado com sucesso', {timeout: 1500});
                    })
                        .catch(() => {
                            toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1500});
                        })
                }
            });
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 9)
                .then((r) => {
                    if (r.data.indexOf('account-type-edit') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        closeModal() {
            this.sub_account_modal = false
            this.id_sub = ''
            this.sub_account = {id: this.id, name: '', status: ''}
        },

        openPost() {
            this.is_post = true
            this.sub_account_modal = true
        },

        openPut(data) {
            this.is_post = false
            this.id_sub = data.id
            this.sub_account.name = data.name
            this.sub_account.status = data.status
            this.sub_account_modal = true
        }
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        selectStatus() {
            return this.$store.state.var.selectStatus
        },
    }
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.sub-account-modal {
    width: 40%;
    z-index: 10;
    height: auto;
    border-radius: 5px;
    position: absolute;
    padding: 40px 20px;
    background-color: #FFFFFF;
    box-shadow: 5px 10px 12px 0 #A6A6A6;
}

.sub-account-modal > .btn-close {
    top: 5px;
    right: 5px;
    position: absolute;
}

@media (max-width: 991px ) {
    .sub-account-modal {
        width: 90%;
    }
}
</style>