<template>
    <div class="modal-window top-50 start-50 translate-middle" v-if="modal">
        <div role="button" class="btn-close" @click="modal = !modal"></div>

        <row-component>
            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <div class="form-check form-check-inline">
                    <input v-model="formDataClient.type" class="form-check-input" type="radio" value="1" name="docs"
                           id="type-one">
                    <label class="form-check-label" for="type-one">CPF</label>
                </div>
                <div class="form-check form-check-inline">
                    <input v-model="formDataClient.type" class="form-check-input" type="radio" value="2" name="docs"
                           id="type-two" checked>
                    <label class="form-check-label" for="type-two">CNPJ</label>
                </div>
            </row-col-component>
        </row-component>

        <row-component>
            <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <label>Nome:</label>
                <input-component v-model="formDataClient.name" :type="'text'"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                               v-if="formDataClient.type === '2'">
                <label>CNPJ:</label>
                <input-component v-model="formDataClient.cnpj" :type="'text'"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" v-else>
                <label>CPF:</label>
                <input-component v-model="formDataClient.cpf" :type="'text'"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-0 mt-lg-4">
                <button-submit @submit="submitSearch" icon="fa-solid fa-magnifying-glass"/>
            </row-col-component>
        </row-component>

        <div class="mt-4">
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   v-for="(data, index) in order_service_client.data" :key="index">
                    <div class="d-flex align-items-center bg-body-secondary p-2 position-relative">
                        <div class="h-100 avatar__image__div">
                            <img class="avatar__image" v-if="data.photo" :src="data.photo" alt="">
                            <img class="avatar__image" v-else src="@/assets/avatar.png" alt="">
                        </div>
                        <div class="ms-3 w-75">
                            <p class="mb-1 text-truncate">{{ data.name }}</p>
                            <p class="mb-1">{{ data.cnpj }} {{ data.cpf }}</p>
                        </div>
                        <div class="position-absolute end-0 me-1 me-lg-3">
                            <button-submit @click="selectClient(data)" icon="fa-solid fa-plus"/>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <button-pagination :pagination="order_service_client" :offset="3" @paginate="loadItems"/>
        </div>
    </div>

    <painel-component>
        <form>
            <div class="clearfix mb-3">
                <div class="badge badge-counter float-end">Atualização em: {{ this.seconds }}</div>
            </div>

            <row-component>
                <row-col-component class-row-col="col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>OS:</label>
                    <input-component v-model="formData.os" :type="'text'" placeholder="OS"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-7 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Cliente:</label>
                    <div class="input-group mb-3" style="height: 42px">
                        <input v-model="name" disabled type="text" class="form-control" placeholder="Cliente">
                        <button-submit @click="modal = true" icon="fa-solid fa-magnifying-glass"/>
                    </div>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectStatus"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText" :searchable="true"
                                 placeholder="Status"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submit" icon="fa-solid fa-magnifying-glass" name="Pesquisar"/>
                </row-col-component>
            </row-component>
        </form>

        <table class="table" v-if="viewPage">
            <thead>
            <tr class="border-top">
                <th scope="col" style="width: 30%">OS</th>
                <th scope="col" style="width: 30%">Cliente</th>
                <th scope="col" style="width: 10%">Ações</th>
            </tr>
            </thead>
            <tbody class="table-group-divider">
            <tr v-for="(data, index) in object.data" :key="index">
                <td data-label="OS">{{ data.os }}</td>
                <td data-label="Cliente">{{ data.name_client }}</td>
                <td data-label="Ações">
                    <button-show :id="data.id" route="closure-show"/>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="object" :offset="3" @paginate="loadItems"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import InputComponent from "@/components/forms/InputComponent";
import Multiselect from "@vueform/multiselect";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import {useToast} from "vue-toastification";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonShow from "@/components/buttons/ButttonShow";

const toast = useToast()
export default {
    name: 'ClosureSearch',
    components: {
        ButtonShow,
        ButtonSubmit,
        ButtonPagination,
        RowColComponent,
        Multiselect,
        InputComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch('updateNamePage', 'OS Pesquisa (Fechamento)')
        this.checkACL()
        this.loadItems(1)

        this.interval = setInterval(() => {
            // Diminui o contador em 1 segundo
            this.seconds--;
            // Verifica se o contador chegou a 0
            if (this.seconds === 0) {
                // Para o contador
                clearInterval(this.interval);
                this.loadItems(1)
                // Reinicia o contador
                this.seconds = 90;
                // Inicia o contador novamente
                this.startCounter();
            }
        }, 1000);
    },

    data() {
        return {
            formData: {
                os: '',
                client_id: '',
                status: ''
            },

            formDataClient: {
                page: '',
                type: '2',
                name: '',
                cnpj: '',
                cpf: ''
            },

            name: '',
            modal: false,

            seconds: 90,
            viewPage: false,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadClosings', {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data.length === 0) {
                                toast.info('Nada foi encontrado com os parâmetros informados!', {timeout: 1500})
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                })
            }
        },

        loadClients(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadOrderServicesClients', {...this.params_two, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data.length === 0) {
                                toast.info('Nada foi encontrado com order-service parâmetros informados!', {timeout: 1500})
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 20)
                .then((r) => {
                    if (r.data.indexOf('os-close') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        selectClient(data) {
            this.modal = false
            this.formData.client_id = data.id
            this.name = data.name
        },

        startCounter() {
            // Inicia o contador novamente
            this.interval = setInterval(() => {
                // Diminui o contador em 1 segundo
                this.seconds--;
                // Verifica se o contador chegou a 0
                if (this.seconds === 0) {
                    // Para o contador
                    clearInterval(this.interval);
                    this.loadItems(1)
                    // Reinicia o contador
                    this.seconds = 90;
                    // Inicia o contador novamente
                    this.startCounter();
                }
            }, 1000); // A cada 1000ms (1 segundo)
        }
    },

    computed: {
        object() {
            return this.$store.state.closure.items
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        selectStatus() {
            return this.$store.state.var.selectStatus
        },

        params() {
            return {
                page: this.object.current_page,
                os: this.formData.os,
                client_id: this.formData.client_id,
                status: this.formData.status,
            }
        },

        order_service_client() {
            return this.$store.state.order_service.clients
        },

        params_two() {
            return {
                page: this.order_service_client.current_page,
                type: this.formDataClient.type,
                name: this.formDataClient.name,
                cnpj: this.formDataClient.cnpj,
                cpf: this.formDataClient.cpf
            }
        }
    },

    beforeUnmount() {
        clearInterval(this.interval)
    }
}
</script>

<style scoped>
.badge-counter {
    color: #FFFFFF;
    background-color: #9FA692;
}

.modal-window {
    z-index: 6;
    width: 80%;
    padding: 2%;
    height: 60%;
    overflow: auto;
    position: fixed;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 5px 10px 12px 0 #A6A6A6;
}

.btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}

.avatar__image__div {
    width: 10%;
}

@media (max-width: 991px) {
    .modal-window {
        width: 95%;
        height: 80%;
    }
}
</style>