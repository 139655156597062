import actions from "@/store/modules/guide/actions";
import getters from "@/store/modules/guide/getters";
import mutations from "@/store/modules/guide/mutations";
import state from "@/store/modules/guide/state";

export default {
    actions,
    getters,
    mutations,
    state
}