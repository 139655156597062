import AdminComponent from "@/components/dashboard/AdminComponent";
import SeparationSearch from "@/views/search/SeparationSearch";
import SeparationShow from "@/views/show/SeparationShow.vue";

export default {
    path: '/separation',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'separation-search', component: SeparationSearch},
        {path: 'show/:id', name: 'separation-show', props: true, component: SeparationShow}
    ]
}