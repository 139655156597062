export default {
    name: 'Estoque',
    icon: 'fa-solid fa-box',
    subLinks: [
        {
            name: 'Entrada / Saída',
            route: '/inventorie/search',
            permissions: [
                "inventorie-search",
                "inventorie-create"
            ]
        },
        {
            name: 'Produto',
            route: '/product/search',
            permissions: [
                "product-search",
                "product-create",
                "product-edit",
                "product-delete"
            ]
        },
    ],
    pagePermissions: [
        "inventorie-search",
        "inventorie-create",
        "product-search",
        "product-create",
        "product-edit",
        "product-delete"
    ]
}