import menuAdmin from "@/configs/links-menu/menuAdmin";
import menuBanho from "@/configs/links-menu/menuBanho";
import menuConfig from "@/configs/links-menu/menuConfig";
import menuEstoque from "@/configs/links-menu/menuEstoque";
import menuFinanceiro from "@/configs/links-menu/menuFinanceiro";
import menuRelatorio from "@/configs/links-menu/menuRelatorio";
export default {
    menuAdmin,
    menuBanho,
    menuConfig,
    menuEstoque,
    menuFinanceiro,
    menuRelatorio
}