import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = 'order-service'

export default {

    loadOrderServices(context, params) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then((response) => {
                    context.commit('LOAD_ORDER_SERVICE', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    loadOrderServicesClients(context, params) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}order-service-search-client`, {params})
                .then((response) => {
                    context.commit('LOAD_ORDER_SERVICE_CLIENT', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    loadOrderService(context, id) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    loadOrderServiceOsBath(context, id) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}order-service-get-os-bath/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    loadOrderServiceSend(context, id) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}order-service-bath-get-send/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    loadOrderServiceBathServiceClose(context, id) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}order-service-bath-service-get-close/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    // updateClient(context, formData) {
    //     context.commit('LOADER', true)
    //     return new Promise((resolve, reject) => {
    //         axios.put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
    //             .then((response) => {
    //                 return resolve(response.data)
    //             }).catch((errors) => {
    //             return reject(errors)
    //         }).finally(() => {
    //             context.commit('LOADER', false)
    //         })
    //     })
    // },

    storeOrderService(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}${RESOURCE}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    storeOrderServiceObs(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}order-service-add-obs-os`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    storeOrderServiceObsInt(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}order-service-add-obs-internal`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    loadOrderServiceBath(context, id) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}order-service-get-os-add-bath/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    loadOrderServiceGetBath(context, id) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}order-service-get-bath/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    storeOrderServiceBath(context, formData) {
        let config = {headers: {'Content-Type': 'multipart/form-data'}}
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}order-service-add-bath`, formData, config)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    storeOrderServiceDiscountPayment(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}order-service-bath-add-payment`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    storeOrderServiceBathService(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}order-service-add-bath-service`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    storeOrderServiceSend(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}order-service-bath-add-send`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    storeOrderServiceAdditional(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}order-service-bath-add-service-add`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    updateOrderServiceBath(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}order-service-update-bath/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    updateOrderServiceTable(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}order-service-update-table/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    updateOrderServiceDiscountPayment(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}order-service-bath-update-payment/${formData.idos}/${formData.idpayment}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    updateOrderServiceBathPhoto(context, formData) {
        context.commit('LOADER', true)
        let config = {headers: {'Content-Type': 'multipart/form-data'}}
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}order-service-update-photo-bath`, formData, config)
                .then((r) => {
                    resolve(r)
                })
                .catch(errors => reject(errors))
                .finally(() => context.commit('LOADER', false))
        })
    },

    updateOrderServiceSend(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}order-service-bath-update-send/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    updateOrderServiceBathService(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}order-service-update-bath-service`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    updateOrderServiceEmailsSend(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}order-service-bath-send-client/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    updateOrderServiceUpdateStatus(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}order-service-update-status/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    updateOrderServiceBathServiceClose(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}order-service-bath-service-update-close/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    updateOrderServiceAdditional(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}order-service-bath-add-service-add-update/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    destroyOrderService(context, id) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}${RESOURCE}/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    destroyOrderServiceDiscountPayment(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}order-service-bath-delete-payment/${formData.id}/${formData.id_pay}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    destroyOrderServiceDeleteBath(context, id) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}order-service-delete-bath/${id}}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    destroyOrderServiceDeleteBathService(context, id) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}order-service-delete-bath-service/${id}}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    destroyOrderServiceAdditional(context, id) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}order-service-bath-add-service-add-delete/${id}}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    loadOrderServiceGroupOS(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}order-service-get-os/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadOrderServiceGroup(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}order-service-add-service-bath-group`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    storeOrderServiceGroup(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}order-service-add-service-bath-group-store`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    storeOrderServiceBathAddServiceAdditionalAdd(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}order-service-bath-add-service-additional-add`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    loadOrderServiceBathAddServiceAdditionalGet(context) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}order-service-bath-add-service-additional-get`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    loadOrderServiceBathAddServiceAdditionalShow(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}order-service-bath-add-service-additional-show/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    updateOrderServiceBathAddServiceAdditionalUpdate(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}order-service-bath-add-service-additional-update/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    destroyOrderServiceBathAddServiceAdditionalDelete(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}order-service-bath-add-service-additional-delete/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },

    orderServiceBathServiceDeleteAll(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE}order-service-bath-service-delete-all/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit("LOADER", false))
        })
    },
}