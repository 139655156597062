<template>
    <loader-component/>
    <router-view/>
</template>

<script>
import LoaderComponent from "@/components/alerts/LoaderComponent";

export default {
    name: 'App',
    components: {
        LoaderComponent
    }
}
</script>