import AdminComponent from "@/components/dashboard/AdminComponent";
import StockSearch from "@/views/search/StockSearch";
import StockCreate from "@/views/create/StockCreate.vue";

export default {
    path: '/stock',
    component: AdminComponent,
    children: [
        {path: 'search', name: 'stock-search', component: StockSearch},
        {path: 'create', name: 'stock-create', component: StockCreate}
    ]
}