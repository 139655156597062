import actions from "@/store/modules/bank-account/actions";
import getters from "@/store/modules/bank-account/getters";
import mutations from "@/store/modules/bank-account/mutations";
import state from "@/store/modules/bank-account/state";

export default {
    actions,
    getters,
    mutations,
    state
}