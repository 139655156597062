import AdminComponent from "@/components/dashboard/AdminComponent";

import SettingsView from "@/views/search/SettingsView";

export default {
    path: "/settings",
    meta: {auth: true},
    component: AdminComponent,
    children: [
        {path: "search", name: "settings-view", component: SettingsView}
    ]
}