import actions from "@/store/modules/entrance-exit-forecast/actions";
import getters from "@/store/modules/entrance-exit-forecast/getters";
import mutations from "@/store/modules/entrance-exit-forecast/mutations";
import state from "@/store/modules/entrance-exit-forecast/state";

export default {
    actions,
    getters,
    mutations,
    state
}