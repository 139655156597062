<template>
    <div class="modal-w position-fixed start-0 top-0 d-grid" v-if="update.show">
        <div class="modal-p p-4 pt-5" v-if="update.show">
            <error-component :errors="errors" v-if="showError"/>

            <div role="button" @click="closeModal" class="btn-close"></div>

            <form>
                <row-component v-if="update.formData.type <= '5'">
                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <label>{{ update.formData.label }}:</label>

                        <div v-if="update.formData.type === '1'">
                            <input class="form-control" v-model="update.formData.value_text">
                        </div>

                        <div v-if="update.formData.type === '2'">
                            <input-component v-money="MONEY" v-model="update.formData.value_coin"/>
                        </div>

                        <div v-if="update.formData.type === '3'">
                            <input-component v-model="update.formData.value_date"/>
                        </div>

                        <div class="form-check form-switch" v-if="update.formData.type === '4'">
                            <input class="form-check-input" type="checkbox" role="switch" id="switch"
                                   :checked="update.formData.value_check === '2'">
                        </div>

                        <div class="input-group" v-if="update.formData.type === '5'">
                            <input-component v-model="update.formData.value_int"/>
                        </div>
                    </row-col-component>
                </row-component>

                <row-component v-else>
                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>{{ update.formData.value_check_text }}:</label>
                        <div class="form-check form-switch me-3">
                            <input class="form-check-input" type="checkbox" role="switch" id="switch"
                                   @click="checkedChange" :checked="update.formData.value_check === '2'">
                        </div>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                        <label>{{ update.formData.label }}:</label>
                        <input-component v-money="MONEY" v-model="update.formData.value_coin"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <button-submit :disabled="sendForm" @click="submit" class="w-100" icon="fa-solid fa-floppy-disk" name="Salvar"/>
                    </row-col-component>
                </row-component>
            </form>
        </div>
    </div>

    <painel-component v-if="viewPage">
        <div class="accordion" id="accordionExample">
            <template v-for="(data, index) in formData" :key="index">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            {{ data.label }}
                        </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <row-component>
                                <template v-for="(item, index) in data.itens" :key="index">
                                    <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                        <label>{{ item.value_check_text }} {{ item.label }}:</label>

                                        <div class="input-group" v-if="item.type === '1'">
                                            <input disabled class="form-control" v-model="item.value_text">
                                            <div @click="loadItem(item.id)" role="button" class="input-group-text">
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </div>
                                        </div>

                                        <div class="input-group" v-if="item.type === '2'">
                                            <input-component disabled v-money="MONEY" v-model="item.value_coin"/>
                                            <div @click="loadItem(item.id)" role="button" class="input-group-text">
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </div>
                                        </div>

                                        <div class="input-group" v-if="item.type === '3'">
                                            <input disabled class="form-control" v-model="item.value_date">
                                            <div @click="loadItem(item.id)" role="button" class="input-group-text">
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </div>
                                        </div>

                                        <div class="form-check form-switch" v-if="item.type === '4'">
                                            <input disabled class="form-check-input" type="checkbox" role="switch"
                                                   id="switch" :checked="value_check === '2'">
                                        </div>

                                        <div class="input-group" v-if="item.type === '5'">
                                            <input disabled class="form-control" v-model="item.value_int">
                                            <div @click="loadItem(item.id)" role="button" class="input-group-text">
                                                <i class="fa-solid fa-pen-to-square"></i>
                                            </div>
                                        </div>

                                        <div v-if="item.type === '6'" class="d-flex">
                                            <div class="form-check form-switch me-3">
                                                <input disabled class="form-check-input" type="checkbox" role="switch"
                                                       id="switch" :checked="item.value_check === '2'">
                                            </div>

                                            <div class="input-group">
                                                <input disabled class="form-control" v-model="item.value_coin">
                                                <div @click="loadItem(item.id)" role="button" class="input-group-text">
                                                    <i class="fa-solid fa-pen-to-square"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </row-col-component>
                                </template>
                            </row-component>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import {useToast} from "vue-toastification";
import {MONEY} from "@/configs/constants";
import {mask} from "vue-the-mask";
import {VMoney} from "v-money";
import ErrorComponent from "@/components/alerts/ErrorComponent.vue";

const toast = useToast()
export default {
    name: "SettingsView",
    components: {
        ErrorComponent,
        ButtonSubmit,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        mask,
        money: VMoney
    },

    created() {
        this.$store.dispatch("updateNamePage", "Configurações")
        this.loadItems()
        this.checkACL()
    },

    data() {
        return {
            MONEY,
            formData: [],
            checked: "1",

            update: {
                show: false,
                formData: {
                    id: "",
                    type: "",
                    type_fix: "",
                    label: "",
                    group: "",
                    value_text: "",
                    value_coin: "",
                    value_date: "",
                    value_check: "",
                    value_check_text: "",
                    value_int: "",
                }
            },

            errors:[],
            viewPage: false,
            sendForm: false,
            showError: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch("loadSettings")
                .then((response) => {
                    this.formData = response
                }).catch(() => toast.error("Error desconhecido", {timeout: 1200}))
        },

        loadItem(id) {
            this.$store.dispatch("loadSetting", id)
                .then((response) => {
                    this.update.formData = response
                    this.update.show = true
                    this.checked = response.value_check
                }).catch(() => toast.error("Error desconhecido", {timeout: 1200}))
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 22)
                .then((r) => {
                    if (r.data.indexOf('settings-edit') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        submit() {
            this.update.formData.value_check = this.checked
            this.$store.dispatch('updateSetting', this.update.formData)
                .then(() => {
                    this.errors = []
                    this.sendForm = false
                    this.showError = false
                    this.update.show = false
                    this.loadItems()
                    toast.success('Cadastro atualizado com sucesso', {timeout: 1500})
                }).catch((e) => {
                this.showError = true
                this.sendForm = false
                this.errors = e.response.data.errors
            })
        },

        checkedChange() {
            if (parseInt(this.checked) === 1) {
                this.checked = 2
            } else {
                this.checked = 1
            }
        },

        closeModal(){
            this.errors = []
            this.showError = false
            this.update.show = false
            this.update.formData = {
                id: "",
                type: "",
                type_fix: "",
                label: "",
                group: "",
                value_text: "",
                value_coin: "",
                value_date: "",
                value_check: "",
                value_check_text: "",
                value_int: "",
            }
        }
    }
}
</script>

<style scoped>
.modal-w {
    z-index: 10;
    width: 100vw;
    height: 100vh;
    place-items: center;
    background: rgba(0, 0, 0, 0.7);
}

.modal-p {
    width: 40%;
    height: auto;
    border-radius: 5px;
    position: relative;
    background: #FFFFFF;
}

.modal-p .btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    background-color: #f0f0f0;
}

.form-check-input:checked {
    background-color: #9FA692;
    border-color: #9FA692;
}

.form-check-input:focus {
    border-color: #9FA692
}

.form-switch .form-check-input {
    width: 3em;
    height: 2em;
}

input {
    height: 42px;
    outline: none;
    border-radius: 5px;
    padding-left: 10px;
    border: 1px solid #cdcdcd;
}

input:focus {
    box-shadow: none;
    border-color: #000;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
}

@media (max-width: 991px) {
    .modal-p {
        width: 95%;
    }
}
</style>