import {URL_BASE} from "@/configs/constants";
import axios from "axios";

const RESOURCE = "settings"

export default {
    actions: {
        loadSettings(context) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}`)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        },

        loadSetting(context, id) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        },

        updateSetting(context, formData){
            context.commit("LOADER", true)
            return new Promise((resolve, reject)=>{
                axios.put(`${URL_BASE}${RESOURCE}/${formData.id}`, formData)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => context.commit("LOADER", false))
            })
        }
    }
}