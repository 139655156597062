<template>
    <div class="home">
        <div class="card-infos mb-3">
            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="card-home">
                        <div class="desc">
                            <p>Ordens de Serviços</p>
                            <p>{{ object.totalOS }}</p>
                        </div>
                        <div class="icon">
                            <i class="fa-solid fa-list-ul"></i>
                        </div>
                    </div>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="card-home">
                        <div class="desc">
                            <p>Cliente</p>
                            <p>{{ object.totalClient }}</p>
                        </div>
                        <div class="icon">
                            <i class="fa-solid fa-user-group"></i>
                        </div>
                    </div>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="card-home">
                        <div class="desc">
                            <p>Produtos</p>
                            <p>{{ object.totalProduct }}</p>
                        </div>
                        <div class="icon">
                            <i class="fa-solid fa-box"></i>
                        </div>
                    </div>
                </row-col-component>
            </row-component>
        </div>

        <Bar style="max-height: 650px" :options="chartOptions" :data="chartData"/>
    </div>
</template>

<script>
import {Bar} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import {useToast} from "vue-toastification";

const toast = useToast()
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'HomeView',
    components: {
        Bar,
        RowComponent,
        RowColComponent
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Home')
        this.loadItems()
    },

    data() {
        return {
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                        backgroundColor: '#04589b'
                    },
                ]
            },

            chartOptions: {
                responsive: true
            },

            object: {}
        }
    },


    methods: {

        loadItems() {
            this.$store.dispatch('loadHome')
                .then((r) => {
                    this.object = r
                    this.loadCharts(this.object.graphicOS)
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        loadCharts(data) {
            let date = new Date()
            let year = date.getFullYear()

            this.chartData = {
                labels: data.labels,
                datasets: [
                    {
                        label: `Abertura de OS ${year}`,
                        data: data.data,
                        backgroundColor: '#9FA692'
                    },
                ]
            }
        },
    }

}
</script>

<style scoped>
.home {
    margin: 2%;
    padding: 2%;
}

.card-infos .card-home {
    border: 0;
    width: 90%;
    height: 95px;
    display: flex;
    border-radius: 5px;
    align-items: center;
    background: #ffffff;
    justify-content: space-between;
}

.card-infos .card-home .icon {
    width: 30%;
    color: #9FA692;
    font-size: 3.5rem;
    text-align: center;
}

.card-infos .card-home .desc {
    padding: 0 20px;
}

.card-infos .card-home .desc p {
    margin: 0;
    color: #ababab;
    font-size: 20px;
    text-transform: uppercase;
}

.card-infos .card-home .desc p:last-child {
    margin: 0;
    color: #000000;
    font-weight: bold;
}

@media (max-width: 1290px) {
    .card-infos .card-home .desc {
        padding: 0 0 0 10px;
    }

    .card-infos .card-home .desc p {
        font-size: 16px;
    }

    .card-infos .card-home .icon {
        font-size: 3rem;
    }
}

@media (max-width: 991px) {
    .card-infos .card-home {
        width: 100%;
    }

    .card-infos .card-home .desc p {
        margin: 0;
        font-size: 16px;
    }

    .card-infos .card-home .desc {
        padding: 20px;
    }
}

@media (max-width: 428px) {
    .card-infos .card-home .desc p {
        margin: 0;
        font-size: 20px;
    }
}
</style>