import AdminComponent from "@/components/dashboard/AdminComponent";
import BathSearch from "@/views/search/BathSearch";
import BathShow from "@/views/show/BathShow";

export default {
    path: '/bath',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'bath-search', component: BathSearch},
        {path: 'show/:id', name: 'bath-show', props: true, component: BathShow}
    ]
}