<template>
    <painel-component>
        <error-component :errors="errors" v-if="showError"/>

        <form v-if="viewPage">
            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.name}">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" :type="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.name}">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectStatus"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.tax_percentage}">
                    <label>Taxa %:</label>
                    <input-component v-money="money" v-model="formData.tax_percentage" :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.tax_transaction}">
                    <label>Taxa Transação R$:</label>
                    <input-component v-money="money" v-model="formData.tax_transaction" :type="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.tax_transaction}">
                    <label>Observação:</label>
                    <text-area v-model="formData.obs" rows="5"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-4">
                    <button-submit :disabled="sendForm" @submit="submit" class="w-100" name="Adicionar"
                                   icon="fa-solid fa-circle-plus"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 mt-4">
                    <button-route class="w-100" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import Multiselect from "@vueform/multiselect";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import TextArea from "@/components/forms/TextArea";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import InputComponent from "@/components/forms/InputComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {useToast} from "vue-toastification";
import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";


const toast = useToast()
export default {
    name: 'FormPaymentCreate',
    components: {
        ErrorComponent,
        InputComponent,
        ButtonSubmit,
        ButtonRoute,
        TextArea,
        RowColComponent,
        RowComponent,
        Multiselect,
        PainelComponent
    },

    directives: {
        money: VMoney
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Forma de Pagamento Adicionar')
        this.checkACL()
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: '/form-payment/search',
                icon: 'fa-solid fa-rotate-left'
            },

            formData: {
                name: '',
                status: '',
                tax_percentage: '',
                tax_transaction: '',
                obs: ''
            },

            money: MONEY,

            viewPage: false,

            errors: [],
            sendForm: false,
            showError: false
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeFormPayment', this.formData)
                    .then((r) => {
                        this.sendForm = false
                        this.errors = []
                        this.showError = false
                        toast.success('Cadastro efetuado com sucesso', {timeout: 1500})
                        this.$router.push({name: 'form-payment-edit', params: {id: r}})
                    }).catch((e) => {
                    this.sendForm = false
                    this.showError = true
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 10)
                .then((r) => {
                    if (r.data.indexOf('payment-methods-create') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        }

    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        selectStatus() {
            return this.$store.state.var.selectStatus
        },
    }
}
</script>