<template>
    <router-link tooltip="Editar" role="button" :to="{name: route, params: {id: id}}" class="btn btn-success mx-1">
        <i class="fa-solid fa-pen-to-square"></i>
    </router-link>
</template>

<script>
export default {
    name: 'ButtonEdit',
    props: {
        route: {
            'type': String,
            "required": true,
        },

        id: {
            'type': Number,
            "required": true,
        }
    }
}
</script>