<template>
    <div class="flag"
         :class="[{'entrance-color' : formData.type === '1', 'exit-color' : formData.type === '2'}]">
        <i :class="[{'ti ti-circle-arrow-up' :formData.type === '1', 'ti ti-circle-arrow-down' :formData.type === '2'}]"></i>
        {{ statusEntranceExit(formData.type) }}
        <div @click="$emit('close-modal')" class="btn-close clearfix"></div>
    </div>

    <div class="mt-4">
        <row-component>
            <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                <div class="d-flex align-items-start flex-column">
                    <div class="d-flex">
                        <p class="my-0">Criado por:</p>
                        <img v-if="formData.photo_user" class="avatar__image mx-2" :src="formData.photo_user" alt="">
                        <img v-else class="avatar__image mx-2" src="@/assets/avatar.png" alt="">
                        {{ formData.name_user }}
                    </div>
                    <p>Código: {{ formData.code }}</p>
                </div>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                <div class="d-flex align-items-center">
                    <p class="d-inline-block mx-3">Data de Criação: <br> <i class="ti ti-calendar-due"></i>
                        {{ formData.date_create }}</p>
                    <p class="d-inline-block mx-3">Horário de criação: <br> <i class="ti ti-clock-filled"></i>
                        {{ formData.hour_create }}</p>
                </div>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <div class="d-flex align-items-center">
                    <p class="d-inline-block mx-3">Valor: <br> <i class="ti ti-cash-banknote"></i>
                        {{ formData.price }}</p>
                </div>
            </row-col-component>
            <hr>
        </row-component>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                <label>Conta Bancária:</label>
                <Multiselect label="name" v-model="formData.bank_accounts_id" :options="multiselectAccountBank"
                             :searchable="true" :noOptionsText="noOptionsText" :noResultsText="noResultText"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                <label>Vencimento:</label>
                <input-component v-mask="'##/##/####'" :type-input="'text'" v-model="formData.due_date"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                <label>Data de Efetivação:</label>
                <input-component v-mask="'##/##/####'" :type-input="'text'" v-model="formData.effective_date"/>
            </row-col-component>
            <hr>
        </row-component>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Tipo de Conta:</label>
                <Multiselect v-model="formData.account_types_id" :options="multiselectAccountTypeAPI"
                             @select="accountSub(formData.account_types_id)"
                             @clear="accountSubClear"
                             :searchable="true" :noOptionsText="noOptionsText" :noResultsText="noResultText"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Sub Conta:</label>
                <Multiselect v-model="formData.account_type_sub_id" :options="multiselectAccountTypeSub"
                             :disabled="sub_disabled"
                             :searchable="true" :noOptionsText="noOptionsText" :noResultsText="noResultText"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Valor Pago:</label>
                <input-component v-money="money" :type-input="'text'" v-model="formData.price_paid"/>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <label>Antecipado:</label>
                <Multiselect v-model="formData.attended" :options="multiselectYesNo" :searchable="true"
                             :noOptionsText="noOptionsText" :noResultsText="noResultText"/>
            </row-col-component>
        </row-component>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <label>Observação:</label>
                <text-area rows="4" v-model="formData.obs"/>
            </row-col-component>
        </row-component>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                <button-submit :disabled="sendForm" @submit="submit" name="Salvar" icon="fa-solid fa-floppy-disk"/>
            </row-col-component>
        </row-component>
    </div>

</template>

<script>
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import Multiselect from '@vueform/multiselect';
import InputComponent from "@/components/forms/InputComponent";
import TextArea from "@/components/forms/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import {useToast} from "vue-toastification";
import {MONEY} from "@/configs/constants";
import {VMoney} from "v-money";
import {mask} from "vue-the-mask";

const toast = useToast()

export default {
    name: 'EntranceExitForecastUpdate',
    components: {
        ButtonSubmit,
        InputComponent,
        RowComponent,
        RowColComponent,
        TextArea,
        Multiselect,
    },

    directives: {
        mask,
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    emits: ['close-modal'],

    created() {
        this.loadItems()
    },

    data() {
        return {
            money: MONEY,

            formData: {
                code: '',
                type: '',
                type_preview: '',
                bank_accounts_id: '',
                account_types_id: '',
                due_date: '',
                price: '',
                price_paid: '',
                obs: '',
                business: '',
                user_id: '',
                user_name: '',
                account_type_sub: '',
                account_type_sub_id: '',
                effective_date: '',
                attended: '',
                photo_user: '',
                name_user: '',
                date_create: '',
                hour_create: '',
                account_types_name: '',
                bank_accounts_name: '',
                bank_accounts_photo: ''
            },

            sub_disabled: true,

            sendForm: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadEntranceExitPreview', this.id)
                .then((r) => {
                    this.formData = r
                    this.$store.dispatch('loadMultiSelectAccountBank')
                    this.$store.dispatch('loadMultiSelectAccountType')
                    this.accountSub(this.formData.account_types_id)
                }).catch(() => {
                toast.error('Erro desconhecido', {timeout: 2000})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateEntranceExitPreview', this.formData)
                    .then(() => {
                        this.sendForm = false
                        toast.success('Cadastro atualizado com sucesso', {timeout: 1500})
                    }).catch(() => {
                    this.sendForm = false
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        accountSub(id) {
            this.$store.dispatch('loadMultiSelectAccountTypeSub', id)
            this.sub_disabled = false
        },

        accountSubClear() {
            this.sub_disabled = true
            this.formData.account_type_sub_id = ''
            this.formData.account_types_id = ''
        },

        statusEntranceExit(value) {
            if (parseInt(value) === 1)
                return 'Entrada'
            return 'Saída'
        },

    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        multiselectYesNo() {
            return this.$store.state.var.multiselectYesNo
        },

        multiselectEntranceExit() {
            return this.$store.state.var.multiselectEntranceExit
        },

        multiselectMonth() {
            return this.$store.state.var.multiselectMonth
        },

        multiselectFrequency() {
            return this.$store.state.var.multiselectFrequency
        },

        multiselectAccountBank() {
            return this.$store.state.var.multiselectAccountBank
        },

        multiselectAccountTypeAPI() {
            return this.$store.state.var.multiselectAccountTypeAPI
        },

        multiselectAccountTypeSub() {
            return this.$store.state.var.multiselectAccountTypeSub
        },
    }
}
</script>

<style scoped>
.avatar__image {
    width: 35px;
}

.flag {
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    color: #FFFFFF;
    font-weight: bold;
    position: absolute;
}

.flag i {
    font-size: 18px;
}

.entrance-color {
    background-color: #16ab39;
}

.exit-color {
    background-color: #db2828;
}

.btn-close {
    float: right;
    cursor: pointer;
}
</style>