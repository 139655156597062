<template>
    <painel-component>
        <error-component :errors="errors" v-if="showError"/>

        <form v-if="viewPage">
            <div class="clearfix">
                <button-route class="my-2 float-end" :items="itemsAdd"/>
            </div>

            <div class="aux my-4">
                <div class="avatar-form">
                    <img v-if="formData.product_photo" :src="formData.product_photo" alt="" class="avatar__image">
                    <img v-else src="@/assets/avatar.png" alt="" class="avatar__image">
                </div>
                <p>{{ formData.name }}</p>
                <router-link :to="{ name: 'product-edit-photo', id: this.id }" class="figure">
                    <i class="fa-solid fa-camera"></i>
                </router-link>
            </div>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.name}">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.status}">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectStatus" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.price}">
                    <label>Preço:</label>
                    <input-component v-model="formData.price" v-money="money" :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.initial_inventory}">
                    <label>Estoque Inicial:</label>
                    <input-component v-model="formData.initial_inventory" :type="'number'" min="0"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.minimum_stock}">
                    <label>Estoque Mínimo:</label>
                    <input-component v-model="formData.minimum_stock" :type="'number'" min="0"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.classification}">
                    <label>Classificação:</label>
                    <Multiselect v-model="formData.classification" :options="multiselectClassification"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.obs}">
                    <label>Observações:</label>
                    <text-area v-model="formData.obs" rows="5"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" @submit="submit" class="w-100" name="Salvar"
                                   icon="fa-solid fa-floppy-disk"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/forms/TextArea";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";
import ErrorComponent from "@/components/alerts/ErrorComponent";

const toast = useToast()
export default {
    name: 'ProductUpdate',
    components: {
        ErrorComponent,
        ButtonSubmit,
        ButtonRoute,
        TextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Produto Editar')
        this.checkACL()
        this.loadItems()
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: '/product/search',
                icon: 'fa-solid fa-rotate-left'
            },

            itemsAdd: {
                name: '',
                route: '/product/create',
                icon: 'fa-solid fa-circle-plus'
            },

            formData: {
                name: '',
                status: '',
                price: '',
                initial_inventory: '',
                brand_id: '',
                minimum_stock: '',
                classification: '',
                obs: ''
            },

            money: MONEY,

            viewPage: false,

            errors: [],
            sendForm: false,
            showError: false,
        }
    },

    methods: {

        loadItems() {
            this.$store.dispatch('loadProduct', this.id)
                .then((r) => {
                    this.formData = r
                    this.$store.dispatch('loadMultiSelectClassification')
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateProduct', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro atualizado com sucesso', {timeout: 1500})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 15)
                .then((r) => {
                    if (r.data.indexOf('product-edit') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        selectStatus() {
            return this.$store.state.var.selectStatus
        },

        multiselectClassification() {
            return this.$store.state.var.multiselectClassification
        },
    }
}
</script>