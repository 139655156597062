import AdminComponent from "@/components/dashboard/AdminComponent";
import EntranceExitForecastSearch from "@/views/search/EntranceExitForecastSearch";
import EntranceExitForecastCreate from "@/views/create/EntranceExitForecastCreate";
import EntranceExitForecastUpdate from "@/views/update/EntranceExitForecastUpdate.vue";

export default {
    path: '/entrance-exit-forecast',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'entrance-exit-forecast-search', component: EntranceExitForecastSearch},
        {path: 'create', name: 'entrance-exit-forecast-create', component: EntranceExitForecastCreate},
        {path: 'edit/:id', name: 'entrance-exit-forecast-edit', component: EntranceExitForecastUpdate, props: true},
    ]
}