<template>
    <painel-component>
        <error-component :errors="errors" v-if="showError"/>

        <form v-if="viewPage">
            <div class="clearfix">
                <button-route class="float-end my-2 ms-2" :items="itemsAdd"/>
                <button-submit @click="cloneFunc" class="float-end my-2" icon="fa-solid fa-clone"/>
            </div>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.name}">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" :type="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.table}">
                    <label>Tabela:</label>
                    <Multiselect v-model="formData.table" :options="multiselectTableService"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.show_mo}">
                    <label>Exibir M.O:</label>
                    <br>
                    <div class="form-check form-check-inline mt-2">
                        <input v-model="formData.show_mo" class="form-check-input" type="radio" name="docs1" value="1"
                               id="moN" checked>
                        <label class="form-check-label" for="moN">Não</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input v-model="formData.show_mo" class="form-check-input" type="radio" name="docs1" value="2"
                               id="moS">
                        <label class="form-check-label" for="moN">Sim</label>
                    </div>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.mo_value}">
                    <label>M.O:</label>
                    <input-component v-money="money3" v-model="formData.mo_value" :type="'text'" :maxlength="4"/>
                </row-col-component>
                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.show_ml}">
                    <label>Exibir ML:</label>
                    <br>
                    <div class="form-check form-check-inline mt-2">
                        <input v-model="formData.show_ml" class="form-check-input" type="radio" name="docs2" value="1"
                               id="mlN" checked>
                        <label class="form-check-label" for="mlN">Não</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input v-model="formData.show_ml" class="form-check-input" type="radio" name="docs2" value="2"
                               id="mlS">
                        <label class="form-check-label" for="mlS">Sim</label>
                    </div>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.ml_value}">
                    <label>ML:</label>
                    <input-component v-money="money3" v-model="formData.ml_value" :maxlength="4" :type="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.status}">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectStatus" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.type}">
                    <label>Tipo:</label>
                    <Multiselect v-model="formData.type" :options="multiselectKindService"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.type}">
                    <label>Custo Operacional %:</label>
                    <input-component v-money="money3" v-model="formData.cost" :maxlength="4" :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.cost_fixo}">
                    <label>Custo Operacional Fixo:</label>
                    <input-component maxlength="12" v-money="money" v-model="formData.cost_fixo" :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.price}">
                    <label>Preço:</label>
                    <input-component maxlength="12" v-money="money" v-model="formData.price" :type="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.obs}">
                    <label>Observações:</label>
                    <text-area v-model="formData.obs" rows="5"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" @submit="submit" class="w-100" name="Salvar"
                                   icon="fa-solid fa-floppy-disk"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/forms/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: 'ServiceUpdate',
    components: {
        ErrorComponent,
        ButtonRoute,
        ButtonSubmit,
        TextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        money: VMoney,
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Serviço Editar')
        this.checkACL()
        this.loadItems()
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: '/service/search',
                icon: 'fa-solid fa-rotate-left'
            },

            itemsAdd: {
                name: '',
                route: '/service/create',
                icon: 'fa-solid fa-circle-plus'
            },

            formData: {
                name: '',
                status: '',
                type: '',
                cost: '',
                cost_fixo: '',
                price: '',
                table: '',
                show_mo: '1',
                show_ml: '1',
                mo_value: '',
                ml_value: '',
                obs: ''
            },

            money: MONEY,

            money3: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 1,
                allowBlank: false,
                max: 99,
                minimumNumberOfCharacters: 3,
                masked: true /* doesn't work with directive */
            },

            viewPage: false,

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {

        loadItems() {
            this.$store.dispatch('loadService', this.id)
                .then((r) => {
                    this.formData = r
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateService', this.formData)
                    .then(() => {
                        this.sendForm = false
                        this.errors = []
                        this.showError = false
                        toast.success('Cadastro atualizado com sucesso', {timeout: 1500})
                    }).catch((e) => {
                    this.sendForm = false
                    this.showError = true
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 7)
                .then((r) => {
                    if (r.data.indexOf('service-edit') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        cloneFunc() {
            this.clone.push(this.formData)
            this.$router.push({name: 'service-create'})
        }

    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        clone() {
            return this.$store.state.var.clone
        },

        selectStatus() {
            return this.$store.state.var.selectStatus
        },

        multiselectKindService() {
            return this.$store.state.var.multiselectKindService
        },

        multiselectTableService() {
            return this.$store.state.var.multiselectTableService
        }
    }
}
</script>