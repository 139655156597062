<template>
    <painel-component>
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-8 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" :type="'text'" placeholder="Digite o nome"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>CPF:</label>
                    <input-component v-model="formData.cpf" :type="'text'" placeholder="000.000.000-00"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectStatus" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Status"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <button-submit class="me-1" name="Pesquisar" icon="fa-solid fa-magnifying-glass"/>
                    <button-route class="ms-1" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>

        <table class="table">
            <thead>
            <tr class="border-top">
                <th scope="col" style="width: 20%">Nome</th>
                <th scope="col" style="width: 15%">CPF</th>
                <th scope="col" style="width: 15%">RG</th>
                <th scope="col" style="width: 10%">Status</th>
                <th scope="col" style="width: 10%" v-if="buttonCheck.edit || buttonCheck.delete">Ações</th>
            </tr>
            </thead>
            <tbody class="table-group-divider">
            <tr v-for="(data, index) in employee.data" :key="index">
                <td data-label="Nome">{{ data.name }}</td>
                <td data-label="CPF">{{ data.cpf }}</td>
                <td data-label="RG">{{ data.rg }}</td>
                <td data-label="Status">{{ statusString(data.status) }}</td>
                <td data-label="Ações" v-if="buttonCheck.edit || buttonCheck.delete">
                    <button-edit route="employee-edit" :id="data.id" v-if="buttonCheck.edit"/>
                    <button-delete v-if="buttonCheck.edit"/>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="employee" :offset="3" @paginate="loadItems"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonEdit from "@/components/buttons/ButtonEdit";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import {useToast} from "vue-toastification";
import ButtonPagination from "@/components/buttons/ButtonPagination.vue";

const toast = useToast()
export default {
    name: 'EmployeeSearch',
    components: {
        ButtonPagination,
        ButtonRoute,
        ButtonSubmit,
        ButtonDelete,
        ButtonEdit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Funcionário Pesquisa')
        this.checkACL()
        this.loadItems(1)
    },

    data() {
        return {
            itemsRoute: {
                name: 'Adicionar',
                route: '/employee/create',
                icon: 'fa-solid fa-circle-plus'
            },

            formData: {
                name: '',
                cpf: '',
                status: ''
            },

            buttonCheck: {
                create: false,
                edit: false,
                delete: false
            }
        }
    },

    methods: {

        submit() {
            this.loadItems(1)
            this.searchSubmit = true
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadEmployees', {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data.length === 0) {
                                toast.info('Nada foi encontrado com os parâmetros informados!', {timeout: 1500})
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 5)
                .then((r) => {
                    if (r.data.indexOf('employee-search') !== -1) {
                        if (r.data.indexOf('employee-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('employee-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('employee-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        statusString(value) {
            if (parseInt(value) === 1)
                return 'Ativo'
            return 'Inativo'
        }

    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        selectStatus() {
            return this.$store.state.var.selectStatus
        },

        employee() {
            return this.$store.state.employee.items
        },

        params() {
            return {
                name: this.formData.name,
                cpf: this.formData.cpf,
                status: this.formData.status
            }
        }
    }
}
</script>