import AdminComponent from "@/components/dashboard/AdminComponent";

import InspectionSearch from "@/views/search/InspectionSearch.vue";
import InspectionShow from "@/views/show/InspectionShow.vue";

export default {
    path: '/inspection',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'inspection-search', component: InspectionSearch},
        {path: 'show/:id', name: 'inspection-show', props: true, component: InspectionShow}
    ]
}