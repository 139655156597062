import AdminComponent from "@/components/dashboard/AdminComponent";
import InventorieSearch from "@/views/search/InventorieSearch";
import InventorieCreate from "@/views/create/InventorieCreate.vue";

export default {
    path: '/inventorie',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'inventorie-search', component: InventorieSearch},
        {path: 'create', name: 'inventorie-create', component: InventorieCreate},
        // {path: 'edit/:id', name: 'form-payment-edit', component: FormPaymentUpdate, props: true}
    ]
}