import AdminComponent from "@/components/dashboard/AdminComponent";
import AdditionalServiceSearch from "@/views/search/AdditionalServiceSearch";
import AdditionalServiceCreate from "@/views/create/AdditionalServiceCreate";
import AdditionalServiceUpdate from "@/views/update/AdditionalServiceUpdate";

export default {
    path: '/additional-service',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'additional-service-search', component: AdditionalServiceSearch},
        {path: 'create', name: 'additional-service-create', component: AdditionalServiceCreate},
        {path: 'edit/:id', name: 'additional-service-edit', component: AdditionalServiceUpdate, props: true},
    ]
}