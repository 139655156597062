<template>
    <nav>
        <div class="box-logo">
            <router-link @click="activeLinkRouteRemove" to="/">
                <img src="@/assets/iwassa-logo-08.png" alt="Logo">
            </router-link>

            <button class="menu-hamburger bg-transparent border-0" @click="openMenu"
                    :class="{'opened': menu_hamburger}">
                <svg width="100" height="100" viewBox="0 0 100 100">
                    <path class="line line1"
                          d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"/>
                    <path class="line line2" d="M 20,50 H 80"/>
                    <path class="line line3"
                          d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"/>
                </svg>
            </button>
        </div>

        <transition name="slide">
            <div class="menu box" v-if="menu_nav">
                <ul>
                    <li v-if="show_menu.menuAdmin" @click="drop_menu.nav_admin = !drop_menu.nav_admin" class="menu-link">
                        <i :class="links.menuAdmin.icon"></i> {{ links.menuAdmin.name }}
                    </li>
                    <transition name="slide">
                        <div class="box" v-if="drop_menu.nav_admin">
                            <div v-for="(link, index) in links.menuAdmin.subLinks" :key="index">
                                <router-link v-if="checkPermissionMenu(link.permissions)" @click="openMenu"
                                             :class="[{ 'active-link': (activeLinkRoute(link.route)) }, 'sub-menu']"
                                             :to="link.route">
                                    {{ link.name }}
                                </router-link>
                            </div>
                        </div>
                    </transition>

                    <li v-if="show_menu.menuBanho" @click="drop_menu.nav_banho = !drop_menu.nav_banho" class="menu-link">
                        <i :class="links.menuBanho.icon"></i> {{ links.menuBanho.name }}
                    </li>
                    <transition name="slide">
                        <div class="box" v-if="drop_menu.nav_banho">
                            <div v-for="(link, index) in links.menuBanho.subLinks" :key="index">
                                <router-link v-if="checkPermissionMenu(link.permissions)" @click="openMenu"
                                             :class="[{ 'active-link': (activeLinkRoute(link.route)) }, 'sub-menu']"
                                             :to="link.route">
                                    {{ link.name }}
                                </router-link>
                            </div>
                        </div>
                    </transition>

                    <li v-if="show_menu.menuFinanceiro" @click="drop_menu.nav_financeiro = !drop_menu.nav_financeiro" class="menu-link">
                        <i :class="links.menuFinanceiro.icon"></i> {{ links.menuFinanceiro.name }}
                    </li>
                    <transition name="slide">
                        <div class="box" v-if="drop_menu.nav_financeiro">
                            <div v-for="(link, index) in links.menuFinanceiro.subLinks" :key="index">
                                <router-link v-if="checkPermissionMenu(link.permissions)" @click="openMenu"
                                             :class="[{ 'active-link': (activeLinkRoute(link.route)) }, 'sub-menu']"
                                             :to="link.route">
                                    {{ link.name }}
                                </router-link>
                            </div>
                        </div>
                    </transition>

                    <li v-if="show_menu.menuEstoque" @click="drop_menu.nav_estoque = !drop_menu.nav_estoque" class="menu-link">
                        <i :class="links.menuEstoque.icon"></i> {{ links.menuEstoque.name }}
                    </li>
                    <transition name="slide">
                        <div class="box" v-if="drop_menu.nav_estoque">
                            <div v-for="(link, index) in links.menuEstoque.subLinks" :key="index">
                                <router-link v-if="checkPermissionMenu(link.permissions)" @click="openMenu"
                                             :class="[{ 'active-link': (activeLinkRoute(link.route)) }, 'sub-menu']"
                                             :to="link.route">
                                    {{ link.name }}
                                </router-link>
                            </div>
                        </div>
                    </transition>

                    <!--<li class="menu-link">
                        <i :class="links.menuRelatorio.icon"></i> {{ links.menuRelatorio.name }}
                    </li>-->

                    <router-link class="menu-link" v-if="checkPermissionMenu(links.menuConfig.permissions)"
                                 :class="[{ 'active-link': (activeLinkRoute(links.menuConfig.route)) }]" :to="links.menuConfig.route">
                        <i :class="links.menuConfig.icon"></i> {{ links.menuConfig.name }}
                    </router-link>
                </ul>
            </div>
        </transition>
    </nav>
</template>

<script>

import linksMenu from "@/configs/links-menu";

export default {
    name: 'NavComponent',

    created() {
        this.checkACL()

        if (window.innerWidth >= 990) {
            this.menu_nav = true
        }
    },

    data() {
        return {
            links: linksMenu,
            drop_menu: [{
                nav_admin: false,
                nav_banho: false,
                nav_estoque: false,
                nav_financeiro: false,
                nav_relatorio: false
            }],

            show_menu: {
                menuAdmin: false,
                menuBanho: false,
                menuConfig: false,
                menuEstoque: false,
                menuFinanceiro: false
            },

            pagePermissions: [],

            activeLink: false,
            menu_hamburger: false,
            menu_nav: false
        }
    },

    methods: {
        openMenu() {
            if (window.innerWidth <= 990) {
                this.menu_hamburger = !this.menu_hamburger
                this.menu_nav = !this.menu_nav
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitensnav')
                .then((r) => {
                    this.pagePermissions = r.data;
                    this.pagePermissions.forEach(item => {

                        if (linksMenu.menuAdmin.pagePermissions.includes(item)) {
                            this.show_menu.menuAdmin = true
                        }

                        if (linksMenu.menuBanho.pagePermissions.includes(item)) {
                            this.show_menu.menuBanho = true
                        }

                        if (linksMenu.menuConfig.pagePermissions.includes(item)) {
                            this.show_menu.menuConfig = true
                        }

                        if (linksMenu.menuFinanceiro.pagePermissions.includes(item)) {
                            this.show_menu.menuFinanceiro = true
                        }

                        if (linksMenu.menuEstoque.pagePermissions.includes(item)) {
                            this.show_menu.menuEstoque = true
                        }
                    })
                })
        },

        checkPermissionMenu(page) {
            let shouldSkip = false;
            this.pagePermissions.forEach(value => {
                if (shouldSkip)
                    return;

                if (page.includes(value))
                    shouldSkip = true;
            });
            return shouldSkip;
        },

        activeLinkRoute(nameLink) {
            let x1 = nameLink.split("/");
            return this.$route.matched[0].path === "/" + x1[1];
        },

        activeLinkRouteRemove() {
            this.activeLink = false;
        },
    }
}
</script>

<style scoped>
nav {
    float: left;
    width: 300px;
    height: 100vh;
    position: fixed;
    overflow-y: auto;
    background: #2d2d2d;
    box-shadow: 0 0 11px rgba(0, 0, 0, .13);
    webkit-box-shadow: 0 0 11px rgba(0, 0, 0, .13);
}

.box-logo {
    height: 85px;
    padding: 15px;
    background-color: #9FA692;
}

.box-logo img {
    margin: 0 auto;
    display: block;
    max-height: 60px;
}

.menu {
    padding-top: 20px;
}

.menu ul {
    padding: 0;
    list-style: none;
}

.menu ul a.menu-link{
    text-decoration: none;
}

.menu ul li.menu-link,
.menu ul a.menu-link,
.menu ul .box .sub-menu {
    height: 45px;
    display: block;
    color: #c1c1c1;
    cursor: pointer;
    list-style: none;
    padding: 10px 15px;
    position: relative;
    border-bottom: 1px solid rgba(107, 108, 109, 0.19);
}

.menu ul li.menu-link i,
.menu ul a.menu-link i {
    font-size: 18px;
    margin-right: 5px;
}

.menu ul li.menu-link:focus,
.menu ul li.menu-link:hover,
.menu ul li.menu-link:active {
    color: #404e67;
    background-color: #EDEDED;
    -moz-transition: .5s ease-in;
    -webkit-transition: .5s ease-in;
}

.menu ul .box a.sub-menu {
    padding-left: 30px;
    text-decoration: none;
}

.active-link {
    border-left: 5px solid #9FA692;
}

.menu ul .box a.sub-menu:hover,
.menu ul .box a.sub-menu:active,
.menu ul .box a.sub-menu:focus {
    color: #9FA692;
    -moz-transition: 0.3s ease-in;
    -webkit-transition: 0.3s ease-in;
}

.box {
    width: 100%;
    margin: 0 auto;
    max-height: 700px;
}

.slide-enter-active {
    transition-duration: 0.5s;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave-from {
    overflow: hidden;
}

.slide-enter-from,
.slide-leave-to {
    max-height: 0;
    overflow: hidden;
}

@media (max-width: 991px) {
    nav {
        width: 100%;
        height: auto;
        position: static;
    }

    .menu {
        padding: 0;
    }

    .menu ul {
        margin: 0;
    }

    .box-logo img {
        margin: unset;
    }
}
</style>