import actions from "@/store/modules/entrance-exit/actions";
import getters from "@/store/modules/entrance-exit/getters";
import mutations from "@/store/modules/entrance-exit/mutations";
import state from "@/store/modules/entrance-exit/state";

export default {
    actions,
    getters,
    mutations,
    state
}