import AdminComponent from "@/components/dashboard/AdminComponent";
import BankAccountSearch from "@/views/search/BankAccountSearch";
import BankAccountCreate from "@/views/create/BankAccountCreate";
import BankAccountUpdate from "@/views/update/BankAccountUpdate";
import BankAccountUpdatePhoto from "@/views/update/BankAccountUpdatePhoto";

export default {
    path: '/bank-account',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'bank-account-search', component: BankAccountSearch},
        {path: 'create', name: 'bank-account-create', component: BankAccountCreate},
        {path: 'edit/:id', name: 'bank-account-edit', component: BankAccountUpdate, props: true},
        {path: 'photo/:id', name: 'bank-account-edit-photo', component: BankAccountUpdatePhoto, props: true},
    ]
}