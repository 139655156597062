<template>
    <vue-easy-lightbox :visible="visibleRef" :imgs="baths.photosModal" :index="indexRef" @hide="onHide"/>

    <painel-component>
        <error-component :errors="errors" v-if="showError"/>

        <div class="clearfix mb-3">
            <span class="badge text-bg-primary me-2">Tabela {{ formData.table }}</span>

            <div class="badge text-bg-warning float-end" style="padding:0 0 0 0.75rem">
                Ouro dia: R${{ formData.priceOuro }}
                <button @click="updatePrice" class="btn ms-2" style="background-color: #EDB300FF;">
                    <i class="fa-solid fa-rotate"></i>
                </button>
            </div>
        </div>

        <form class="mb-4">
            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.os}">
                    <label>OS:</label>
                    <input-component v-model="formData.os" disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.group_id}">
                    <label>Grupo de Banho:</label>
                    <Multiselect v-model="formData.group_id" :options="multiselectBathGroups" :searchable="true"
                                 @select="handleClick" @deselect="handleClick" @clear="handleClick" @close="handleClick"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"
                                 mode="tags" :close-on-select="false" :create-option="true" :groups="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.percentage}">
                    <label>Porcentagem %:</label>
                    <input-component @keyup="limitarInput(formData.percentage)" v-mask="'###'"
                                     v-model="formData.percentage"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.service_id}">
                    <label>Serviço:</label>
                    <Multiselect v-model="formData.service_id" :options="multiselectServiceWithTable" :searchable="true"
                                 @select="getServicePrice(formData.service_id)"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.layer}">
                    <label>Milésimos:</label>
                    <input-component maxlength="4" v-money="money3" v-model="formData.layer"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.thousandth}">
                    <label>M.O.:</label>
                    <input-component maxlength="4" v-money="money3" v-model="formData.thousandth"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.base_id}">
                    <label>Base:</label>
                    <Multiselect v-model="formData.base_id" :options="multiselectServiceOne" :searchable="true"
                                 @select="getServiceAddPrice(formData.base_id, 1)"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.cor_id}">
                    <label>Cor:</label>
                    <Multiselect v-model="formData.cor_id" :options="multiselectServiceTwo" :searchable="true"
                                 @select="getServiceAddPrice(formData.cor_id, 2)"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.verniz_id}">
                    <label>Verniz:</label>
                    <Multiselect v-model="formData.verniz_id" :options="multiselectServiceThree" :searchable="true"
                                 @select="getServiceAddPrice(formData.verniz_id, 3)"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.price_kilo}">
                    <label>Preço do Serviço:</label>
                    <input-component v-money="MONEY" v-model="formData.price_kilo"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.price_base}">
                    <label>Preço Base:</label>
                    <input-component v-money="MONEY" v-model="formData.price_base"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.price_cor}">
                    <label>Preço Cor:</label>
                    <input-component v-money="MONEY" v-model="formData.price_cor"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.price_verniz}">
                    <label>Preço Verniz:</label>
                    <input-component v-money="MONEY" v-model="formData.price_verniz"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" @click="search" class="w-100" name="Buscar"
                                   icon="fa-solid fa-magnifying-glass"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsOne"/>
                </row-col-component>
            </row-component>
        </form>

        <template v-for="(data, index) in baths.baths" :key="index">
            <div class="card bg-light-subtle p-3 mb-4 position-relative">
                <div class="count">
                    {{ index + 1 }}
                </div>

                <div class="mb-2 clearfix">
                    <button-delete class="float-end" @click="deleteBath(index)"/>
                </div>

                <div class="aux my-4">
                    <div class="avatar-form rounded-0">
                        <div class="btn-avatar-view">
                            <button-submit @click="showImg(index)" icon="fa-solid fa-camera-retro"/>
                        </div>
                        <img v-if="data.photo" :src="data.photo" class="avatar__image rounded-0" alt="">
                        <img v-else src="@/assets/avatar.png" class="avatar__image rounded-0" alt="">
                    </div>
                </div>

                <row-component>
                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>Grupo de Banho:</label>
                        <Multiselect disabled :options="multiselectBathGroup" v-model="data.bath_group_id"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>Origem:</label>
                        <input-component v-model="data.origem" disabled/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>Código:</label>
                        <input-component v-model="data.code" disabled/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>Preço Bruto UN:</label>
                        <input-component v-model="data.price_bruto" disabled/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                        <label>Quantidade Original:</label>
                        <input-component v-model="data.the_amount_original" disabled/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                        <label>Quantidade Disponíveis:</label>
                        <input-component v-model="data.available_quantity" disabled/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                        <label>Quantidade Banho:</label>
                        <input-component v-model="data.the_amount" disabled/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>Peso:</label>
                        <input-component type="text" v-model="data.kilo" disabled/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>Total Peso:</label>
                        <input-component type="text" v-model="data.kilo_total" disabled/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                        <label>Preço Base:</label>
                        <input-component v-model="data.price_base" disabled/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                        <label>Preço Total Base:</label>
                        <input-component v-model="data.price_base_total" disabled/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                        <label>Preço Cor:</label>
                        <input-component v-model="data.price_cor" disabled/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                        <label>Preço Total Cor:</label>
                        <input-component v-model="data.price_cor_total" disabled/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                        <label>Preço Verniz:</label>
                        <input-component v-model="data.price_verniz" disabled/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                        <label>Preço Total Verniz:</label>
                        <input-component v-model="data.price_verniz_total" disabled/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>Preço do Serviço:</label>
                        <input-component v-model="data.price_kilo" disabled/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>Custo do Banho Por KG:</label>
                        <input-component v-model="data.price_bath_kg" disabled/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>Custo Do Serviço (ML + MO):</label>
                        <input-component v-model="data.price_ml_mo" disabled/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>Subtotal:</label>
                        <input-component v-model="data.price_subtotal" disabled/>
                    </row-col-component>
                </row-component>
            </div>
        </template>

        <row-component v-if="baths.baths">
            <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <button-submit @click="submit" :disabled="sendForm" class="w-100" name="Adicionar"
                               icon="fa-solid fa-circle-plus"/>
            </row-col-component>
        </row-component>

    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import VueEasyLightbox from "vue-easy-lightbox";

import {useToast} from "vue-toastification";
import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {mask} from "vue-the-mask";
import {ref} from "vue";

const toast = useToast()
export default {
    name: "OSCreateServiceGroup",
    components: {
        VueEasyLightbox,
        ButtonDelete,
        ButtonRoute,
        ButtonSubmit,
        InputComponent,
        Multiselect,
        RowColComponent,
        RowComponent,
        ErrorComponent,
        PainelComponent
    },

    directives: {
        mask,
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("updateNamePage", "OS Adicionar Serviço Grupo")
        this.$store.dispatch("loadMultiSelectServiceOne")
        this.$store.dispatch("loadMultiSelectServiceTwo")
        this.$store.dispatch("loadMultiSelectServiceThree")
        this.$store.dispatch("loadMultiSelectBathGroup")
        this.loadItems()
    },

    setup() {
        const visibleRef = ref(false)
        const indexRef = ref(0)
        const imgs = []
        const showImg = (index) => {
            indexRef.value = index
            visibleRef.value = true
        }
        const onHide = () => visibleRef.value = false
        return {
            visibleRef,
            indexRef,
            imgs,
            showImg,
            onHide,
        }
    },

    data() {
        return {
            itemsOne: {
                route: `/os/edit/${this.id}`,
                icon: "fa-solid fa-rotate-left",
                name: "Voltar"
            },

            formData: {
                os: "",
                order_service_id: this.id,
                group_id: [],
                percentage: "50",
                service_id: "",
                layer: "",
                thousandth: "",
                base_id: "",
                cor_id: "",
                verniz_id: "",
                price_kilo: "",
                price_base: "",
                price_cor: "",
                price_verniz: "",
                priceOuro: "",
                table: "",
            },

            baths: [],

            bathsArr:[],

            MONEY,
            money3: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                precision: 1,
                allowBlank: false,
                max: 99,
                minimumNumberOfCharacters: 3,
                masked: true
            },

            errors: [],
            sendForm: false,
            showError: false
        }
    },

    methods: {
        search() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('loadOrderServiceGroup', this.formData)
                    .then((response) => {
                        this.baths = response
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeOrderServiceGroup', this.baths)
                    .then(() => {
                        this.errors = []
                        this.sendForm = false
                        this.showError = false
                        toast.success('Cadastro efetuado com sucesso', {timeout: 1500})
                        this.$router.push({name: "os-edit", params: {id: this.id}})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        loadItems() {
            this.$store.dispatch("loadOrderServiceGroupOS", this.id)
                .then((response) => {
                    this.formData.os = response.os
                    this.formData.priceOuro = response.priceOuro
                    this.formData.table = response.table
                    this.$store.dispatch('loadMultiSelectServiceWithTable', response.table)
                    this.changeBathArray()
                }).catch(() => {
                toast.error("Erro desconhecido", {timeout: 1500})
            })
        },

        updatePrice() {
            this.$store.dispatch("loadOrderServiceGroupOS", this.id)
                .then((response) => {
                    this.formData.priceOuro = response.priceOuro
                    this.formData.price_kilo = response.priceOuro
                }).catch(() => {
                toast.error("Erro desconhecido", {timeout: 1500})
            })
        },

        limitarInput(value) {
            if (parseInt(value) > 100)
                this.formData.percentage = 100;
        },

        getServiceAddPrice(id, type) {
            this.$store.dispatch('loadGetServiceAddPrice', id)
                .then((r) => {
                    if (type === 1) {
                        this.formData.price_base = r
                    }
                    if (type === 2) {
                        this.formData.price_cor = r
                    }
                    if (type === 3) {
                        this.formData.price_verniz = r
                    }
                })
        },

        getServicePrice(id) {
            this.$store.dispatch('loadGetServicePrice', id)
                .then((r) => {
                    this.formData.price_kilo = r.price
                    this.formData.thousandth = r.mo_value
                    this.formData.layer = r.ml_value

                })
        },

        deleteBath(index) {
            this.baths.baths.splice(index, 1)
        },

        changeBathArray(){
            this.$store.dispatch('loadMultiSelectBathGroup')
                .then((response)=>{
                    this.bathsArr.push({
                        label: "Selecionar tudo",
                        options: response
                    })
                })
        },

        handleClick() {
            const value = [...this.formData.group_id]
            const group = this.multiselectBathGroups[0]

            if (value) {
                const allSelected = value.length === group.options.length;
                group.label = allSelected ? "Desselecionar todos" : "Selecionar todos";
            } else {
                group.label = "Selecionar todos"
            }
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        multiselectBathGroup() {
            return this.$store.state.var.multiselectBathGroup
        },

        multiselectBathGroups() {
            return this.bathsArr
        },

        multiselectServiceWithTable() {
            return this.$store.state.var.multiselectServiceWithTable
        },

        multiselectServiceOne() {
            return this.$store.state.var.multiselectServiceOne
        },

        multiselectServiceTwo() {
            return this.$store.state.var.multiselectServiceTwo
        },

        multiselectServiceThree() {
            return this.$store.state.var.multiselectServiceThree
        },
    }
}
</script>

<style scoped>
.count {
    height: 35px;
    width: 90px;
    padding: 5px;
    color: #FFFFFF;
    position: absolute;
    background: #9FA692;
}

.avatar-form::before,
.avatar-form::after {
    display: none;
}

.avatar-form {
    width: 115px;
    height: 150px;
}

.btn-avatar-view {
    opacity: 0;
    width: 115px;
    z-index: 100;
    height: 150px;
    display: grid;
    position: absolute;
    place-items: center;
    transition: all 0.2s ease-in-out;
    background-color: rgba(0, 0, 0, 0.3);
}

.btn-avatar-view:hover {
    opacity: 1;
}

@media (max-width: 428px) {
    .avatar-form {
        width: 115px;
        height: 150px;
    }

    .avatar-form img {
        height: 150px;
        display: block;
    }
}
</style>