export default {
    name: 'Etapas Banho',
    icon: 'fa-solid fa-list-check',
    subLinks: [
        {
            name: 'Separação',
            route: '/separation/search',
            permissions: [
                'os-separation'
            ]
        },
        {
            name: 'Amarração',
            route: '/mooring/search',
            permissions: [
                'os-mooring'
            ]
        },
        {
            name: 'Banho',
            route: '/bath/search',
            permissions: [
                'os-bath'
            ]
        },
        {
            name: 'Inspeção',
            route: '/inspection/search',
            permissions: [
                'os-inspection'
            ]
        },
        {
            name: 'Fechamento',
            route: '/closure/search',
            permissions: [
                'os-close'
            ]
        },
        {
            name: 'Envio',
            route: '/shipping/search',
            permissions: [
                'os-send'
            ]
        },
    ],
    pagePermissions: [
        'os-separation',
        'os-mooring',
        'os-bath',
        'os-inspection',
        'os-close',
        'os-send'
    ]
}