import actions from "@/store/modules/account-type/actions";
import getters from "@/store/modules/account-type/getters";
import mutations from "@/store/modules/account-type/mutations";
import state from "@/store/modules/account-type/state";

export default {
    actions,
    getters,
    mutations,
    state
}