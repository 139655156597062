<template>
    <painel-component>
        <error-component :errors="errors" v-if="showError"/>

        <form v-if="viewPage">
            <div class="clearfix">
                <button-route class="my-2 float-end" :items="itemsAdd"/>
            </div>

            <div class="aux my-4">
                <div class="avatar-form">
                    <img v-if="formData.photo" :src="formData.photo" alt="" class="avatar__image">
                    <img v-else src="@/assets/avatar.png" alt="" class="avatar__image">
                </div>
                <p>{{ formData.name }}</p>
                <router-link :to="{ name: 'bank-account-edit-photo', id: this.id }" class="figure">
                    <i class="fa-solid fa-camera"></i>
                </router-link>
            </div>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.name}">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.balance}">
                    <label>Saldo:</label>
                    <input-component v-model="formData.balance" v-money="money" :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.allow_negative_balance}">
                    <label>Permitir Negativo:</label>
                    <Multiselect v-model="formData.allow_negative_balance" :options="multiselectYesNo"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.status}">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectStatus" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.bank}">
                    <label>Banco:</label>
                    <Multiselect v-model="formData.bank" :options="multiselectBankList" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.account_type}">
                    <label>Tipo de conta:</label>
                    <Multiselect v-model="formData.account_type" :options="multiselectAccountType"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.agency}">
                    <label>Agência:</label>
                    <input-component v-model="formData.agency" :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.digit_account}">
                    <label>Conta/Dígito:</label>
                    <input-component v-model="formData.digit_account" :type="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component
                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                        :class="{'formError': errors.pix_type}">
                    <label>Tipo PIX:</label>
                    <Multiselect v-model="formData.pix_type" :options="multiselectPIX" :searchable="true"
                                 :noResultsText="noResultText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component v-if="formData.pix_type === 1"
                                   class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.key_pix}">
                    <label>Chave:</label>
                    <input-component v-model="formData.key_pix" :type="'text'"
                                     v-mask="['##.###.###/####-##','###.###.###-##']"/>
                </row-col-component>

                <row-col-component v-else-if="formData.pix_type === 2"
                                   class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.key_pix}">
                    <label>Chave:</label>
                    <input-component v-model="formData.key_pix" :type="'text'"
                                     v-mask="['(##) ####-####','(##) #####-####']"/>
                </row-col-component>

                <row-col-component v-else-if="formData.pix_type === 3"
                                   class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.key_pix}">
                    <label>Chave:</label>
                    <input-component v-model="formData.key_pix" :type="'email'"/>
                </row-col-component>

                <row-col-component v-else-if="formData.pix_type === 4"
                                   class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.key_pix}">
                    <label>Chave:</label>
                    <input-component v-model="formData.key_pix" :type="'text'"/>
                </row-col-component>

                <row-col-component v-else
                                   class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.key_pix}">
                    <label>Chave:</label>
                    <input-component v-model="formData.key_pix" :type="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Observações:</label>
                    <text-area v-model="formData.obs" rows="5"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" @submit="submit" class="w-100" name="Salvar"
                                   icon="fa-solid fa-floppy-disk"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/forms/TextArea";
import {mask} from "vue-the-mask";
import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import {useToast} from "vue-toastification";
import ErrorComponent from "@/components/alerts/ErrorComponent";

const toast = useToast()
export default {
    name: 'BankAccountUpdate',
    components: {
        ErrorComponent,
        ButtonRoute,
        ButtonSubmit,
        TextArea,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent,
        Multiselect
    },

    directives: {
        mask,
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Conta Bancária Editar')
        this.checkACL()
        this.loadItems()
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: '/bank-account/search',
                icon: 'fa-solid fa-rotate-left'
            },

            itemsAdd: {
                name: '',
                route: '/bank-account/create',
                icon: 'fa-solid fa-circle-plus'
            },

            formData: {
                name: '',
                bank: '',
                balance: '',
                account_type: '',
                agency: '',
                digit_account: '',
                pix_type: '',
                key_pix: '',
                allow_negative_balance: '',
                main_account: '',
                status: '',
                obs: ''
            },

            pix: '',
            money: MONEY,

            viewPage: false,

            errors: [],
            sendForm: false,
            showError: false,
        }
    },

    methods: {

        loadItems() {
            this.$store.dispatch('loadBankAccount', this.id)
                .then((r) => {
                    this.formData = r
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateBankAccount', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro atualizado com sucesso', {timeout: 1500})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 8)
                .then((r) => {
                    if (r.data.indexOf('account-bank-edit') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        selectStatus() {
            return this.$store.state.var.selectStatus
        },

        multiselectYesNo() {
            return this.$store.state.var.multiselectYesNo
        },

        multiselectBankList() {
            return this.$store.state.var.multiselectBankList
        },

        multiselectAccountType() {
            return this.$store.state.var.multiselectAccountType
        },

        multiselectPIX() {
            return this.$store.state.var.multiselectPIX
        },
    }
}
</script>