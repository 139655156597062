<template>
    <button :tooltip="name" :type="type" class="btn btn-primary my-background" @click="submit">
        <i :class="icon"></i>
        {{ name }}
    </button>
</template>

<script>
export default {
    name: 'ButtonSubmit',
    props: {
        name: {
            type: String,
            default: ''
        },

        type: {
            type: String,
            default: 'button'
        },

        icon: {
            type: String,
            default: ''
        }
    },

    methods: {
        submit() {
            this.$emit('submit')
        }
    }
}
</script>

<style scoped>
.my-background{
    border-color: #9FA692;
    background-color: #9FA692;
}

.my-background.disabled,
.my-background:disabled{
    opacity: 0.8;
    border-color: #9FA692 !important;
    background-color: #9FA692 !important;
}

.my-background:active,
.my-background:focus,
.my-background:hover{
    border-color: #9FA692 !important;
    background-color: #9FA692 !important;
}
</style>