import AdminComponent from "@/components/dashboard/AdminComponent";
import GuideSearch from "@/views/search/GuideSearch";
import GuideCreate from "@/views/create/GuideCreate";
import GuideUpdate from "@/views/update/GuideUpdate";
import GuideUpdatePhoto from "@/views/update/GuideUpdatePhoto.vue";

export default {
    path: '/guide',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: "search", name: "guide-search", component: GuideSearch},
        {path: "create", name: "guide-create", component: GuideCreate},
        {path: "edit/:id", name: "guide-edit", props: true, component: GuideUpdate},
        {path: "photo/:id", name: "guide-photo", props: true, component: GuideUpdatePhoto}
    ]
}