import AdminComponent from "@/components/dashboard/AdminComponent";

import MooringSearch from "@/views/search/MooringSearch";
import MooringShow from "@/views/show/MooringShow.vue";

export default {
    path: '/mooring',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'mooring-search', component: MooringSearch},
        {path: 'show/:id', name: 'mooring-show', props: true, component: MooringShow}
    ]
}