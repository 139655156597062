<template>
    <div class="painel-container">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'PainelComponent'
}
</script>

<style scoped>
.painel-container {
    margin: 2%;
    padding: 2%;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 5px 10px 12px 0 #A6A6A6;
    -webkit-box-shadow: 5px 10px 12px 0 #A6A6A6;
}
</style>