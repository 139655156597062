import axios from "axios"

export default {
    actions: {
        searchApiCNPJ(context, formData) {
            context.commit('LOADER', true)
            return new Promise((resolve, reject) => {
                const config = {"headers": {"Authorization": "819cee50-84dc-4499-b7c9-8204c26014ac-dc3db308-a66d-44f8-b997-079d5b7f7c80"},}
                axios.get(`https://api.cnpja.com/office/${formData.cnpj}`, config)
                    .then((r) => {
                        resolve(r)
                    })
                    .catch(errors => {
                        reject(errors)
                    })
                    .finally(() => {
                        context.commit('LOADER', false)
                    })
            })
        },

        searchApiCEP(context, formData) {
            context.commit('LOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`https://viacep.com.br/ws/${formData.cep}/json/`)
                    .then((r) => {
                        resolve(r)
                    })
                    .catch(errors => {
                        reject(errors)
                        context.commit('LOADER', false)
                    })
                    .finally(() => context.commit('LOADER', false))
            })
        }
    }
}





