<template>
    <painel-component>
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Produto:</label>
                    <Multiselect v-model="value" :options="[]" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Fornecedor:</label>
                    <Multiselect v-model="value" :options="[]" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Entrada/Saída:</label>
                    <Multiselect v-model="button" :options="multiselectEntranceExit" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Quantidade UN:</label>
                    <input-component :type="'number'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Preço:</label>
                    <input-component v-money="money" :type="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit v-if="button === 2" class="w-100" name="Remover" icon="fa-solid fa-trash"/>
                    <button-submit v-else class="w-100" name="Adicionar" icon="fa-solid fa-circle-plus"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import Multiselect from "@vueform/multiselect";
import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";

export default {
    name: 'StockCreate',
    components: {
        Multiselect,
        InputComponent,
        RowColComponent,
        ButtonSubmit,
        ButtonRoute,
        RowComponent,
        PainelComponent
    },

    directives: {
        money: VMoney
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Estoque Adicionar/Remover')
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: '/stock/search',
                icon: 'fa-solid fa-rotate-left'
            },

            money: MONEY,
            button: '',
        }
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        multiselectEntranceExit() {
            return this.$store.state.var.multiselectEntranceExit
        },
    }
}
</script>