export default {
    name: 'Financeiro',
    icon: 'fa-solid fa-calculator',
    subLinks: [
        {
            name: 'Conta Bancária',
            route: '/bank-account/search',
            permissions: [
                "account-bank-search",
                "account-bank-create",
                "account-bank-edit"
            ]
        },
        {
            name: 'Entrada e Saída',
            route: '/entrance-exit/search',
            permissions: [
                "entrance-exit-search",
                "entrance-exit-create"
            ]
        },
        {
            name: 'Entrada e Saída (Previsão)',
            route: '/entrance-exit-forecast/search',
            permissions: [
                "entrance-exit-preview-search",
                "entrance-exit-preview-create",
                "entrance-exit-preview-edit",
                "entrance-exit-preview-delete",
                "entrance-exit-preview-low"
            ]
        },
        {
            name: 'Formas de Pagamento',
            route: '/form-payment/search',
            permissions: [
                "payment-methods-search",
                "payment-methods-create",
                "payment-methods-edit"
            ]
        },
        {
            name: 'Recibo',
            route: '/receipt/search',
            permissions: [
                "receipt-search",
                "receipt-create",
                "receipt-edit",
                "receipt-delete"
            ]
        },
        {
            name: 'Tipos de Conta',
            route: '/account-type/search',
            permissions: [
                "account-type-search",
                "account-type-create",
                "account-type-edit"
            ]
        },
    ],
    pagePermissions: [
        "entrance-exit-search",
        "entrance-exit-create",
        "entrance-exit-preview-search",
        "entrance-exit-preview-create",
        "entrance-exit-preview-edit",
        "entrance-exit-preview-delete",
        "entrance-exit-preview-low",
        "payment-methods-search",
        "payment-methods-create",
        "payment-methods-edit",
        "receipt-search",
        "receipt-create",
        "receipt-edit",
        "receipt-delete",
        "account-bank-search",
        "account-bank-create",
        "account-bank-edit",
        "account-type-search",
        "account-type-create",
        "account-type-edit",
    ]
}