<template>
    <painel-component>
        <error-component :errors="errors" v-if="showError"/>

        <form v-if="viewPage">
            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <div class="form-check form-check-inline">
                        <input v-model="formData.type" class="form-check-input" type="radio" name="docs" value="1"
                               id="cliente">
                        <label class="form-check-label" for="cliente">Cliente</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input v-model="formData.type" class="form-check-input" type="radio" name="docs" value="3"
                               id="fornecedor">
                        <label class="form-check-label" for="fornecedor">Fornecedor</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input v-model="formData.type" class="form-check-input" type="radio" name="docs" value="2"
                               id="funcionario" checked>
                        <label class="form-check-label" for="funcionario">Funcionário</label>
                    </div>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <button-route class="mx-2 float-end" :items="itemsAdd"/>

                    <button-submit class="float-end" @click="printReceipt(formData.link_receipt_pdf)"
                                   icon="fa-solid fa-print"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.client_employee_provider_type}">
                    <label>Tipo:</label>
                    <Multiselect v-model="formData.client_employee_provider_type"
                                 @select="titleSuggestion(formData.client_employee_provider_type)"
                                 :options="multiselectTypeReceipt" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component v-if="formData.type === '1'"
                                   class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.client_employee_provider_id}">
                    <label>Cliente:</label>
                    <Multiselect label="name" v-model="formData.client_employee_provider_id"
                                 :options="multiselectClient"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component v-else-if="formData.type === '3'"
                                   class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.client_employee_provider_id}">
                    <label>Fornecedor:</label>
                    <Multiselect label="name" v-model="formData.client_employee_provider_id"
                                 :options="multiselectProvider"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component v-else
                                   class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.client_employee_provider_id}">
                    <label>Funcionário:</label>
                    <Multiselect label="name" v-model="formData.client_employee_provider_id"
                                 :options="multiselectEmployee"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.title}">
                    <label>Título:</label>
                    <input-component v-model="formData.title" :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.date}">
                    <label>Data:</label>
                    <input-component v-model="formData.date" v-mask="'##/##/####'" :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.price}">
                    <label>Valor:</label>
                    <input-component v-model="formData.price" v-money="money" :type="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.paid}">
                    <label>Pago:</label>
                    <!--<div class="input-group" v-if="type === '3'">
                    <input-component v-model="formData.paid" :type="'text'"/>
                    <button-submit class="input-group-text" icon="fa-solid fa-magnifying-glass"/>
                    </div>-->

                    <input-component v-model="formData.paid" :type="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.referent}">
                    <label>Referente:</label>
                    <text-area v-model="formData.referent" rows="2"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" @submit="submit" class="w-100" icon="fa-solid fa-floppy-disk"
                                   name="Salvar"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/forms/TextArea";
import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {mask} from "vue-the-mask";
import {useToast} from "vue-toastification";
import ErrorComponent from "@/components/alerts/ErrorComponent";

const toast = useToast()
export default {
    name: 'ReceiptUpdate',
    components: {
        ErrorComponent,
        TextArea,
        Multiselect,
        ButtonSubmit,
        RowComponent,
        InputComponent,
        RowColComponent,
        ButtonRoute,
        PainelComponent
    },

    directives: {
        mask,
        money: VMoney,
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Recibo Editar')
        this.checkACL()
        this.loadItems()
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: '/receipt/search',
                icon: 'fa-solid fa-rotate-left'
            },

            itemsAdd: {
                name: '',
                route: '/receipt/create',
                icon: 'fa-solid fa-circle-plus'
            },

            formData: {
                type: '',
                title: '',
                client_employee_provider_type: '',
                client_employee_provider_id: '',
                date: '',
                price: '',
                paid: '',
                referent: '',
                link_receipt_pdf: ''
            },

            money: MONEY,

            viewPage: false,

            errors: [],
            sendForm: false,
            showError: false
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadReceipt', this.id)
                .then((r) => {
                    this.$store.dispatch('loadMultiSelectClient')
                    this.$store.dispatch('loadMultiSelectEmployee')
                    this.$store.dispatch('loadMultiSelectProvider')
                    this.formData = r
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateReceipt', this.formData)
                    .then(() => {
                        this.sendForm = false
                        this.errors = []
                        this.showError = false
                        toast.success('Cadastro atualizado com sucesso', {timeout: 1500})
                    }).catch((e) => {
                    this.sendForm = false
                    this.showError = true
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 11)
                .then((r) => {
                    if (r.data.indexOf('receipt-edit') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        printReceipt(url) {
            window.open(url, '_blank')
        },

        titleSuggestion(value) {
            switch (parseInt(value)) {
                case 1:
                    return this.formData.title = 'Recibo de pagamento';
                case  2:
                    return this.formData.title = 'Recibo de recebimento';
                case 3:
                    return this.formData.title = 'Recibo de pagamento salarial';
                case 4:
                    return this.formData.title = 'Recibo adiantamento salarial';
                case 5:
                    return this.formData.title = 'Recibo de pagamento salarial (Prestador de serviço)';
                case 6:
                    return this.formData.title = 'Recibo adiantamento salarial (Prestador de serviço)';
                default:
                    return this.formData.title = ''
            }
        }
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        multiselectTypeReceipt() {
            return this.$store.state.var.multiselectTypeReceipt
        },

        multiselectClient() {
            return this.$store.state.var.multiselectClient
        },

        multiselectEmployee() {
            return this.$store.state.var.multiselectEmployee
        },

        multiselectProvider() {
            return this.$store.state.var.multiselectProvider
        },

    },
}
</script>