<template>
    <painel-component>
        <error-component :errors="errors" v-if="showError"/>

        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>OS:</label>
                    <input-component v-model="formData.os" :type="'text'" disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Tipo de Entrega:</label>
                    <Multiselect v-model="formData.type" :options="multiselectDeliveryType"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>Peso:</label>
                    <input-component v-model="kilo" :type="'text'" disabled/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>Peso Total:</label>
                    <div class="position-relative">
                        <p class="position-absolute top-50 start-0 translate-middle-y"
                           style="margin-left: 10px">
                            {{ formData.kilo }}
                        </p>
                        <input-component v-model="number" @keyup="funcOne" :type="'text'" style="color: transparent"/>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <label>Entrega:</label>
                    <Multiselect v-model="formData.send" :options="multiselectDelivery"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Valor:</label>
                    <input-component maxlength="12" v-money="money" v-model="formData.price" :type="'text'"/>
                </row-col-component>
            </row-component>

            <div v-if="formData.type === 3">
                <row-component>
                    <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <label>CEP:</label>
                        <input-component v-mask="'#####-###'" @change="searchCEP" v-model="formData.cep"
                                         :type="'text'"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                        <label>Endereço:</label>
                        <input-component v-model="formData.address" :type="'text'"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>Bairro:</label>
                        <input-component v-model="formData.district" :type="'text'"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>Cidade:</label>
                        <input-component v-model="formData.city" :type="'text'"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>Estado:</label>
                        <input-component v-model="formData.state" :type="'text'"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>Nº:</label>
                        <input-component v-model="formData.number" :type="'text'"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <label>Complemente:</label>
                        <text-area v-model="formData.complement" :rows="4"/>
                    </row-col-component>
                </row-component>
            </div>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @submit="submit" :disabled="sendForm" class="w-100" name="Adicionar"
                                   icon="fa-solid fa-circle-plus"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import TextArea from "@/components/forms/TextArea";
import ErrorComponent from "@/components/alerts/ErrorComponent";

import {mask} from "vue-the-mask";
import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: 'OSCreateShipping',
    components: {
        ErrorComponent,
        TextArea,
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        mask,
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'OS Adicionar Envio')
        this.loadOs()
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: `/os/edit/${this.id}`,
                icon: 'fa-solid fa-rotate-left'
            },

            formData: {
                id: this.id,
                os: '',
                type: '',
                send: '',
                price: '',
                kilo: '',
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                number: '',
                complement: '',
                country: 'Brasil'
            },

            kilo: '',
            number: '',
            money: MONEY,

            errors: [],
            sendForm: false,
            showError: false,
        }
    },

    methods: {
        loadOs() {
            this.$store.dispatch('loadOrderServiceSend', this.id)
                .then((r) => {
                    this.formData.os = r.os
                    this.kilo = r.totalKilo
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        submit() {
            delete this.formData.os
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeOrderServiceSend', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro efetuado com sucesso', {timeout: 1500})
                        this.$router.push({name: 'os-edit', params: {id: this.id}})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        searchCEP() {
            let cep = this.formData.cep.replace(/\D/g, '');
            const params = {'cep': cep}

            this.$store.dispatch('searchApiCEP', params)
                .then((r) => {
                    this.formData.city = r.data.localidade
                    this.formData.state = r.data.uf
                    this.formData.district = r.data.bairro
                    this.formData.address = r.data.logradouro
                    this.formData.complement = r.data.complemento
                }).catch(() => {
            })
        },

        funcOne(event) {
            let result = parseInt(0 + event.target.value);
            return this.formData.kilo = this.kiloF(this.pad(result, 7))
        },

        pad(str, max) {
            str = str.toString();
            str = str.length < max ? this.pad("0" + str, max) : str; // zero à esquerda
            str = str.length > max ? str.substring(0, max) : str; // máximo de caracteres
            return str;
        },

        kiloF(v) {
            v = v.replace(/(\d{2})(\d{1,2})$/, "$1.$2") //Coloca um hífen entre o terceiro e o quarto dígitos
            v = v.replace(/(\d{2})(\d)/, "$1,$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
            return v
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        multiselectDeliveryType() {
            return this.$store.state.var.multiselectDeliveryType
        },

        multiselectDelivery() {
            return this.$store.state.var.multiselectDelivery
        },
    }

}
</script>