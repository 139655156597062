import AdminComponent from "@/components/dashboard/AdminComponent";
import ReceiptSearch from "@/views/search/ReceiptSearch";
import ReceiptCreate from "@/views/create/ReceiptCreate";
import ReceiptUpdate from "@/views/update/ReceiptUpdate";

export default {
    path: '/receipt',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'receipt-search', component: ReceiptSearch},
        {path: 'create', name: 'receipt-create', component: ReceiptCreate},
        {path: 'edit/:id', name: 'receipt-edit', component: ReceiptUpdate, props: true},
    ]
}