<template>
    <painel-component>
        <error-component :errors="errors" v-if="showError"/>

        <div class="tabs effect-3" v-if="viewPage">
            <input type="radio" id="tab-1" name="tab-effect-3" checked="checked">
            <span>
                <i class="fa-solid fa-circle-info"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="fa-solid fa-envelope"></i>
                 <p>Email</p>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3">
            <span>
                <i class="fa-solid fa-square-phone"></i>
                <p>Telefone</p>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3">
            <span>
                <i class="fa-solid fa-money-bill-1"></i>
                <p>Conta Bancária</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.name}">
                                <label>Nome:</label>
                                <input-component v-model="formData.name" :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>CPF:</label>
                                <input-component v-model="formData.cpf" v-mask="'###.###.###-##'" :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>RG:</label>
                                <input-component v-model="formData.rg" v-mask="['##.###.###-X', '#.###.###']"
                                                 :type="'text'"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Data Nascimento:</label>
                                <input-component v-model="formData.birth_date" v-mask="'##/##/####'" :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.status}">
                                <label>Status:</label>
                                <Multiselect v-model="formData.status" :options="selectStatus" :searchable="true"
                                             :noResultsText="noResultText" :noOptionsText="noOptionsText"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.sexo}">
                                <label>Sexo:</label>
                                <Multiselect v-model="formData.sexo" :options="multiselectSexo" :searchable="true"
                                             :noResultsText="noResultText" :noOptionsText="noOptionsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>CEP:</label>
                                <input-component @change="searchCEP" v-model="formData.cep" v-mask="'#####-###'"
                                                 :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Cidade:</label>
                                <input-component v-model="formData.city" :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Estado:</label>
                                <input-component v-model="formData.state" :type="'text'"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>Endereço:</label>
                                <input-component v-model="formData.address" :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Bairro:</label>
                                <input-component v-model="formData.district" :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Pais:</label>
                                <input-component v-model="formData.country" :type="'text'"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <label>Complemento:</label>
                                <text-area v-model="formData.complement" :rows="5"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="clearfix">
                        <button-submit @click="emailsAdd" class="float-end mb-3" icon="fa-solid fa-plus"/>
                    </div>

                    <div class="bg-body-secondary p-3 my-3" v-for="(data, index) in addEmails" :key="index">
                        <form>
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Receber notificação:</label>

                                    <div class="form-check form-check-inline ms-2">
                                        <input v-model="data.notification" class="form-check-input" type="radio"
                                               value="1" :id="'emailS' + index">
                                        <label class="form-check-label" :for="'email' + index">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-model="data.notification" class="form-check-input" type="radio"
                                               value="2" :id="'emailN' + index"
                                               checked>
                                        <label class="form-check-label" :for="'emailN' + index">Não</label>
                                    </div>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component v-model="data.name" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Email:</label>
                                    <input-component v-model="data.email" :type="'email'"/>
                                </row-col-component>
                            </row-component>
                        </form>
                        <div class="clearfix" v-if="addEmails.length > 1">
                            <button-delete @click="removeEmail(index)" class="float-end"/>
                        </div>
                    </div>
                </section>

                <section id="tab-item-3">
                    <div class="clearfix">
                        <button-submit @click="phonesAdd" class="float-end mb-3" icon="fa-solid fa-plus"/>
                    </div>

                    <div class="bg-body-secondary p-3 my-3" v-for="(data, index) in addPhones" :key="index">
                        <form>
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Receber notificação:</label>

                                    <div class="form-check form-check-inline ms-2">
                                        <input v-model="data.notification" class="form-check-input" type="radio"
                                               value="1" :id="'emailS' + index">
                                        <label class="form-check-label" :for="'email' + index">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-model="data.notification" class="form-check-input" type="radio"
                                               value="2" :id="'emailN' + index"
                                               checked>
                                        <label class="form-check-label" :for="'emailN' + index">Não</label>
                                    </div>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component v-model="data.name" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Telefone:</label>
                                    <input-component v-model="data.phone" :type="'text'"
                                                     v-mask="['(##) ####-####', '(##) #####-####']"/>
                                </row-col-component>
                            </row-component>
                        </form>
                        <div class="clearfix" v-if="addPhones.length > 1">
                            <button-delete @click="removePhone(index)" class="float-end"/>
                        </div>
                    </div>
                </section>

                <section id="tab-item-4">
                    <div class="clearfix">
                        <button-submit @click="accountAdd" class="float-end" icon="fa-solid fa-plus"/>
                    </div>

                    <div class="bg-body-secondary p-3 my-3" v-for="(data, index) in addAccounts" :key="index">
                        <form>
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Banco:</label>
                                    <Multiselect v-model="data.bank" :options="multiselectBankList" :searchable="true"
                                                 :noResultsText="noResultText" :noOptionsText="noOptionsText"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Tipo de Conta:</label>
                                    <Multiselect v-model="data.account_type" :options="multiselectAccountType"
                                                 :searchable="true" :noResultsText="noResultText"
                                                 :noOptionsText="noOptionsText"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Agência:</label>
                                    <input-component v-model="data.agency" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Conta/Dígito:</label>
                                    <input-component v-model="data.digit_account" :type="'text'"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Tipo PIX:</label>
                                    <Multiselect v-model="data.pix_type" :options="multiselectPIX" :searchable="true"
                                                 :noResultsText="noResultText" :noOptionsText="noOptionsText"/>
                                </row-col-component>

                                <row-col-component v-if="data.pix_type === 1"
                                                   class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Chave:</label>
                                    <input-component v-model="data.key_pix" :type="'text'"
                                                     v-mask="['##.###.###/####-##','###.###.###-##']"/>
                                </row-col-component>

                                <row-col-component v-else-if="data.pix_type === 2"
                                                   class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Chave:</label>
                                    <input-component v-model="data.key_pix" :type="'text'"
                                                     v-mask="['(##) ####-####','(##) #####-####']"/>
                                </row-col-component>

                                <row-col-component v-else-if="data.pix_type === 3"
                                                   class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Chave:</label>
                                    <input-component :type="'email'"/>
                                </row-col-component>

                                <row-col-component v-else-if="data.pix_type === 4"
                                                   class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Chave:</label>
                                    <input-component v-model="data.key_pix" :type="'text'"/>
                                </row-col-component>

                                <row-col-component v-else
                                                   class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Chave:</label>
                                    <input-component v-model="data.key_pix" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <div class="mt-4">
                                        <label>Conta Principal:</label>
                                        <div class="form-check form-check-inline ms-3">
                                            <input v-model="data.main_account" class="form-check-input" type="radio"
                                                   value="1" name="doc" :id="'nao' + index">
                                            <label class="form-check-label" :for="'nao' + index">Não</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input v-model="data.main_account" class="form-check-input" type="radio"
                                                   value="2" name="doc" :id="'sim' + index">
                                            <label class="form-check-label" :for="'sim' + index">Sim</label>
                                        </div>
                                    </div>
                                </row-col-component>
                            </row-component>
                        </form>
                        <div class="clearfix" v-if="addAccounts.length > 1">
                            <button-delete @click="removeAccount(index)" class="float-end"/>
                        </div>
                    </div>
                </section>

                <row-component>
                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-submit :disabled="sendForm" @submit="submit" class="w-100" name="Adicionar"
                                       icon="fa-solid fa-circle-plus"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-route class="w-100" :items="itemsOne"/>
                    </row-col-component>
                </row-component>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import TextArea from "@/components/forms/TextArea";
import Multiselect from "@vueform/multiselect";
import RowComponent from "@/components/row/RowComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import {ESTADOS} from "@/configs/constants";
import {mask} from "vue-the-mask";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "GuideCreate",
    components: {
        ButtonDelete,
        ButtonRoute,
        ButtonSubmit,
        ErrorComponent,
        RowComponent,
        TextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        PainelComponent
    },

    directives: {
        mask
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Representante Adicionar')
        this.nullInput()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                name: 'Voltar',
                route: '/guide/search',
                icon: 'fa-solid fa-rotate-left'
            },

            formData: {
                name: "",
                cpf: "",
                rg: "",
                birth_date: "",
                type: "1",
                status: "",
                sexo: "",
                emails: [],
                phones: [],
                bank_accounts: [],
                cep: "",
                city: "",
                state: "",
                district: "",
                address: "",
                complement: "",
                country: "Brasil"
            },

            errors: [],
            sendForm: false,
            viewPage: false,
            showError: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeGuide', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro efetuado com sucesso', {timeout: 1500})
                        this.$router.push({name: 'guide-edit', params: {id: r}})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 19)
                .then((r) => {
                    if (r.data.indexOf('guide-create') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        searchCEP() {
            this.resetFormAddress()
            let cep = this.formData.cep.replace(/\D/g, '');
            const params = {'cep': cep}
            this.$store.dispatch('searchApiCEP', params)
                .then((r) => {
                    this.formData.city = r.data.localidade
                    this.formData.state = r.data.uf
                    this.formData.stateView = ESTADOS[r.data.uf]
                    this.formData.district = r.data.bairro
                    this.formData.address = r.data.logradouro
                    this.formData.complement = r.data.complemento
                }).catch(() => {
            })
        },

        resetFormAddress() {
            this.formData.city = ''
            this.formData.state = ''
            this.formData.stateView = ''
            this.formData.district = ''
            this.formData.address = ''
            this.formData.complement = ''
            this.formData.country = 'Brasil'
        },


        nullInput() {
            if (this.formData.emails.length === 0)
                this.emailsAdd()
            if (this.formData.phones.length === 0)
                this.phonesAdd()
            if (this.formData.bank_accounts.length === 0)
                this.accountAdd()
        },

        emailsAdd() {
            this.formData.emails.push({
                name: "",
                email: "",
                notification: 2
            })
        },

        accountAdd() {
            this.formData.bank_accounts.push({
                bank: "",
                account_type: "",
                agency: "",
                digit_account: "",
                pix_type: "",
                key_pix: "",
                main_account: 1
            })
        },

        phonesAdd() {
            this.formData.phones.push({
                name: "",
                phone: "",
                notification: 2
            })
        },

        removeEmail(index) {
            this.formData.emails.splice(index, 1)
        },

        removePhone(index) {
            this.formData.phones.splice(index, 1)
        },

        removeAccount(index) {
            this.formData.bank_accounts.splice(index, 1)
        }
    },

    computed: {
        noResultText() {
            return this.$store.state.var.noResultText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectStatus() {
            return this.$store.state.var.selectStatus
        },

        multiselectSexo() {
            return this.$store.state.var.multiselectSexo
        },

        multiselectBankList() {
            return this.$store.state.var.multiselectBankList
        },

        multiselectAccountType() {
            return this.$store.state.var.multiselectAccountType
        },

        multiselectPIX() {
            return this.$store.state.var.multiselectPIX
        },

        addEmails() {
            return this.formData.emails
        },

        addPhones() {
            return this.formData.phones
        },

        addAccounts() {
            return this.formData.bank_accounts
        }
    }
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: calc(100% / 4);
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 25%;
}

#tab-3,
#tab-3 + span,
#tab-3:checked ~ .line {
    left: 50%;
}

#tab-4,
#tab-4 + span,
#tab-4:checked ~ .line {
    left: 75%;
}
</style>