import {createStore} from 'vuex'
import createPersistedState from 'vuex-persistedstate';

import AccountType from "@/store/modules/account-type";
import AdditionalService from "@/store/modules/additional-service";
import Auth from "@/store/modules/auth";
import BankAccount from "@/store/modules/bank-account";
import Bath from "@/store/modules/bath";
import BathGroup from "@/store/modules/bath-group";
import Client from "@/store/modules/client";
import Closure from "@/store/modules/closure";
import Employee from "@/store/modules/employee";
import EntranceExit from "@/store/modules/entrance-exit";
import EntranceExitForecast from "@/store/modules/entrance-exit-forecast";
import External from "@/store/modules/external";
import FormPayment from "@/store/modules/form-payment";
import Guide from "@/store/modules/guide";
import Home from "@/store/modules/home"
import Inspection from "@/store/modules/inspection";
import Inventorie from "@/store/modules/inventorie";
import Loader from "@/store/modules/loader";
import Mooring from "@/store/modules/mooring";
import OrderService from "@/store/modules/order-service";
import Product from "@/store/modules/product";
import Provider from "@/store/modules/provider";
import Receipt from "@/store/modules/receipt";
import Separation from "@/store/modules/separation";
import Service from "@/store/modules/service";
import Settings from "@/store/modules/settings";
import Shipping from "@/store/modules/shipping";
import Stock from "@/store/modules/stock";
import User from "@/store/modules/user";
import UserGroup from "@/store/modules/user-group";
import Var from '@/store/modules/var'

export default createStore({
    plugins:[createPersistedState()],
    modules: {
        account_type: AccountType,
        additional_service: AdditionalService,
        auth: Auth,
        bank_account: BankAccount,
        bath: Bath,
        bath_group: BathGroup,
        client: Client,
        closure: Closure,
        employee: Employee,
        entrance_exit: EntranceExit,
        entrance_exit_forecast: EntranceExitForecast,
        external: External,
        form_payment: FormPayment,
        guide: Guide,
        home: Home,
        inspection: Inspection,
        inventorie: Inventorie,
        loader: Loader,
        mooring: Mooring,
        order_service: OrderService,
        product: Product,
        provider: Provider,
        receipt: Receipt,
        separation: Separation,
        service: Service,
        settings: Settings,
        shipping: Shipping,
        stock: Stock,
        user: User,
        user_group: UserGroup,
        var: Var
    }
})
