<template>
    <painel-component>
        <error-component :errors="errors" v-if="showError"/>

        <form v-if="viewPage">
            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.type}">
                    <label>Entrada/Saida:</label>
                    <Multiselect v-model="formData.type" :options="multiselectEntranceExit"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.attended}">
                    <label>Antecipado:</label>
                    <Multiselect v-model="formData.attended" :options="multiselectYesNo" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.bank_accounts_id}">
                    <label>Conta Bancária:</label>
                    <Multiselect label="name" v-model="formData.bank_accounts_id" :options="multiselectAccountBank"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.account_types_id}">
                    <label>Tipo de Conta:</label>
                    <Multiselect v-model="formData.account_types_id" :options="multiselectAccountTypeAPI"
                                 :noOptionsText="noOptionsText"
                                 @select="accountSub(formData.account_types_id)"
                                 @clear="accountClear"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.account_type_sub_id}">
                    <label>Tipo de SubConta:</label>
                    <Multiselect :disabled="sub_disabled" v-model="formData.account_type_sub_id"
                                 :options="multiselectAccountTypeSub"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.price}">
                    <label>Valor:</label>
                    <input-component v-model="formData.price" v-money="money" :type="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.obs}">
                    <label>Observações:</label>
                    <text-area v-model="formData.obs" rows="5"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" @submit="submit" class="w-100" name="Adicionar"
                                   icon="fa-solid fa-circle-plus"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowColComponent from "@/components/row/RowColComponent";
import RowComponent from "@/components/row/RowComponent";
import InputComponent from "@/components/forms/InputComponent";
import TextArea from "@/components/forms/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import Multiselect from "@vueform/multiselect";
import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";
import ErrorComponent from "@/components/alerts/ErrorComponent.vue";

const toast = useToast()
export default {
    name: 'EntranceExitCreate',
    components: {
        ErrorComponent,
        Multiselect,
        ButtonRoute,
        ButtonSubmit,
        TextArea,
        InputComponent,
        RowComponent,
        RowColComponent,
        PainelComponent
    },

    directives: {
        money: VMoney
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Entrada/Saída Adicionar')
        this.$store.dispatch('loadMultiSelectAccountBank')
        this.$store.dispatch('loadMultiSelectAccountType')
        this.checkACL()
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: '/entrance-exit/search',
                icon: 'fa-solid fa-rotate-left'
            },

            formData: {
                type: '',
                bank_accounts_id: '',
                account_types_id: '',
                account_type_sub_id: '',
                price: '',
                business: '',
                obs: '',
                account_type_sub: '',
                attended: ''
            },

            sub_disabled: true,

            money: MONEY,

            viewPage: false,

            errors: [],
            sendForm: false,
            showError: false
        }
    },

    methods: {

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeEntranceExit', this.formData)
                    .then(() => {
                        this.sendForm = false
                        this.errors = []
                        this.showError = false
                        toast.success('Cadastro efetuado com sucesso', {timeout: 1500})
                        this.$router.push({name: 'entrance-exit-search'})
                    }).catch((e) => {
                    this.sendForm = false
                    this.showError = true
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 12)
                .then((r) => {
                    if (r.data.indexOf('entrance-exit-create') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        accountSub(id) {
            this.$store.dispatch('loadMultiSelectAccountTypeSub', id)
            this.sub_disabled = false
        },

        accountClear() {
            this.formData.account_types_id = ''
            this.formData.account_type_sub_id = ''
            this.sub_disabled = true
        }
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        multiselectYesNo() {
            return this.$store.state.var.multiselectYesNo
        },

        multiselectEntranceExit() {
            return this.$store.state.var.multiselectEntranceExit
        },

        multiselectAccountBank() {
            return this.$store.state.var.multiselectAccountBank
        },

        multiselectAccountTypeSub() {
            return this.$store.state.var.multiselectAccountTypeSub
        },

        multiselectAccountTypeAPI() {
            return this.$store.state.var.multiselectAccountTypeAPI
        },
    }
}
</script>