export default {
    name: 'Configurações',
    icon: 'fa-solid fa-gear',
    route: '/settings/search',
    permissions: [
        'os-separation'
    ],
    pagePermissions: [
        'settings-edit'
    ]
}