import AdminComponent from "@/components/dashboard/AdminComponent";
import FormPaymentSearch from "@/views/search/FormPaymentSearch";
import FormPaymentCreate from "@/views/create/FormPaymentCreate";
import FormPaymentUpdate from "@/views/update/FormPaymentUpdate";

export default {
    path: '/form-payment',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'form-payment-search', component: FormPaymentSearch},
        {path: 'create', name: 'form-payment-create', component: FormPaymentCreate},
        {path: 'edit/:id', name: 'form-payment-edit', component: FormPaymentUpdate, props: true}
    ]
}