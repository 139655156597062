<template>
    <painel-component>
        <error-component :errors="errors" v-if="showError"/>

        <div class="clearfix">
            <button-route class="my-2 float-end" :items="itemsAdd"/>
        </div>

        <div class="tabs effect-3" v-if="viewPage">
            <input type="radio" id="tab-1" name="tab-effect-3" checked="checked"
                   :class="{ 'mobileTab-client': tabMobile }">
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="fa-solid fa-circle-info"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3" v-if="picked === 2">
            <span v-if="picked === 2">
                <i class="fa-solid fa-circle-user"></i>
                <p>Sócio</p>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="fa-solid fa-envelope"></i>
                <p>Email</p>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="fa-solid fa-square-phone"></i>
                <p>Telefone</p>
            </span>

            <input type="radio" id="tab-5" name="tab-effect-3" v-if="picked === 2">
            <span v-if="picked === 2">
                <i class="fa-solid fa-list-check"></i>
                <p>Atividades econômicas secundárias</p>
            </span>

            <div class="line ease" :class="{ 'line-3': tabMobile }"></div>
            <div class="tab-content">

                <section id="tab-item-1">
                    <form>
                        <div class="aux my-4">
                            <div class="avatar-form">
                                <img v-if="formData.photo" :src="formData.photo" alt="" class="avatar__image">
                                <img v-else src="@/assets/avatar.png" alt="" class="avatar__image">
                            </div>
                            <p>{{ formData.name }}</p>
                            <router-link :to="{ name: 'provider-edit-photo', id: this.id }" class="figure">
                                <i class="fa-solid fa-camera"></i>
                            </router-link>
                        </div>

                        <row-component v-if="picked === 2">
                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.cnpj}">
                                <label>CNPJ:</label>
                                <div class="position-relative">
                                    <input-component disabled v-model="formData.cnpj" :type="'text'"/>
                                    <i @click="searchAPiCNPJ" role="button"
                                       class="fa-solid fa-arrows-rotate position-absolute top-50 end-0 translate-middle-y me-3">
                                    </i>
                                </div>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.size}">
                                <label>Porte:</label>
                                <input-component v-model="formData.size" :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.status}">
                                <label>Status:</label>
                                <Multiselect v-model="formData.status" :options="selectStatus"
                                             :noOptionsText="noOptionsText"
                                             :noResultsText="noResultText" :searchable="true"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    :class="{'formError' : errors.name}">
                                <label>Nome:</label>
                                <input-component v-model="formData.name" :type="'text'"/>
                            </row-col-component>
                        </row-component>

                        <row-component v-if="picked === 2">
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    :class="{'formError' : errors.name_f}">
                                <label>Nome Fantasia:</label>
                                <input-component v-model="formData.name_f" :type="'text'"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    :class="{'formError' : errors.surname}">
                                <label>Apelido:</label>
                                <input-component v-model="formData.surname" :type="'text'"/>
                            </row-col-component>
                        </row-component>

                        <div v-if="picked === 2">
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                        :class="{'formError' : errors.legal_nature}">
                                    <label>Natureza Jurídica:</label>
                                    <input-component v-model="formData.legal_nature" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                        :class="{'formError' : errors.share_capital}">
                                    <label>Capital Social:</label>
                                    <input-component maxlength="12" v-model="formData.share_capital" v-money="money"
                                                     :type="'text'"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                        :class="{'formError' : errors.main_activity}">
                                    <label>Atividade Econômica Principal:</label>
                                    <input-component v-model="formData.main_activity" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                        :class="{'formError' : errors.date_open}">
                                    <label>Data de Abertura:</label>
                                    <input-component v-model="formData.date_open" v-mask="'##/##/####'" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                        :class="{'formError' : errors.headquarters}">
                                    <label>Matriz:</label>
                                    <input-component v-model="formData.headquarters" :type="'text'"/>
                                </row-col-component>
                            </row-component>
                        </div>

                        <div v-if="picked === 1">
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                        :class="{'formError' : errors.cpf}">
                                    <label>CPF:</label>
                                    <input-component disabled v-model="formData.cpf"
                                                     v-mask="'###.###.###-##'" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                        :class="{'formError' : errors.rg}">
                                    <label>RG:</label>
                                    <input-component disabled v-model="formData.rg"
                                                     v-mask="['##.###.###-X', '#.###.###']" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                        :class="{'formError' : errors.birth_date}">
                                    <label>Data Nasc:</label>
                                    <input-component v-model="formData.birth_date" v-mask="'##/##/####'"
                                                     :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                        :class="{'formError' : errors.status}">
                                    <label>Status:</label>
                                    <Multiselect v-model="formData.status" :options="selectStatus"
                                                 :noOptionsText="noOptionsText"
                                                 :noResultsText="noResultText" :searchable="true"/>
                                </row-col-component>
                            </row-component>
                        </div>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.cep}">
                                <label>CEP:</label>
                                <input-component @change="searchApiCEP" v-model="formData.cep" v-mask="'#####-###'"
                                                 :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.city}">
                                <label>Cidade:</label>
                                <input-component v-model="formData.city" :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.state}">
                                <label>Estado:</label>
                                <input-component v-model="formData.state" :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.district}">
                                <label>Bairro:</label>
                                <input-component v-model="formData.district" :type="'text'"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.address}">
                                <label>Endereço:</label>
                                <input-component v-model="formData.address" :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.country}">
                                <label>País:</label>
                                <input-component v-model="formData.country" :type="'text'"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    :class="{'formError' : errors.complement}">
                                <label>Referência:</label>
                                <text-area v-model="formData.complement" rows="5"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>
                <section id="tab-item-2">
                    <div class="clearfix">
                        <button-submit @click="addMembers" class="float-end" icon="fa-solid fa-plus"/>
                    </div>
                    <div class="bg-body-secondary p-3 my-3" v-for="(data, index) in membersAdd" :key="index">
                        <form>
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component v-model="data.name" :type="'text'"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>CPF/CNPJ:</label>
                                    <input-component v-model="data.cpf_cnpj" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Idade:</label>
                                    <input-component v-model="data.age" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Qualificação:</label>
                                    <input-component v-model="data.qualification" :type="'text'"/>
                                </row-col-component>
                            </row-component>
                        </form>
                        <div class="clearfix" v-if="membersAdd.length > 1">
                            <button-submit @click="removeMembers(index)" class="float-end bg-danger border-0"
                                           icon="fa-solid fa-trash"/>
                        </div>
                    </div>
                </section>
                <section id="tab-item-3">
                    <div class="clearfix">
                        <button-submit @click="addEmail" class="float-end" icon="fa-solid fa-plus"/>
                    </div>
                    <div class="bg-body-secondary p-3 my-3" v-for="(data, index) in emailsAdd" :key="index">
                        <form>
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Receber notificação:</label>

                                    <div class="form-check form-check-inline ms-2">
                                        <input v-model="data.notification" class="form-check-input" type="radio"
                                               value="1" :id="'emailS' + index">
                                        <label class="form-check-label" :for="'email' + index">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-model="data.notification" class="form-check-input" type="radio"
                                               value="2" :id="'emailN' + index"
                                               checked>
                                        <label class="form-check-label" :for="'emailN' + index">Não</label>
                                    </div>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component v-model="data.name" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Email:</label>
                                    <input-component v-model="data.email" :type="'email'"/>
                                </row-col-component>
                            </row-component>
                        </form>
                        <div class="clearfix" v-if="emailsAdd.length > 1">
                            <button-submit @click="removeEmail(index)" class="float-end bg-danger border-0"
                                           icon="fa-solid fa-trash"/>
                        </div>
                    </div>
                </section>
                <section id="tab-item-4">
                    <div class="clearfix">
                        <button-submit @click="addPhone" class="float-end" icon="fa-solid fa-plus"/>
                    </div>
                    <div class="bg-body-secondary p-3 my-3" v-for="(data, index) in phonesAdd" :key="index">
                        <form>
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Receber notificação:</label>

                                    <div class="form-check form-check-inline ms-2">
                                        <input v-model="data.notification" class="form-check-input" type="radio"
                                               value="1" :id="'phoneS' + index">
                                        <label class="form-check-label" :for="'phoneS' + index">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-model="data.notification" class="form-check-input" type="radio"
                                               value="2" :id="'phoneN' + index" checked>
                                        <label class="form-check-label" :for="'phoneN' + index">Não</label>
                                    </div>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component v-model="data.name" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Telefone:</label>
                                    <input-component v-model="data.phone" v-mask="['(##) ####-####', '(##) #####-####']"
                                                     :type="'text'"/>
                                </row-col-component>
                            </row-component>
                        </form>
                        <div class="clearfix" v-if="phonesAdd.length > 1">
                            <button-submit @click="removePhone(index)" class="float-end bg-danger border-0"
                                           icon="fa-solid fa-trash"/>
                        </div>
                    </div>
                </section>
                <section id="tab-item-5">
                    <div class="clearfix">
                        <button-submit @click="addActivities" class="float-end" icon="fa-solid fa-plus"/>
                    </div>
                    <div class="bg-body-secondary p-3 my-3" v-for="(data, index) in activitiesAdd" :key="index">
                        <form>
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Código:</label>
                                    <input-component v-model="data.code" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Descrição:</label>
                                    <input-component v-model="data.desc" :type="'email'"/>
                                </row-col-component>
                            </row-component>
                        </form>
                        <div class="clearfix" v-if="activitiesAdd.length > 1">
                            <button-submit @click="removeActivities(index)" class="float-end bg-danger border-0"
                                           icon="fa-solid fa-trash"/>
                        </div>
                    </div>
                </section>

                <row-component>
                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-submit :disabled="sendForm" @submit="submit" class="w-100" name="Salvar"
                                       icon="fa-solid fa-floppy-disk"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-route class="w-100" :items="itemsRoute"/>
                    </row-col-component>
                </row-component>
            </div>
        </div>
    </painel-component>
</template>

<script>
import InputComponent from "@/components/forms/InputComponent";
import Multiselect from "@vueform/multiselect";
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import TextArea from "@/components/forms/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import {mask} from 'vue-the-mask';
import {VMoney} from 'v-money'
import {MONEY} from "@/configs/constants";
import {ESTADOS} from "@/configs/constants";
import {useToast} from "vue-toastification";
import ErrorComponent from "@/components/alerts/ErrorComponent";

const toast = useToast()
export default {
    name: 'ClientUpdate',
    components: {
        ErrorComponent,
        ButtonRoute,
        ButtonSubmit,
        TextArea,
        InputComponent,
        Multiselect,
        PainelComponent,
        RowColComponent,
        RowComponent,
    },

    props: {
        id: {
            required: true
        }
    },

    directives: {
        mask,
        money: VMoney
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Fornecedor Editar')
        this.checkACL()
        this.loadItems()
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: '/provider/search',
                icon: 'fa-solid fa-rotate-left'
            },

            itemsAdd: {
                name: '',
                route: '/provider/create',
                icon: 'fa-solid fa-circle-plus'
            },

            formData: {
                id: this.id,
                cpf: '',
                cnpj: '',
                size: '',
                name: '',
                name_f: '',
                surname: '',
                legal_nature: '',
                share_capital: '',
                main_activity: '',
                date_open: '',
                birth_date: '',
                headquarters: '',
                special_date: '',
                rg: '',
                type: '',
                status: '',
                emails: [],
                phones: [],
                members: [],
                secondary_activitys: [],
                service: '',
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                country: '',
            },

            money: MONEY,

            picked: 2,

            viewPage: false,

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {

        loadItems() {
            this.$store.dispatch('loadProvider', this.id)
                .then((r) => {
                    this.formData = r
                    this.check()
                    this.nullInputArray()
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateProvider', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro atualizado com sucesso', {timeout: 1500})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 4)
                .then((r) => {
                    if (r.data.indexOf('provider-edit') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        check() {
            if (parseInt(this.formData.type) === 1)
                this.picked = 1
            if (parseInt(this.formData.type) === 2)
                this.picked = 2
        },

        dateFormatBR(inputDate) {
            let dateArray = inputDate.split("-");
            return `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}`;
        },

        searchApiCEP() {
            this.resetFormAddress()
            let cep = this.formData.cep.replace(/\D/g, '');
            const dateCEP = {'cep': cep}
            this.$store.dispatch('searchApiCEP', dateCEP)
                .then((r) => {
                    this.formData.city = r.data.localidade
                    this.formData.state = r.data.uf
                    this.formData.stateView = ESTADOS[r.data.uf]
                    this.formData.district = r.data.bairro
                    this.formData.address = r.data.logradouro
                    this.formData.complement = r.data.complemento
                }).catch(() => {
            })
        },

        searchAPiCNPJ() {
            let cnpj = this.formData.cnpj.replace(/\D/g, '');
            const dateCNPJ = {'cnpj': cnpj}
            this.$store.dispatch('searchApiCNPJ', dateCNPJ)
                .then((r) => {
                    this.completeInput(r.data)
                }).catch(() => {
            })
        },

        resetFormAddress() {
            this.formData.city = ''
            this.formData.state = ''
            this.formData.stateView = ''
            this.formData.district = ''
            this.formData.address = ''
            this.formData.complement = ''
            this.formData.country = 'Brasil'
        },

        resetFormData() {
            this.formData = {
                cpf: '',
                cnpj: '',
                size: '',
                name: '',
                name_f: '',
                surname: '',
                legal_nature: '',
                share_capital: '',
                main_activity: '',
                date_open: '',
                birth_date: '',
                headquarters: '',
                rg: '',
                type: this.formData.type,
                status: 1,
                emails: [],
                phones: [],
                members: [],
                secondary_activitys: [],
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                country: '',
                company_status: ''
            }
        },

        completeInput(data) {
            this.formData.name = data.company.name
            this.formData.name_f = data.alias
            this.formData.surname = this.formData.name_f
            this.formData.legal_nature = `${data.company.nature.id} - ${data.company.nature.text}`
            this.formData.share_capital = data.company.equity.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            this.formData.main_activity = `${data.mainActivity.id} - ${data.mainActivity.text}`
            this.formData.date_open = this.dateFormatBR(data.founded)
            this.formData.headquarters = data.head ? 'Sim' : 'Não'
            this.formData.company_status = `${data.status.id} - ${data.status.text}`
            this.formData.cep = data.address.zip
            this.formData.city = data.address.city
            this.formData.stateView = ESTADOS[data.address.state]
            this.formData.state = data.address.state
            this.formData.district = data.address.district
            this.formData.address = `${data.address.street}, ${data.address.number}`
            this.formData.country = data.address.country.name
            this.formData.complement = data.address.details
            this.formData.size = `${data.company.size.id} -  ${data.company.size.acronym} - ${data.company.size.text}`
            if (data.company.members.length > 0) {
                this.formData.members = []
                data.company.members.forEach(element => {
                    this.formData.members.push({
                        name: element.person.name,
                        cpf_cnpj: element.person.taxId,
                        age: element.person.age,
                        qualification: `${element.role.id} - ${element.role.text}`
                    })
                });
            }
            if (data.sideActivities.length > 0) {
                this.formData.secondary_activitys = []
                data.sideActivities.forEach(element => {
                    this.formData.secondary_activitys.push({
                        code: `${element.id}`,
                        desc: `${element.text}`
                    })
                });
            }
        },

        nullInputArray() {
            if (this.membersAdd.length === 0)
                this.addMembers()
            if (this.emailsAdd.length === 0)
                this.addEmail()
            if (this.phonesAdd.length === 0)
                this.addPhone()
            if (this.activitiesAdd.length === 0)
                this.addActivities()
        },

        addMembers() {
            this.formData.members.push({
                name: '',
                cpf_cnpj: '',
                age: '',
                qualification: ''
            })
        },

        addEmail() {
            this.formData.emails.push({
                name: '',
                email: '',
                notification: ''
            })
        },

        addPhone() {
            this.formData.phones.push({
                name: '',
                phone: '',
                notification: ''
            })
        },

        addActivities() {
            this.formData.secondary_activitys.push({
                code: '',
                desc: ''
            })
        },

        removeMembers(index) {
            this.formData.members.splice(index, 1)
        },

        removeEmail(index) {
            this.formData.emails.splice(index, 1)
        },

        removePhone(index) {
            this.formData.phones.splice(index, 1)
        },

        removeActivities(index) {
            this.formData.secondary_activitys.splice(index, 1)
        },

    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        selectStatus() {
            return this.$store.state.var.selectStatus
        },

        membersAdd() {
            return this.formData.members
        },

        emailsAdd() {
            return this.formData.emails
        },

        phonesAdd() {
            return this.formData.phones
        },

        activitiesAdd() {
            return this.formData.secondary_activitys
        },

        tabMobile() {
            return this.picked !== 2;
        },
    }
}
</script>

<style scoped>
.mobileTab-client,
.effect-3 .line.line-3 {
    width: 33% !important;
}

#tab-1.mobileTab-client,
#tab-1.mobileTab-client + span {
    left: 0 !important;
}

#tab-3.mobileTab-client,
#tab-3.mobileTab-client + span,
#tab-3:checked ~ .line.line-3 {
    left: 33% !important;
}

#tab-4.mobileTab-client,
#tab-4.mobileTab-client + span,
#tab-4:checked ~ .line.line-3 {
    left: 66% !important;
}

.tabs > span {
    font-size: 14px;
}

@media (max-width: 1366px) {
    .tabs > span p {
        display: none;
    }

    .tabs > span {
        font-size: 16px;
    }
}
</style>