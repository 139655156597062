<template>
    <painel-component>
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                    <div class="form-check form-check-inline">
                        <input v-model="formData.client_employee_provider_type" class="form-check-input" type="radio"
                               name="docs" value="1" id="cliente">
                        <label class="form-check-label" for="cliente">Cliente</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input v-model="formData.client_employee_provider_type" class="form-check-input" type="radio"
                               name="docs" value="3"
                               id="fornecedor">
                        <label class="form-check-label" for="fornecedor">Fornecedor</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input v-model="formData.client_employee_provider_type" class="form-check-input" type="radio"
                               name="docs" value="2"
                               id="funcionario" checked>
                        <label class="form-check-label" for="funcionario">Funcionário</label>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Código:</label>
                    <input-component v-model="formData.code" :type="'text'" placeholder="Código"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Título:</label>
                    <input-component v-model="formData.title" :type="'text'" placeholder="Título"/>
                </row-col-component>

                <row-col-component v-if="formData.client_employee_provider_type === '1'"
                                   class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Cliente:</label>
                    <Multiselect label="name" v-model="formData.client_employee_provider_id"
                                 :options="multiselectClient"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText" :searchable="true"
                                 placeholder="Cliente"/>
                </row-col-component>

                <row-col-component v-else-if="formData.client_employee_provider_type === '3'"
                                   class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Fornecedor:</label>
                    <Multiselect label="name" v-model="formData.client_employee_provider_id"
                                 :options="multiselectProvider"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText" :searchable="true"
                                 placeholder="Fornecedor"/>
                </row-col-component>

                <row-col-component v-else
                                   class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Funcionário:</label>
                    <Multiselect label="name" v-model="formData.client_employee_provider_id"
                                 :options="multiselectEmployee"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText" :searchable="true"
                                 placeholder="Funcionário"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <button-submit @submit="loadItems" class="me-1" icon="fa-solid fa-magnifying-glass"
                                   name="Pesquisar"/>
                    <button-route v-if="buttonCheck.create" class="ms-1" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>

        <table class="table">
            <thead>
            <tr class="border-top">
                <th scope="col" style="width: 5%">Código</th>
                <th scope="col" style="width: 10%">Criador</th>
                <th scope="col" style="width: 15%">Titulo</th>
                <th scope="col" style="width: 10%">Preço</th>
                <th scope="col" style="width: 20%">Nome</th>
                <th scope="col" style="width: 10%">Ações</th>
            </tr>
            </thead>
            <tbody class="table-group-divider">
            <tr v-for="(data, index) in receipt.data" :key="index">
                <td data-label="Código">{{ data.code }}</td>
                <td data-label="Criador">{{ data.creator_user_name }}</td>
                <td data-label="Titulo">{{ data.title }}</td>
                <td data-label="Preço">{{ data.price }}</td>
                <td data-label="Nome">{{ data.name }}</td>
                <td data-label="Ações">
                    <button-edit route="receipt-edit" :id="1"/>
                    <button-delete @click="destroy(data)"/>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="receipt" :offset="3" @paginate="loadItems"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonEdit from "@/components/buttons/ButtonEdit";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import Multiselect from "@vueform/multiselect";
import swal from "sweetalert";
import {useToast} from "vue-toastification";
import ButtonPagination from "@/components/buttons/ButtonPagination";

const toast = useToast()
export default {
    name: 'ReceiptSearch',
    components: {
        ButtonPagination,
        Multiselect,
        ButtonDelete,
        ButtonEdit,
        ButtonRoute,
        ButtonSubmit,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Recibo Pesquisa')
        this.checkACL()
        this.loadItems()
        this.$store.dispatch('loadMultiSelectClient')
        this.$store.dispatch('loadMultiSelectEmployee')
        this.$store.dispatch('loadMultiSelectProvider')
    },

    data() {
        return {
            itemsRoute: {
                name: 'Adicionar',
                route: '/receipt/create',
                icon: 'fa-solid fa-circle-plus'
            },

            formData: {
                title: '',
                code: '',
                client_employee_provider_type: '2',
                client_employee_provider_id: ''
            },

            buttonCheck: {
                create: false,
                edit: false,
                delete: false
            },

            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadReceipts', {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data.length === 0) {
                                toast.info('Nada foi encontrado com os parâmetros informados!', {timeout: 1500})
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                })
            }
        },

        destroy(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name}, ${this.me.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyReceipt', item.id).then(() => {
                        this.loadItems(1)
                        toast.success('Cadastro deletado com sucesso', {timeout: 1500});
                    })
                        .catch(() => {
                            toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1500});
                        })
                }
            });
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 11)
                .then((r) => {
                    if (r.data.indexOf('receipt-search') !== -1) {
                        if (r.data.indexOf('receipt-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('receipt-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('receipt-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        statusString(value) {
            if (parseInt(value) === 1)
                return 'Ativo'
            return 'Inativo'
        }
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        multiselectTypeReceipt() {
            return this.$store.state.var.multiselectTypeReceipt
        },

        multiselectClient() {
            return this.$store.state.var.multiselectClient
        },

        multiselectEmployee() {
            return this.$store.state.var.multiselectEmployee
        },

        multiselectProvider() {
            return this.$store.state.var.multiselectProvider
        },

        receipt() {
            return this.$store.state.receipt.items
        },

        params() {
            return {
                page: this.receipt.current_page,
                title: this.formData.title,
                code: this.formData.code,
                client_employee_provider_type: this.formData.client_employee_provider_type,
                client_employee_provider_id: this.formData.client_employee_provider_id

            }
        }
    }
}
</script>