<template>
    <painel-component>
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" :type="'text'" placeholder="Digite o nome"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectStatus" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Status"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <button-submit @submit="submit" class="me-1" name="Pesquisar" icon="fa-solid fa-magnifying-glass"/>
                    <button-route v-if="buttonCheck.create" class="ms-1" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>

        <table class="table">
            <thead>
            <tr class="border-top">
                <th scope="col" style="width: 30%">Nome</th>
                <th scope="col" style="width: 30%">Status</th>
                <th scope="col" style="width: 10%" v-if="buttonCheck.edit || buttonCheck.delete">Ações</th>
            </tr>
            </thead>
            <tbody class="table-group-divider">
            <tr v-for="(data, index) in bath_group.data" :key="index">
                <td data-label="Nome">{{ data.name }}</td>
                <td data-label="Status">{{ statusString(data.status) }}</td>
                <td data-label="Ações" v-if="buttonCheck.edit || buttonCheck.delete">
                    <button-edit route="bath-group-edit" :id="data.id" v-if="buttonCheck.edit"/>
                    <button-delete @click="destroy(data)" v-if="buttonCheck.delete"/>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="bath_group" :offset="3" @paginate="loadItems"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import InputComponent from "@/components/forms/InputComponent";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonEdit from "@/components/buttons/ButtonEdit";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import Multiselect from "@vueform/multiselect";
import swal from "sweetalert";
import {useToast} from "vue-toastification";
import ButtonPagination from "@/components/buttons/ButtonPagination.vue";

const toast = useToast()
export default {
    name: 'BathGroupSearch',
    components: {
        ButtonPagination,
        Multiselect,
        ButtonDelete,
        ButtonEdit,
        RowColComponent,
        ButtonSubmit,
        ButtonRoute,
        InputComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Grupo Banho Pesquisa')
        this.checkACL()
        this.loadItems(1)
    },

    data() {
        return {
            itemsRoute: {
                name: 'Adicionar',
                route: '/bath-group/create',
                icon: 'fa-solid fa-circle-plus'
            },

            formData: {
                name: '',
                status: ''
            },

            buttonCheck: {
                create: false,
                edit: false,
                delete: false
            },

            searchSubmit: false
        }
    },

    methods: {

        submit() {
            this.loadItems(1)
            this.searchSubmit = true
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadBathGroups', {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data.length === 0) {
                                toast.info('Nada foi encontrado com os parâmetros informados!', {timeout: 1500})
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                })
            }
        },

        destroy(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name}, ${this.me.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyBathGroup', item.id).then(() => {
                        this.loadItems(1)
                        toast.success('Cadastro deletado com sucesso', {timeout: 1500});
                    })
                        .catch(() => {
                            toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1500});
                        })
                }
            });
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 6)
                .then((r) => {
                    if (r.data.indexOf('bath-group-search') !== -1) {
                        if (r.data.indexOf('bath-group-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('bath-group-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('bath-group-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        statusString(value) {
            if (parseInt(value) === 1)
                return 'Ativo'
            return 'Inativo'
        }

    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        selectStatus() {
            return this.$store.state.var.selectStatus
        },

        bath_group() {
            return this.$store.state.bath_group.items
        },

        params() {
            return {
                page: this.bath_group.current_page,
                name: this.formData.name,
                status: this.formData.status
            }
        }

    }
}
</script>