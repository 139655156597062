import AdminComponent from "@/components/dashboard/AdminComponent";

import StockClientSearch from "@/views/search/StockClientSearch";
import StockClientCreate from "@/views/create/StockClientCreate";
import StockClientUpdate from "@/views/update/StockClientUpdate";

import StockClientCreateBath from "@/views/create/StockClientCreateBath";
import StockClientUpdateBath from "@/views/update/StockClientUpdateBath";

import StockClientCreateBathOS from "@/views/create/StockClientCreateBathOS";

export default {
    path: "/stock-client",
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: "search", name: "stock-client-search", component: StockClientSearch},
        {path: "create", name: "stock-client-create", component: StockClientCreate},
        {path: "edit/:id", name: "stock-client-edit", component: StockClientUpdate, props: true},

        {path: "stock-create-bath/:id", name: "stock-client-create-bath", component: StockClientCreateBath, props: true},
        {path: "stock-edit-bath/:id/:bath", name: "stock-client-edit-bath", component: StockClientUpdateBath, props: true},

        {path: "stock-create-bath-os/:id/:id_client", name: "stock-create-bath-os", component: StockClientCreateBathOS, props: true}
    ]
}