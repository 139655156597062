import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";

import {NAME_TOKEN} from "@/configs/constants";

// Main CSS
import "@/assets/css/main.css"

// Toast Notification
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css"

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

// FontAwesome Icons
import "@/assets/icons/fontawesome.css"

const tokenAccess = localStorage.getItem(NAME_TOKEN)
if (tokenAccess)
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenAccess}`;

if (process.env.NODE_ENV === 'production')
    axios.defaults.baseURL = 'https://system.iwassabrutos.com'
else
    axios.defaults.baseURL = 'https://iwassa.d7mmarketing.com.br'

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

createApp(App)
    .use(Toast)
    .use(store)
    .use(router)
    .mount('#app')

store.dispatch('loginCheck')
    .catch(() => {
        store.dispatch('logout')
    })