import actions from "@/store/modules/order-service/actions";
import getters from "@/store/modules/order-service/getters";
import mutations from "@/store/modules/order-service/mutations";
import state from "@/store/modules/order-service/state";

export default {
    actions,
    getters,
    mutations,
    state
}