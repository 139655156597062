<template>
    <painel-component>
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Produto:</label>
                    <Multiselect v-model="value" :options="[]" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Produto"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Fornecedor:</label>
                    <Multiselect v-model="value" :options="[]" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Fornecedor"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Entrada/Saída:</label>
                    <Multiselect v-model="value" :options="multiselectEntranceExit" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Entrada/Saída"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Mês:</label>
                    <Multiselect v-model="value" :options="multiselectMonth" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Mês"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Ano:</label>
                    <Multiselect v-model="value" :options="[]" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Ano"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4">
                    <button-submit class="me-1" name="Pesquisar" icon="fa-solid fa-magnifying-glass"/>
                    <button-route class="ms-1" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>

        <table class="table mt-3">
            <thead>
            <tr class="border-top">
                <th scope="col" style="width: 15%">Produto</th>
                <th scope="col" style="width: 15%">Quantidade</th>
                <th scope="col" style="width: 15%">Entrada/Saída</th>
                <th scope="col" style="width: 15%">Data - Horário</th>
                <th scope="col" style="width: 15%">Preço</th>
                <th scope="col" style="width: 10%">Ações</th>
            </tr>
            </thead>
            <tbody class="table-group-divider">
            <tr>
                <td data-label="Produto">Produto</td>
                <td data-label="Quantidade">1</td>
                <td data-label="Entrada/Saída">Entrada</td>
                <td data-label="Data - Horário">10/09/2001 - 20:00h</td>
                <td data-label="Preço">R$ 10,00</td>
                <td data-label="Ações">
                    <button-edit/>
                    <button-delete/>
                </td>
            </tr>
            </tbody>
        </table>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import Multiselect from "@vueform/multiselect";
import ButtonRoute from "@/components/buttons/ButtonRoute.vue";
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";
import ButtonEdit from "@/components/buttons/ButtonEdit.vue";
import ButtonDelete from "@/components/buttons/ButtonDelete.vue";

export default {
    name: 'StockSearch',
    components: {
        ButtonDelete,
        ButtonEdit,
        ButtonSubmit,
        ButtonRoute,
        Multiselect,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Estoque Pesquisa')
    },

    data() {
        return {
            itemsRoute: {
                name: 'Adicionar',
                route: '/stock/create',
                icon: 'fa-solid fa-circle-plus'
            }
        }
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        selectStatus() {
            return this.$store.state.var.selectStatus
        },

        multiselectMonth() {
            return this.$store.state.var.multiselectMonth
        },

        multiselectEntranceExit() {
            return this.$store.state.var.multiselectEntranceExit
        },
    }
}
</script>