import AdminComponent from "@/components/dashboard/AdminComponent";
import BathGroupSearch from "@/views/search/BathGroupSearch";
import BathGroupCreate from "@/views/create/BathGroupCreate";
import BathGroupUpdate from "@/views/update/BathGroupUpdate";

export default {
    path: '/bath-group',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'bath-group-search', component: BathGroupSearch},
        {path: 'create', name: 'bath-group-create', component: BathGroupCreate},
        {path: 'edit/:id', name: 'bath-group-edit', component: BathGroupUpdate, props: true},
    ]
}