import AdminComponent from "@/components/dashboard/AdminComponent";

import HomeView from "@/views/HomeView";
import LoginView from "@/views/LoginView";

import AccountType from "@/router/modules/account-type";
import AdditionalService from "@/router/modules/additional-service";
import BankAccount from "@/router/modules/bank-account";
import Bath from "@/router/modules/bath";
import BathGroup from "@/router/modules/bath-group";
import Client from "@/router/modules/client";
import Closure from "@/router/modules/closure";
import Employee from "@/router/modules/employee";
import EntranceExit from "@/router/modules/entrance-exit";
import EntranceExitForecast from "@/router/modules/entrance-exit-forecast";
import FormPayment from "@/router/modules/form-payment";
import Guide from "@/router/modules/guide";
import Inspection from "@/router/modules/inspection";
import Inventorie from "@/router/modules/inventorie";
import Mooring from "@/router/modules/mooring";
import OS from "@/router/modules/os";
import Product from "@/router/modules/product";
import Provider from "@/router/modules/provider";
import Receipt from "@/router/modules/receipt";
import Separation from "@/router/modules/separation";
import Service from "@/router/modules/service";
import Settings from "@/router/modules/settings";
import Shipping from "@/router/modules/shipping";
import Stock from "@/router/modules/stock";
import StockClient from "@/router/modules/stock-client";
import User from "@/router/modules/user";
import UserGroup from "@/router/modules/user-group";

const routes = [
    {
        path: '/',
        component: AdminComponent,
        children: [
            {path: '/login', component: LoginView, name: 'login'},
            {path: '/', component: HomeView, name: 'home', meta: {auth: true}}
        ]
    },

    AccountType,
    AdditionalService,
    BankAccount,
    Bath,
    BathGroup,
    Client,
    Closure,
    Employee,
    EntranceExit,
    EntranceExitForecast,
    FormPayment,
    Guide,
    Inspection,
    Inventorie,
    Mooring,
    OS,
    Product,
    Provider,
    Receipt,
    Separation,
    Service,
    Settings,
    Shipping,
    Stock,
    StockClient,
    User,
    UserGroup
]

export default routes
