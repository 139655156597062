<template>
    <div class="body-sys container mt-5 py-5 px-3 px-lg-5">
        <error-component :errors="errors" v-if="showError"/>

        <p><strong>{{ formData.title }}</strong></p>
        <hr>

        <row-component class="justify-content-between">
            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                <h5 class="text-uppercase">Estoque</h5>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <button-route class="float-end" :items="itemsOne"/>
            </row-col-component>
        </row-component>

        <template v-for="(data, index) in formData.baths" :key="index">
            <div class="carousel__item">
                <input @click="enableInput(index)" type="checkbox" v-model="baths" name="thumbs" :id="'thumbs' + index"
                       :value="data.id">
                <label class="label-select" :for="'thumbs' + index">
                    <div class="bg-body-secondary p-4">
                        <div class="img-client">
                            <img :src="data.photo" alt="">
                        </div>

                        <div>
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Grupo Banho:</label>
                                    <input-component disabled v-model="data.group_name"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Quantidade Estoque:</label>
                                    <input-component type="number" disabled v-model="data.the_amount"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Peso:</label>
                                    <input-component disabled v-model="data.kilo"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Preço Bruto:</label>
                                    <input-component disabled v-model="data.price_bruto"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Código</label>
                                    <input-component disabled v-model="data.code"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Origem</label>
                                    <input-component disabled v-model="data.origem"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Quantidade Remover</label>
                                    <input-component :id="'stock' + index" disabled type="number" v-model="data.stock"/>
                                </row-col-component>
                            </row-component>
                        </div>
                    </div>
                </label>
            </div>
        </template>

        <button-submit :disables="sendForm" @click="submit" icon="fa-solid fa-plus" name="Adicionar"/>
    </div>
</template>

<script>
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import InputComponent from "@/components/forms/InputComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";

import {useToast} from "vue-toastification";
import ButtonRoute from "@/components/buttons/ButtonRoute.vue";

const toast = useToast()
export default {
    name: "StockClientCreateBathOS",
    components: {
        ButtonRoute,
        ErrorComponent,
        InputComponent,
        ButtonSubmit,
        RowColComponent,
        RowComponent
    },

    props: {
        id: {
            required: true
        },

        id_client: {
            required: true
        },
    },

    created() {
        this.$store.dispatch("updateNamePage", "Estoque Cliente OS")
        this.loadItems()
    },

    data() {
        return {
            itemsOne: {
                route: `/os/edit/${this.id}`,
                icon: "fa-solid fa-rotate-left",
            },

            formData: {
                id: "",
                client_id: "",
                location_id: "",
                code: "",
                code_url: "",
                idOS: "",
                title: "",
                baths: []
            },

            baths: [],

            errors: [],
            sendForm: false,
            showError: false,
        }
    },

    methods: {
        loadItems() {
            let formData = {
                id: this.id,
                id_client: this.id_client
            }
            this.$store.dispatch("loadStockBathOS", formData)
                .then((response) => {
                    this.formData = response
                    this.addProp(response.baths)
                })
        },

        addProp(data) {
            data.forEach((_element) => {
                _element.stock = ""
            })
        },

        enableInput(index) {
            let field = document.querySelector("#stock" + index)
            field.disabled = field.disabled !== true;
        },

        submit() {
            let formData = {
                order_service_id: this.id,
                baths: []
            }

            this.baths.forEach((el) => {
                let selectBaths = this.formData.baths.find((_element) => _element.id === el)

                formData.baths.push({
                    client_stock_parts_id: el,
                    the_amount: selectBaths.stock,
                })
            })

            this.$store.dispatch("storeStockBathOS", formData)
                .then(() => {
                    toast.success("Adicionado a OS com sucesso")
                    this.$router.push({name: "os-edit", params: {id: this.id}})
                }).catch((e) => {
                this.showError = true
                this.sendForm = false
                this.errors = e.response.data.errors
            })
        },
    },
}
</script>

<style scoped>
.body-sys {
    background: #FFFFFF;
    margin-bottom: 100px;
    -webkit-box-shadow: 9px 17px 31px 0 rgba(150, 150, 150, 1);
    -moz-box-shadow: 9px 17px 31px 0 rgba(150, 150, 150, 1);
    box-shadow: 9px 17px 31px 0 rgba(150, 150, 150, 1);
}

.img-client {
    width: 200px;
    height: 200px;
    display: block;
    margin-right: 1rem;
}

.img-client img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carousel__item {
    position: relative;
}

input[type="checkbox"][id^="thumbs"] {
    display: none;
}

.carousel__item > .label-select {
    width: 100%;
    height: 248px;
    cursor: pointer;
    margin-bottom: 1.5rem;
}

.carousel__item > .label-select > div {
    display: flex;
}

.carousel__item > .label-select:before {
    content: " ";
    top: 10px;
    z-index: 1;
    right: 10px;
    width: 25px;
    height: 25px;
    color: white;
    display: block;
    line-height: 22px;
    border-radius: 50%;
    position: absolute;
    text-align: center;
    transform: scale(0);
    border: 1px solid #9FA692;
    background-color: white;
    transition-duration: 0.4s;
}

.carousel__item input:checked + .label-select:before {
    content: "✓";
    transform: scale(1);
    background-color: #9FA692;
}

.carousel__item input:checked + .label-select {
    outline: 2px solid #9FA692;
}

.carousel__item input[type="checkbox"] + .label-select::after {
    display: none;
}

@media (max-width: 991px) {

    .carousel__item > .label-select {
        height: auto;
    }

    .carousel__item > .label-select > div {
        display: flex;
        flex-direction: column;
    }

    .img-client {
        margin: 1rem auto;
    }
}
</style>
