export default {
    STORAGE(state, name) {
        state.storage = name
    },

    CHECKED_TAB(state, bool) {
        state.checked = bool
    },

    SECTION_ID(state, id) {
        state.section_id = id
    },

    UPDATE_NAME_PAGE(state, name) {
        state.namePage = name
    },

    UPDATE_SELECT_PERMISSION(state, name) {
        state.selectPermissions = name
    },

    UPDATE_MULTISELECT_USER_GROUPS(state, name) {
        state.multiselectUserGroups = name
    },

    UPDATE_MULTISELECT_CLIENT(state, name) {
        state.multiselectClient = name
    },

    UPDATE_MULTISELECT_EMPLOYEE(state, name) {
        state.multiselectEmployee = name
    },

    UPDATE_MULTISELECT_PROVIDER(state, name) {
        state.multiselectProvider = name
    },

    UPDATE_MULTISELECT_ACCOUNT_BANK(state, name) {
        state.multiselectAccountBank = name
    },

    UPDATE_MULTISELECT_PAYMENT_METHODS(state, name) {
        state.multiselectPaymentMethods = name
    },

    UPDATE_MULTISELECT_ACCOUNT_TYPE(state, name) {
        state.multiselectAccountTypeAPI = name
    },

    UPDATE_MULTISELECT_ACCOUNT_TYPE_SUB(state, name) {
        state.multiselectAccountTypeSub = name
    },

    UPDATE_MULTISELECT_CLASSIFICATION(state, name) {
        state.multiselectClassification = name
    },

    UPDATE_MULTISELECT_PRODUCT(state, name) {
        state.multiselectProduct = name
    },

    UPDATE_BATH_GROUP(state, name) {
        state.multiselectBathGroup = name
    },

    UPDATE_SERVICE(state, name) {
        state.multiselectService = name
    },

    UPDATE_SERVICE_WITH_TABLE(state, name) {
        state.multiselectServiceWithTable = name
    },

    UPDATE_SERVICE_ONE(state, name) {
        state.multiselectServiceOne = name
    },

    UPDATE_SERVICE_TWO(state, name) {
        state.multiselectServiceTwo = name
    },

    UPDATE_SERVICE_THREE(state, name) {
        state.multiselectServiceThree = name
    },

    UPDATE_CLIENT_LIST_EMAIL(state, name) {
        state.multiClientListEmail = name
    },

    UPDATE_MULTISELECT_GUIDE(state, name) {
        state.multiselectGuide = name
    },
}