import AdminComponent from "@/components/dashboard/AdminComponent";

import ClosureSearch from "@/views/search/ClosureSearch";
import ClosureShow from "@/views/show/ClosureShow";

export default {
    path: '/closure',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'closure-search', component: ClosureSearch},
        {path: 'show/:id', name: 'closure-show', props: true, component: ClosureShow}
    ]
}