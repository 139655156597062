<template>
    <painel-component>
        <error-component :errors="errors" v-if="showError" :class="'mb-5'"/>

        <div class="aux my-4">
            <div class="avatar-form">
                <img v-if="me.photo" :src="imagePreview" alt="" class="avatar__image">
                <img v-else src="@/assets/avatar.png" alt="" class="avatar__image">
            </div>
            <p>{{ me.fullName }}</p>
        </div>

        <form autocomplete="off">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Senha</label>
                    <input-component type="password" v-model="formDate.password"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <label>Confirmar Senha</label>
                    <input-component type="password" v-model="formDate.passwordconfirm"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" @submit="submit" class="w-100" name="Salvar"
                                   icon="fa-solid fa-floppy-disk"/>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                    <button-route :items="itemsRoute" class="w-100"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "UserUpdatePassword",

    created() {
        this.$store.dispatch('updateNamePage', 'Usuário Editar')
    },

    components: {
        ButtonSubmit,
        ButtonRoute,
        PainelComponent,
        InputComponent,
        RowComponent,
        RowColComponent,
        ErrorComponent,
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: '/',
                icon: 'fa-solid fa-rotate-left'
            },

            formDate: {
                password: '',
                passwordconfirm: ''
            },

            imagePreview: "",

            errors: [],
            showError: false,
            sendForm: false,
        }
    },
    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateUserPassword', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        toast.success('Senha atualizada com sucesso', {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        photoPreviewTimer() {
            this.imagePreview = this.me.photo
        }
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },
    },

    beforeMount() {
        setTimeout(() => this.photoPreviewTimer(), 1000);
    },

}
</script>
