<template>
    <div class="modal-window top-50 start-50 translate-middle" v-if="obs_modal || sector_modal">
        <div class="add-obs" v-if="obs_modal">
            <div @click="clsObs" class="btn-close"></div>

            <form>
                <row-component>
                    <row-col-component
                            class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <label v-if="obsInt">Observações Internas:</label>
                        <text-area v-model="add_obs.obs" rows="5"/>
                    </row-col-component>
                </row-component>
            </form>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <button-submit @submit="submitObsInt" v-if="obsInt" icon="fa-solid fa-plus" name="Adicionar"/>
                </row-col-component>
            </row-component>
        </div>
    </div>

    <vue-easy-lightbox :visible="visibleRef" :imgs="formData.photosModal" :index="indexRef" @hide="onHide"/>

    <painel-component>
        <error-component :errors="errors" v-if="showError"/>
        <div class="tabs effect-3" v-if="viewPage">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="fa-solid fa-gem"></i>
                <p>Banho</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="fa-solid fa-comment"></i>
                <p>Observações</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="d-flex">
                                    <span class="badge text-bg-primary me-2">
                                        Status: {{ statusString(formData.status) }}
                                    </span>
                                    <span class="badge text-bg-success me-2">
                                        Total Peso OS: {{ formData.pesoTotal }}
                                    </span>
                                </div>
                            </row-col-component>

                            <row-col-component
                                    class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="btn-group float-end" role="group">
                                    <button-submit @click="submitStatus(5)" tooltip="Enviar para amarração" icon="fa-solid fa-paper-plane"/>
                                    <button-submit @click="this.$router.push({name: 'separation-search'})" tooltip="Voltar" icon="fa-solid fa-rotate-left"/>
                                </div>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                                <label>OS:</label>
                                <input-component v-model="formData.os" disabled :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Data:</label>
                                <input-component v-model="formData.date_hour_create" disabled :type="'text'"/>

                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>Cliente:</label>
                                <input-component v-model="formData.client_name" disabled :type="'text'"/>
                            </row-col-component>
                        </row-component>
                    </form>

                    <div class="p-3 mb-5">
                        <div class="clearfix">
                            <button-submit @click="printOS(formData.urlPrintOSBath)" class="float-end"
                                           icon="fa-solid fa-print"/>
                        </div>

                        <div class="alert alert-secondary my-3" role="alert" v-if="formData.baths.length === 0">
                            Nenhum banho cadastrado
                        </div>

                        <div class="card bg-light-subtle mt-3" v-else
                             v-for="(data, index) in formData.baths" :key="index" :id="data.id">
                            <div class="card-count">{{ index + 1 }}</div>

                            <div class="py-3 px-0 p-lg-3">
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col" style="width: 1%">Imagem</th>
                                        <th scope="col" style="width: 20%">Grupo de Banho <br> Origem</th>
                                        <th scope="col" style="width: 20%">Código</th>
                                        <th scope="col" style="width: 20%">Quant. Bruto <br> Quantidade</th>
                                        <th scope="col" style="width: 20%">Peso UN <br> Total Peso</th>
                                    </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                    <tr>
                                        <td data-label="Imagem">
                                            <div class="position-relative">
                                                <div class="btn-avatar-view">
                                                    <button-submit @click="showImg(index)" icon="fa-solid fa-camera-retro"/>
                                                </div>
                                                <img @click="showImg(index)" class="avatar-form" v-if="data.photo" :src="data.photo" alt="">
                                                <img @click="showImg(index)" class="avatar-form" v-else src="@/assets/avatar.png" alt="">
                                                <label @click="getIdBath(data.id)" :for="'bath' + index" class="position-absolute camera m-2">
                                                    <i role="button" class="fa-solid fa-camera"></i>
                                                    <input @change="onFileChange" class="d-none" type="file" :id="'bath' + index">
                                                </label>
                                            </div>
                                        </td>
                                        <td data-label="Grupo de Banho / Origem">
                                            {{ data.name_group }} <br> {{ data.origem }}
                                        </td>
                                        <td data-label="Código">
                                            {{ data.code }}
                                        </td>
                                        <td data-label="Quant. Bruto / Quantidade">
                                            {{ data.gross_amount }} <br> {{ data.the_amount }}
                                        </td>
                                        <td data-label="Peso UN / Total Peso">
                                            {{ data.kilo }} <br> {{ data.kilo_total }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div v-if="data.services.length > 0" class="accordion p-3" :id="'accordionExample' + index">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" :data-bs-target="'#collapseTwo' + index"
                                                aria-expanded="false" aria-controls="collapseTwo">
                                        </button>
                                    </h2>
                                    <div :id="'collapseTwo' + index" class="accordion-collapse collapse"
                                         :data-bs-parent="'#accordionExample' + index">
                                        <div class="accordion-body">
                                            <div v-for="(dataTwo, indexTwo) in data.services" :key="indexTwo"
                                                 class="bg-body-secondary mb-3 p-3">
                                                <div class="table">
                                                    <strong>Serviço: {{ dataTwo.name_service }}</strong>
                                                </div>
                                                <hr>
                                                <table class="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 15%">Base</th>
                                                        <th scope="col" style="width: 15%">Cor</th>
                                                        <th scope="col" style="width: 15%">Verniz</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody class="table-group-divider">
                                                    <tr>
                                                        <td data-label="Base">{{ dataTwo.name_base }}</td>
                                                        <td data-label="Cor">{{ dataTwo.name_cor }}</td>
                                                        <td data-label="Verniz">{{ dataTwo.name_verniz }}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>

                                                <table class="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 25%">Quantidade</th>
                                                        <th scope="col" style="width: 25%">Peso</th>
                                                        <th scope="col" style="width: 25%">Total Peso</th>
                                                        <th scope="col" style="width: 25%">Milésimos</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody class="table-group-divider">
                                                    <tr>
                                                        <td data-label="Quantidade">{{ dataTwo.the_amount }}</td>
                                                        <td data-label="Peso">{{ dataTwo.kilo }}</td>
                                                        <td data-label="Total Peso">{{ dataTwo.kilo_total }}</td>
                                                        <td data-label="Milésimos">{{ dataTwo.layer }}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="tab-item-2">
                    <div class="p-3 mb-5">
                        <label>Observações Internas:</label>
                        <div class="clearfix">
                            <button-submit @click="addObs" class="float-end" icon="fa-solid fa-plus"
                                           name="Adicionar"/>
                        </div>

                        <div v-if="formData.obsInternal.length === 0" class="alert alert-secondary my-3" role="alert">
                            Nenhuma observação interna cadastrada
                        </div>

                        <div v-else style="height: 450px" class="overflow-y-scroll bg-body-secondary p-3 my-3">
                            <div class="bg-white p-3 my-3" v-for="(data, index) in formData.obsInternal" :key="index">
                                <row-component>
                                    <row-col-component
                                            class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="mb-3">
                                            <img v-if="data.photo" width="35" class="img-fluid rounded-circle"
                                                 :src="data.photo" alt="">

                                            <img v-else width="35" class="img-fluid rounded-circle"
                                                 src="@/assets/avatar.png" alt="">
                                            {{ data.creator_user_name }} - {{ data.date_create }} {{ data.hour_create }}
                                        </div>
                                        <p>{{ data.obs }}</p>
                                    </row-col-component>
                                </row-component>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import InputComponent from "@/components/forms/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import TextArea from "@/components/forms/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import VueEasyLightbox from "vue-easy-lightbox";
import {useToast} from "vue-toastification";

const toast = useToast()
import {ref} from "vue";

export default {
    name: 'SeparationShow',
    components: {
        VueEasyLightbox,
        ErrorComponent,
        ButtonSubmit,
        TextArea,
        RowColComponent,
        RowComponent,
        InputComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'OS Visualizar (Separação)')
        this.checkACL()
        this.loadItems()
    },

    setup() {
        const visibleRef = ref(false)
        const indexRef = ref(0)
        const imgs = []
        const showImg = (index) => {
            indexRef.value = index
            visibleRef.value = true
        }
        const onHide = () => visibleRef.value = false
        return {
            visibleRef,
            indexRef,
            imgs,
            showImg,
            onHide
        }
    },

    data() {
        return {
            formData: {
                id: this.id,
                os: '',
                client_id: '',
                payment_date: '',
                table: '',
                status: '',
                code_url: '',
                created_at: '',
                updated_at: '',
                historics: [],
                obsInternal: [],
                urlPrivate: '',
                pesoTotal: '',
                tableOs: '',
                urlPrintOSBath: '',
                baths: [],
                date_hour_create: '',
                client_name: '',
                photosModal: []
            },

            copy: false,

            add_obs: {
                id: this.id,
                obs: ''
            },

            bath_photo: {
                id: '',
                photo: []
            },

            obsInt: false,
            obs_modal: false,

            errors: [],
            viewPage: false,
            sendForm: false,
            showError: false
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadSeparation', this.id)
                .then((r) => {
                    this.formData = r
                    this.$store.dispatch("loadClientListEmail", r.client_id)
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 20)
                .then((r) => {
                    if (r.data.indexOf('os-separation') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        printOS(link) {
            window.open(link, '_blank')
        },

        copyLink(link) {
            this.$refs.select.focus
            navigator.clipboard.writeText(link)
            this.copy = true
            setTimeout(() => {
                this.copy = false
            }, 1500)
        },

        statusString(value) {
            switch (parseInt(value)) {
                case 1:
                    return 'Cadastrado';
                case 2:
                    return 'Enviado Para Cliente';
                case 3:
                    return 'Aguardando Aprovação Iwassa';
                case 4:
                    return 'Separação';
                case 5:
                    return 'Amarração';
                case 6:
                    return 'Banho';
                case 7:
                    return 'Inspeção';
                case 8:
                    return 'Envio';
                case 9:
                    return 'Fechamento';
                case 10:
                    return 'Finalizado';
                case 11:
                    return 'Aprovado pelo Cliente';
                default:
                    break;
            }
        },

        sectorModal() {
            this.sector_modal = !this.sector_modal
        },

        submitStatus(status) {
            let params = {id: this.id, status: status}
            this.$store.dispatch('updateOrderServiceSeparationStatus', params)
                .then(() => {
                    this.showError = false
                    this.errors = []
                    toast.success('Status atualizado com sucesso', {timeout: 1500})
                    this.$router.push({name: "separation-search"})
                }).catch((e) => {
                this.showError = true
                this.errors = e.response.data.errors
            })
        },

        submitObsInt() {
            this.$store.dispatch('storeOrderServiceSeparationAddObsInternal', this.add_obs)
                .then(() => {
                    toast.success('Observação interna adicionada com sucesso', {timeout: 1500})
                    this.loadItems()
                    this.clsObs()
                    this.add_obs.obs = ''
                }).catch(() => {
                toast.error('Erro ao adicionar observação interna', {timeout: 1500})
                this.loadItems()
                this.clsObs()
                this.add_obs.obs = ''
            })
        },

        getIdBath(id) {
            this.bath_photo.id = id
        },

        onFileChange(event) {
            let files = event.target.files || event.dataTransfer.files
            if (!files.length)
                return
            this.bath_photo.photo = files[0]
            this.submitPhotoBath()
        },

        submitPhotoBath() {
            this.$store.dispatch('updateOrderServiceBathPhoto', this.bath_photo)
                .then(() => {
                    this.loadItems()
                    toast.success("Foto atualizada com sucesso", {timeout: 2000});
                }).catch(() => {
                this.loadItems()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        addObs() {
            this.obs_modal = true
            this.obsInt = true
        },

        clsObs() {
            this.obs_modal = false
            this.obsInt = false
        },
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },
    }
}
</script>

<style scoped>

.tabs {
    z-index: 0;
}

.tabs > input,
.tabs > span,
.effect-3 .line {
    width: calc(100% / 2);
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.modal-window {
    z-index: 10;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
}

.add-obs,
.send-sector {
    width: 50%;
    padding: 2%;
    position: relative;
    background-color: #FFFFFF;
    box-shadow: 5px 10px 12px 0 #A6A6A6;
    -webkit-box-shadow: 5px 10px 12px 0 #A6A6A6;
}

.add-obs .btn-close,
.send-sector .btn-close {
    top: 3%;
    right: 1%;
    position: absolute;
}

.avatar-form::before,
.avatar-form::after {
    display: none;
}

.avatar-form {
    height: 150px;
    border-radius: 0;
}

.camera {
    right: 0;
    bottom: -5px;
}

.table {
    --bs-table-bg: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;;
}

.card-count {
    width: 80px;
    height: 45px;
    margin: 1rem;
    color: #FFFFFF;
    padding: 5px 10px;
    font-weight: bold;
    background-color: #9FA692;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    background-color: #f0f0f0;
}

.btn-avatar-view {
    opacity: 0;
    width: 100px;
    z-index: 100;
    height: 150px;
    display: grid;
    position: absolute;
    place-items: center;
    transition: all 0.2s ease-in-out;
    background-color: rgba(0, 0, 0, 0.3);
}

.btn-avatar-view:hover {
    opacity: 1;
}

@media (max-width: 768px) {
    .add-obs,
    .send-sector {
        width: 70%;
    }
}

@media (max-width: 428px) {
    .avatar-form {
        height: 150px;
        margin-top: 25px;
        margin-bottom: 0;
    }

    .camera {
        left: 70px;
        right: unset;
    }

    .avatar-form img {
        height: 150px;
        display: block;
    }

    .add-obs,
    .send-sector {
        width: 95%;
    }
}
</style>