<template>
    <router-link :tooltip="items.name" role="button" :to="items.route" class="btn btn-secondary">
        <i :class="items.icon"></i>
        {{ items.name }}
    </router-link>
</template>

<script>
export default {
    name: 'ButtonRoute',
    props: {
        items: {
            type: Object,
        }
    }
}
</script>