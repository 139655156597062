<template>
    <div class="modal-window top-50 start-50 translate-middle"
         v-if="obs_modal || pay_modal || sector_modal || close_modal || additional_services_modal">

        <div class="edit-pay" v-if="pay_modal">
            <OSUpdateDiscountPayment v-if="pay_modal" :id="this.id" :items="pay_items" @close="closePay"/>
        </div>

        <div class="add-obs" v-if="obs_modal">
            <div @click="clsObs" class="btn-close"></div>

            <form>
                <row-component>
                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <label>Observações Internas:</label>
                        <text-area v-model="add_obs.obs" rows="5"/>
                    </row-col-component>
                </row-component>
            </form>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <button-submit @submit="submitObs" icon="fa-solid fa-plus" name="Adicionar"/>
                </row-col-component>
            </row-component>
        </div>

        <div class="send-sector" v-if="sector_modal">
            <div role="button" class="btn-close" @click="sectorModal"></div>

            <div class="mt-3">
                <row-component>
                    <template v-for="(data, index) in sectors" :key="index">
                        <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                           v-if="parseInt(formData.status) !== data.id">
                            <div class="d-flex align-items-center bg-body-secondary p-3 position-relative">
                                <div class="ms-3 w-75">
                                    <p class="mb-1 text-truncate">{{ data.name }}</p>
                                </div>
                                <div class="position-absolute end-0 me-1 me-lg-3">
                                    <button-submit @click="submitStatus(data.id)" icon="fa-solid fa-paper-plane"/>
                                </div>
                            </div>
                        </row-col-component>
                    </template>
                </row-component>
            </div>
        </div>

        <div class="close-bath" v-if="close_modal">
            <div role="button" class="btn-close" @click="close_modal = false"></div>

            <div class="mt-3">
                <row-component>
                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <label>Com Defeito:</label>
                        <input-component v-model="close_data.the_amount_broked" :type="'number'"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <label>Faltando Pedras:</label>
                        <input-component v-model="close_data.the_amount_missing_stones" :type="'number'"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <label>Perda:</label>
                        <input-component v-model="close_data.miss" :type="'number'"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <label>Peso Final:</label>
                        <div class="position-relative">
                            <p class="position-absolute top-50 start-0 translate-middle-y"
                               style="margin-left: 10px">
                                {{ close_data.kilo_of_bath }}
                            </p>
                            <input-component v-model="number" @keyup="funcOne" :type="'text'"
                                             style="color: transparent"/>
                        </div>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <button-submit @click="submitClose" class="w-100" name="Salvar" icon="fa-solid fa-floppy-disk"/>
                    </row-col-component>
                </row-component>
            </div>
        </div>

        <div class="additional-services" v-if="additional_services_modal">
            <div class="btn-close" @click="closeAddService"></div>
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Serviço:</label>
                    <Multiselect v-model="add_services.service_id" :options="multiselectServiceTwo"
                                 @select="loadPriceService(add_services.service_id)"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Quantidade:</label>
                    <input-component @keyup="sumAddService" v-model="add_services.the_amounnt"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Preço:</label>
                    <input-component v-money="money" @keyup="sumAddService" v-model="add_services.price"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Preço Total:</label>
                    <input-component v-model="add_services.price_total" disabled/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <button-submit v-if="store_service" @submit="submitAdditionalService" icon="fa-solid fa-plus"
                                   name="Adicionar"/>
                    <button-submit v-else @submit="submitAdditionalServiceEdit" icon="fa-solid fa-floppy-disk"
                                   name="Salvar"/>
                </row-col-component>
            </row-component>
        </div>
    </div>

    <vue-easy-lightbox :visible="visibleRef" :imgs="formData.photosModal" :index="indexRef" @hide="onHide"/>

    <painel-component>
        <error-component :errors="errors" v-if="showError"/>

        <div class="tabs effect-3" v-if="viewPage">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="fa-solid fa-gem"></i>
                <p>Banho</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="fa-solid fa-truck-fast"></i>
                <p>Envio</p>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3">
            <span>
                <i class="fa-solid fa-comment"></i>
                <p>Observações</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="d-flex">
                                    <span class="badge text-bg-primary me-2">
                                        Status: {{ statusString(formData.status) }}
                                    </span>
                                    <span class="badge text-bg-success me-2">
                                        Total Peso OS: {{ formData.pesoTotal }}
                                    </span>
                                </div>
                            </row-col-component>

                            <row-col-component
                                    class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="btn-group float-end" role="group">
                                    <button-submit @click="sectorModal" tooltip="Enviar para setor"
                                                   icon="fa-solid fa-paper-plane"/>
                                    <button-submit @click="routeSearch" tooltip="Voltar"
                                                   icon="fa-solid fa-rotate-left"/>
                                </div>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                                <label>OS:</label>
                                <input-component v-model="formData.os" disabled :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Data:</label>
                                <input-component v-model="formData.date_hour_create" disabled :type="'text'"/>

                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>Cliente:</label>
                                <input-component v-model="formData.client_name" disabled :type="'text'"/>
                            </row-col-component>
                        </row-component>
                    </form>

                    <div class="p-3 mb-5">
                        <div class="alert alert-secondary my-3" role="alert" v-if="formData.baths.length === 0">
                            Nenhum banho cadastrado
                        </div>

                        <div class="card bg-light-subtle mt-3" v-else
                             v-for="(data, index) in formData.baths" :key="index" :id="data.id">
                            <div class="card-count">{{ index + 1 }}</div>

                            <div class="py-3 px-0 p-lg-3">
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col" style="width: 10%">Imagem</th>
                                        <th scope="col" style="width: 15%">Grupo de Banho <br> Origem</th>
                                        <th scope="col" style="width: 15%">Código <br> Preço Bruto UN</th>
                                        <th scope="col" style="width: 15%">Quant. Bruto <br> Quantidade</th>
                                        <th scope="col" style="width: 15%">Peso UN <br> Total Peso</th>
                                    </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                    <tr>
                                        <td data-label="Imagem">
                                            <div class="w-auto position-relative">
                                                <div class="btn-avatar-view">
                                                    <button-submit @click="showImg(index)" icon="fa-solid fa-camera-retro"/>
                                                </div>
                                                <img @click="showImg(index)" class="avatar-form" v-if="data.photo" :src="data.photo" alt="">
                                                <img @click="showImg(index)" class="avatar-form" v-else src="@/assets/avatar.png" alt="">
                                            </div>
                                        </td>
                                        <td data-label="Grupo de Banho / Origem">
                                            {{ data.name_group }} <br> {{ data.origem }}
                                        </td>
                                        <td data-label="Código / Preço Bruto UN">
                                            {{ data.code }} <br> {{ data.price_bruto }}
                                        </td>
                                        <td data-label="Quant. Bruto / Quantidade">
                                            {{ data.gross_amount }} <br> {{ data.the_amount }}
                                        </td>
                                        <td data-label="Peso UN / Total Peso">
                                            {{ data.kilo }} <br> {{ data.kilo_total }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="accordion p-3" :id="'accordionExample' + index" v-if="data.services.length > 0">
                                <div class="accordion-item">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" :data-bs-target="'#collapseTwo' + index"
                                                aria-expanded="false" aria-controls="collapseTwo">
                                        </button>
                                    </h2>
                                    <div :id="'collapseTwo' + index" class="accordion-collapse collapse"
                                         :data-bs-parent="'#accordionExample' + index">
                                        <div class="accordion-body">
                                            <div v-for="(dataTwo, indexTwo) in data.services" :key="indexTwo" class="bg-body-secondary mb-3 p-3">
                                                <div class="table">
                                                    <strong>Serviço: {{ dataTwo.name_service }}</strong>
                                                </div>
                                                <hr>
                                                <table class="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 15%">Base</th>
                                                        <th scope="col" style="width: 15%">Preço</th>
                                                        <th scope="col" style="width: 15%">Cor</th>
                                                        <th scope="col" style="width: 15%">Preço</th>
                                                        <th scope="col" style="width: 15%">Verniz</th>
                                                        <th scope="col" style="width: 10%">Preço</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody class="table-group-divider">
                                                    <tr>
                                                        <td data-label="Base">{{ dataTwo.name_base }}</td>
                                                        <td data-label="Preço">R$ {{ dataTwo.price_base }}</td>
                                                        <td data-label="Cor">{{ dataTwo.name_cor }}</td>
                                                        <td data-label="Preço">R$ {{ dataTwo.price_cor }}</td>
                                                        <td data-label="Verniz">{{ dataTwo.name_verniz }}</td>
                                                        <td data-label="Preço">R$ {{ dataTwo.price_verniz }}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>

                                                <table class="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 20%">Quantidade</th>
                                                        <th scope="col" style="width: 20%">Peso</th>
                                                        <th scope="col" style="width: 20%">Total Peso</th>
                                                        <th scope="col" style="width: 20%">Milésimos</th>
                                                        <th scope="col" style="width: 20%">M.O.</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody class="table-group-divider">
                                                    <tr>
                                                        <td data-label="Quantidade">{{ dataTwo.the_amount }}</td>
                                                        <td data-label="Peso">{{ dataTwo.kilo }}</td>
                                                        <td data-label="Total Peso">{{ dataTwo.kilo_total }}</td>
                                                        <td data-label="Milésimos">{{ dataTwo.layer }}</td>
                                                        <td data-label="M.O.">{{ dataTwo.thousandth }}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>

                                                <table class="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 20%">Preço do Serviço</th>
                                                        <th scope="col" style="width: 20%">Custo do Banho por KG</th>
                                                        <th scope="col" style="width: 30%">
                                                            Custo do Serviço (MO + ML)
                                                        </th>
                                                        <th scope="col" style="width: 10%">Subtotal</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody class="table-group-divider">
                                                    <tr>
                                                        <td data-label="Preço do Serviço">{{ dataTwo.price_kilo }}</td>
                                                        <td data-label="Custo do Banho por KG">
                                                            {{ dataTwo.price_bath_kg }}
                                                        </td>
                                                        <td data-label="Custo do Serviço (MO + ML)">
                                                            {{ dataTwo.price_ml_mo }}
                                                        </td>
                                                        <td data-label="Subtotal">{{ dataTwo.price_subtotal }}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="tab-item-2">
                    <div class="clearfix my-3">
                        <button-submit v-if="formData.send.length === 0" @click="routeAddShipping" class="float-end"
                                       icon="fa-solid fa-plus" name="Adicionar"/>

                        <button-submit v-else @click="routeEditShipping" class="float-end"
                                       icon="fa-solid fa-pen-to-square" name="Editar"/>
                    </div>

                    <div class="alert alert-secondary my-3" role="alert" v-if="formData.send.length === 0">
                        Nenhum envio cadastrado
                    </div>

                    <table class="table table-striped" v-for="(data, index) in send" :key="index">
                        <thead>
                        <tr>
                            <th colspan="2" scope="col" style="width: 20%">Tipo de Envio</th>
                            <th colspan="2" scope="col" style="width: 20%">Envio</th>
                            <th colspan="2" scope="col" style="width: 20%">Preço</th>
                            <th colspan="2" scope="col" style="width: 20%">Peso</th>
                        </tr>
                        </thead>
                        <tbody class="table-group-divider">
                        <tr>
                            <td colspan="2" data-label="Tipo de Envio">{{ deliveryTypeString(data.type) }}</td>
                            <td colspan="2" data-label="Envio">{{ deliveryString(data.send) }}</td>
                            <td colspan="2" data-label="Preço">R$ {{ data.price }}</td>
                            <td colspan="2" data-label="Peso">{{ data.kilo }}</td>
                        </tr>
                        </tbody>
                        <tfoot v-if="formData.send.address" style="background-color: rgba(0, 0, 0, 0.05);">
                        <tr>
                            <th scope="col" style="width: 10%">{{ data.cep }}</th>
                            <th scope="col" style="width: 10%">{{ data.city }} - {{ data.state }}</th>
                            <th scope="col" style="width: 20%">{{ data.district }}</th>
                            <th scope="col" colspan="2" style="width: 20%">{{ data.address }}</th>
                            <th scope="col" style="width: 10%">{{ data.number }}</th>
                            <th scope="col" style="width: 10%">{{ data.country }}</th>
                        </tr>
                        </tfoot>
                    </table>
                </section>

                <section id="tab-item-3">
                    <div class="p-3 mb-5">
                        <label>Observações Internas:</label>
                        <div class="clearfix">
                            <button-submit @click="addObs(2)" class="float-end" icon="fa-solid fa-plus"
                                           name="Adicionar"/>
                        </div>

                        <div v-if="formData.obsInternal.length === 0" class="alert alert-secondary my-3" role="alert">
                            Nenhuma observação interna cadastrada
                        </div>

                        <div v-else style="height: 450px" class="overflow-y-scroll bg-body-secondary p-3 my-3">
                            <div class="bg-white p-3 my-3" v-for="(data, index) in formData.obsInternal" :key="index">
                                <row-component>
                                    <row-col-component
                                            class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="mb-3">
                                            <img v-if="data.photo" width="35" class="img-fluid rounded-circle"
                                                 :src="data.photo" alt="">

                                            <img v-else width="35" class="img-fluid rounded-circle"
                                                 src="@/assets/avatar.png" alt="">
                                            {{ data.creator_user_name }} - {{ data.date_create }} {{ data.hour_create }}
                                        </div>
                                        <p>{{ data.obs }}</p>
                                    </row-col-component>
                                </row-component>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import InputComponent from "@/components/forms/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/forms/TextArea";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ErrorComponent from "@/components/alerts/ErrorComponent";

import OSUpdateDiscountPayment from "@/views/update/OSUpdateDiscountPayment";
import swal from "sweetalert";
import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";
import VueEasyLightbox from "vue-easy-lightbox";
import {ref} from "vue";

const toast = useToast()
export default {
    name: 'ShippingShow',
    components: {
        VueEasyLightbox,
        OSUpdateDiscountPayment,
        ErrorComponent,
        ButtonSubmit,
        TextArea,
        Multiselect,
        RowColComponent,
        RowComponent,
        InputComponent,
        PainelComponent
    },

    directives: {
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'OS Visualizar (Envio)')
        this.checkACL()
        this.loadItems()
    },

    setup() {
        const visibleRef = ref(false)
        const indexRef = ref(0)
        const imgs = []
        const showImg = (index) => {
            indexRef.value = index
            visibleRef.value = true
        }
        const onHide = () => visibleRef.value = false
        return {
            visibleRef,
            indexRef,
            imgs,
            showImg,
            onHide
        }
    },

    data() {
        return {
            formData: {
                os: '',
                client_id: '',
                payment_date: '',
                table: '',
                status: '',
                code_url: '',
                historics: [],
                baths: [],
                obsOs: [],
                bathsClose: [],
                obsInternal: [],
                paymentsForm: [],
                send: [],
                serviceAdds: [],
                paymentsDiscount: [],
                urlPrivate: '',
                pesoTotal: '',
                tableOs: '',
                urlPrintOS: '',
                client_name: '',
                date_hour_create: '',
                urlPrintOSBath: '',
                urlPrintOSCataloging: '',
                urlPrintOSEditableCataloging: '',
            },

            send: [],

            money: MONEY,

            add_obs: {
                id: this.id,
                obs: ''
            },

            obs: false,
            obsInt: false,
            obs_modal: false,

            bath_photo: {
                id: '',
                photo: []
            },

            bath_items: [],
            bath_modal: false,

            pay_items: [],
            pay_modal: false,

            copy: false,

            sector_modal: false,
            sectors: [
                {name: "Enviar para: cadastrado", id: 1},
                {name: "Enviar para: cliente", id: 2},
                {name: "Enviar para: separação", id: 4},
                {name: "Enviar para: amarração", id: 5},
                {name: "Enviar para: banho", id: 6},
                {name: "Enviar para: inspeção", id: 7},
                {name: "Enviar para: o envio", id: 8},
                {name: "Enviar para: fechamento", id: 9}
            ],

            close_modal: false,
            number: "",
            close_data: {
                id: "",
                the_amount_broked: "",
                the_amount_missing_stones: "",
                kilo_of_bath: "",
                miss: ""
            },

            additional_services_modal: false,
            store_service: true,
            add_services: {
                id: this.id,
                service_id: "",
                the_amounnt: "",
                price: "",
                price_total: ""
            },

            viewPage: false,

            errors: [],
            sendForm: false,
            showError: false
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadShipping', this.id)
                .then((r) => {
                    this.formData = r
                    this.loadSend(r.send)
                    this.$store.dispatch("loadClientListEmail", r.client_id)
                    this.$store.dispatch('loadMultiSelectClassification')
                    this.$store.dispatch('loadMultiSelectServiceTwo')
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        loadSend(data) {
            if (data.address)
                return this.send.push({...data.send, ...data.address[0]})
            return this.send.push(data)
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 20)
                .then((r) => {
                    if (r.data.indexOf('os-close') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        sectorModal() {
            this.sector_modal = !this.sector_modal
        },

        submitStatus(status) {
            let params = {id: this.id, status: status}
            this.$store.dispatch('updateOrderServiceSendStatus', params)
                .then(() => {
                    this.showError = false
                    this.errors = []
                    this.sector_modal = false
                    this.$router.push({name: "shipping-search"})
                    toast.success('Status atualizado com sucesso', {timeout: 1500})
                }).catch((e) => {
                this.showError = true
                this.errors = e.response.data.errors
            })
        },

        loadBathClose(id) {
            this.$store.dispatch("loadOrderServiceCloseGetBath", id)
                .then((r) => {
                    this.close_data = r
                    this.close_modal = true
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        submitClose() {
            this.$store.dispatch('updateOrderServiceCloseUpdateBath', this.close_data)
                .then(() => {
                    this.showError = false
                    this.errors = []
                    this.loadItems()
                    this.close_modal = false
                    toast.success('Atualizado com sucesso', {timeout: 1500})
                }).catch((e) => {
                this.showError = true
                this.errors = e.response.data.errors
            })
        },

        funcOne(event) {
            let value = event.target.value
            let valor = parseInt(0 + value);
            let result = this.pad(valor, 7);
            this.close_data.kilo_of_bath = this.kilo(result)
        },

        pad(str, max) {
            str = str.toString();
            str = str.length < max ? this.pad("0" + str, max) : str;
            str = str.length > max ? str.substring(0, max) : str;
            return str;
        },

        kilo(v) {
            v = v.replace(/(\d{2})(\d{1,2})$/, "$1.$2")
            v = v.replace(/(\d{2})(\d)/, "$1,$2")
            return v
        },

        statusString(value) {
            switch (parseInt(value)) {
                case 1:
                    return 'Cadastrado';
                case 2:
                    return 'Enviado Para Cliente';
                case 3:
                    return 'Aguardando Aprovação Iwassa';
                case 4:
                    return 'Separação';
                case 5:
                    return 'Amarração';
                case 6:
                    return 'Banho';
                case 7:
                    return 'Inspeção';
                case 8:
                    return 'Envio';
                case 9:
                    return 'Fechamento';
                case 10:
                    return 'Finalizado';
                case 11:
                    return 'Aprovado pelo Cliente';
                default:
                    break;
            }
        },

        deliveryTypeString(value) {
            switch (parseInt(value)) {
                case 1:
                    return 'Retirar no local';
                case 2:
                    return 'Endereço de cadastro';
                case 3:
                    return 'Novo Endereço';
                default:
                    break;
            }
        },

        deliveryString(value) {
            switch (parseInt(value)) {
                case 1:
                    return 'Retirar no local';
                case 2:
                    return 'Moto Boy';
                case 3:
                    return 'Sedex';
                case 4:
                    return 'PAC';
                default:
                    break;
            }
        },

        routeSearch() {
            this.$router.push({name: 'shipping-search'})
        },

        routeAddShipping() {
            this.$router.push({name: 'shipping-create', params: {id: this.id}})
        },

        routeEditShipping() {
            this.$router.push({name: 'shipping-edit', params: {id: this.id}})
        },

        submitObs() {
            this.$store.dispatch('storeOrderServiceSendAddObsInternal', this.add_obs)
                .then(() => {
                    toast.success('Observação adicionada com sucesso', {timeout: 1500})
                    this.loadItems()
                    this.clsObs()
                    this.add_obs.obs = ''
                }).catch(() => {
                toast.error('Erro ao adicionar observação', {timeout: 1500})
                this.loadItems()
                this.clsObs()
                this.add_obs.obs = ''
            })
        },

        destroyDiscountPayment(item) {
            let params = {id: this.id, id_pay: item.id}

            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar este item, ${this.me.name} ? `,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyOrderServiceDiscountPayment', params).then(() => {
                        this.loadItems()
                        toast.success('Cadastro deletado com sucesso', {timeout: 1500});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1500});
                    })
                }
            });
        },

        addObs(type) {
            this.obs_modal = true

            if (type === 1)
                this.obs = true

            if (type === 2)
                this.obsInt = true
        },

        clsObs() {
            this.obs_modal = false
            this.obs = false
            this.obsInt = false
        },

        editPay(items) {
            this.pay_items = items
            this.pay_modal = true
        },

        closePay() {
            this.pay_modal = false
            this.pay_items = []
            this.loadItems()
        },

        closeAddService() {
            this.add_services.id = this.id
            this.add_services.price = ""
            this.add_services.price_total = ""
            this.add_services.the_amounnt = ""
            this.add_services.service_id = ""
            this.additional_services_modal = false
            this.store_service = true
        },

        editAdditionalService(data) {
            this.add_services.id = data.id
            this.add_services.price = data.price
            this.add_services.price_total = data.price_total
            this.add_services.the_amounnt = data.the_amounnt
            this.add_services.service_id = data.service_id
            this.additional_services_modal = true
            this.store_service = false
        },

        loadPriceService(id) {
            this.$store.dispatch("loadGetServiceAddPrice", id)
                .then((r) => {
                    this.add_services.price = r
                })
        },

        sumAddService() {
            let price = this.add_services.price
            let amount = this.add_services.the_amounnt

            price = price.replace(/\./g, '').replace(',', '.')

            return this.add_services.price_total = (price * amount).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },

        submitAdditionalService() {
            this.$store.dispatch('storeOrderServiceAdditionalClose', this.add_services)
                .then(() => {
                    toast.success('Serviço adicional cadastrado com sucesso', {timeout: 1500})
                    this.loadItems()
                    this.additional_services_modal = false
                }).catch(() => {
                toast.error('Erro ao adicionar cadastro', {timeout: 1500})
                this.loadItems()
                this.additional_services_modal = false
            })
        },

        submitAdditionalServiceEdit() {
            this.$store.dispatch('updateOrderServiceAdditionalClose', this.add_services)
                .then(() => {
                    toast.success('Serviço adicional atualizado com sucesso', {timeout: 1500})
                    this.loadItems()
                    this.additional_services_modal = false
                }).catch(() => {
                toast.error('Erro ao atualizar cadastro', {timeout: 1500})
                this.loadItems()
                this.additional_services_modal = false
            })
        },

        destroyAdditionalService(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar este item, ${this.me.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyOrderServiceAdditionalClose', item.id).then(() => {
                        this.loadItems()
                        toast.success('Cadastro deletado com sucesso', {timeout: 1500});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1500});
                    })
                }
            });
        },

        sumSub(a, b) {
            a = parseFloat(a.replace(/\./g, '').replace(',', '.'))
            b = parseFloat(b.replace(/\./g, '').replace(',', '.'))
            let result = a - b

            return Math.abs(result).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },

        sumPayments(params) {
            let values = params
            let sum = 0
            for (let i = 0; i < values.length; i++) {
                let price = parseFloat(values[i].price.replace(/\./g, '').replace(',', '.'))
                sum += price
            }

            return sum.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },

        sumOs(params) {
            let service = []
            let sum = 0

            params.forEach((element) => {
                if (element.services.length > 0)
                    service = element.services
            })

            for (let i = 0; i < service.length; i++) {
                if (service.length <= 1) {
                    sum = service[i].price_subtotal
                } else {
                    sum += parseFloat(service[i].price_subtotal.replace(/\./g, '').replace(',', '.'))
                }
            }

            return sum.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        multiselectServiceTwo() {
            return this.$store.state.var.multiselectServiceTwo
        },
    }
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: calc(100% / 3);
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 33.3%;
}

#tab-3,
#tab-3 + span,
#tab-3:checked ~ .line {
    left: 66.6%;
}

.modal-window {
    z-index: 10;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
}

.add-obs,
.send-sector,
.close-bath,
.additional-services {
    width: 50%;
    padding: 2%;
    position: relative;
    background-color: #FFFFFF;
    box-shadow: 5px 10px 12px 0 #A6A6A6;
    -webkit-box-shadow: 5px 10px 12px 0 #A6A6A6;
}

.close-bath {
    width: 30%;
}

.edit-pay {
    width: 50%;
    height: auto;
}

.add-obs .btn-close,
.send-sector .btn-close,
.close-bath .btn-close,
.additional-services .btn-close {
    top: 3%;
    right: 1%;
    position: absolute;
}

.avatar-form::before,
.avatar-form::after {
    display: none;
}

.avatar-form {
    height: 150px;
    border-radius: 0;
}

.table {
    --bs-table-bg: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;;
}

.card-count {
    width: 80px;
    height: 45px;
    margin: 1rem;
    color: #FFFFFF;
    padding: 5px 10px;
    font-weight: bold;
    background-color: #9FA692;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    background-color: #f0f0f0;
}

.btn-avatar-view {
    opacity: 0;
    width: 100px;
    z-index: 100;
    height: 150px;
    display: grid;
    position: absolute;
    place-items: center;
    transition: all 0.2s ease-in-out;
    background-color: rgba(0, 0, 0, 0.3);
}

.btn-avatar-view:hover {
    opacity: 1;
}

@media (max-width: 768px) {
    .add-obs,
    .edit-pay,
    .send-sector {
        width: 70%;
    }
}

@media (max-width: 428px) {
    .avatar-form {
        height: 150px;
        margin-top: 25px;
        margin-bottom: 0;
    }

    .avatar-form img {
        height: 150px;
        display: block;
    }

    .add-obs,
    .edit-pay,
    .send-sector {
        width: 95%;
    }

}
</style>