<template>
    <painel-component>
        <error-component :errors="errors" v-if="showError"/>

        <form>
            <div class="aux my-4">
                <div class="avatar-form rounded-0">
                    <img v-if="formData.photo" :src="formData.photo" class="avatar__image rounded-0" alt="">
                    <img v-else src="@/assets/avatar.png" class="avatar__image rounded-0" alt="">
                </div>
            </div>

            <row-component>
                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.code}">
                    <label>Código:</label>
                    <input-component v-model="formData.code" :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.the_amount}">
                    <label>Quantidade:</label>
                    <input-component v-model="formData.the_amount" :type="'number'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.kilo_original}">
                    <label>Peso Original:</label>
                    <div class="position-relative">
                        <p class="position-absolute top-50 start-0 translate-middle-y"
                           style="margin-left: 10px">
                            {{ formData.kilo_original }}
                        </p>
                        <input-component v-model="number" @keyup="funcOne" :type="'text'" style="color: transparent"/>
                    </div>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Peso (Peso total + {{formData.addition}}%):</label>
                    <div class="position-relative">
                        <p class="position-absolute top-50 start-0 translate-middle-y"
                           style="margin-left: 10px">
                            {{ formData.kilo }}
                        </p>
                        <input-component disabled v-model="number" @keyup="funcOne" :type="'text'" style="color: transparent"/>
                    </div>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.price_bruto}">
                    <label>Preço Bruto(UN):</label>
                    <input-component maxlength="12" v-money="money" v-model="formData.price_bruto" :type="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.bath_group_id}">
                    <label>Grupo Banho:</label>
                    <Multiselect v-model="formData.bath_group_id" :options="multiselectBathGroup"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.origem}">
                    <label>Origem:</label>
                    <input-component v-model="formData.origem" :type="'text'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.desc}">
                    <label>Descrição:</label>
                    <text-area v-model="formData.desc" rows="5"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" @submit="submit" class="w-100" name="Salvar"
                                   icon="fa-solid fa-floppy-disk"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import Multiselect from "@vueform/multiselect";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import InputComponent from "@/components/forms/InputComponent";
import TextArea from "@/components/forms/TextArea";
import {useToast} from "vue-toastification";
import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";

const toast = useToast()
export default {
    name: 'StockClientUpdateBath',
    components: {
        TextArea,
        InputComponent,
        ErrorComponent,
        ButtonSubmit,
        ButtonRoute,
        RowColComponent,
        RowComponent,
        Multiselect,
        PainelComponent
    },

    directives: {
        money: VMoney
    },

    props: {
        id: {
            required: true
        },

        bath: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Estoque Cliente Editar Banho')
        this.$store.dispatch('loadMultiSelectClient')
        this.$store.dispatch('loadMultiSelectBathGroup')
        this.loadItems()
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: `/stock-client/edit/${this.id}`,
                icon: 'fa-solid fa-rotate-left'
            },

            formData: {
                id: "",
                client_stock_id: this.id,
                bath_group_id: "",
                the_amount: "",
                kilo: "",
                addition:"",
                kilo_original: "",
                origem: "",
                code: "",
                desc: "",
                price_bruto: ""
            },

            money: MONEY,
            imagePreview: "",
            number: '',

            errors: [],
            sendForm: false,
            showError: false
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch("loadStockBath", this.bath)
                .then((r) => {
                    this.formData = r
                    this.calcKilo()
                }).catch(() => {
                toast.error("Erro desconhecido", {timeout: 1500})
            })
        },

        submit() {
            delete this.formData.photo
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateStockBath', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro atualizado com sucesso', {timeout: 1500})
                        this.$router.push({name: 'stock-client-edit', params: {id: this.id}})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        calcKilo() {
            if (this.formData.addition > 0) {
                let kilo = this.formData.kilo_original.replace(/[.,]/g, '')
                let value = (kilo * this.formData.addition) / 100
                let result = parseFloat(kilo) + value
                this.funcTwo(result)
            }else{
                this.formData.kilo = this.formData.kilo_original
            }
        },

        funcOne(event) {
            let value = event.target.value
            let valor = parseInt(0 + value);
            let result = this.pad(valor, 7);
            this.formData.kilo_original = this.kilo(result)
            this.calcKilo()
        },

        funcTwo(value) {
            let valor = parseInt(0 + value);
            let result = this.pad(valor, 7);
            this.formData.kilo = this.kilo(result)
        },

        pad(str, max) {
            str = str.toString();
            str = str.length < max ? this.pad("0" + str, max) : str; // zero à esquerda
            str = str.length > max ? str.substring(0, max) : str; // máximo de caracteres
            return str;
        },

        kilo(v) {
            v = v.replace(/(\d{2})(\d{1,2})$/, "$1.$2") //Coloca um hífen entre o terceiro e o quarto dígitos
            v = v.replace(/(\d{2})(\d)/, "$1,$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
            return v
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        multiselectClient() {
            return this.$store.state.var.multiselectClient
        },

        multiselectBathGroup() {
            return this.$store.state.var.multiselectBathGroup
        },
    }
}
</script>

<style scoped>
.avatar-form::before,
.avatar-form::after {
    display: none;
}

.avatar-form {
    width: 115px;
    height: 150px;
}

@media (max-width: 428px) {
    .avatar-form {
        width: 115px;
        height: 150px;
    }

    .avatar-form img {
        height: 150px;
        display: block;
    }
}
</style>