<template>
    <vue-easy-lightbox :visible="visibleRef" :imgs="formData.photosModal" :index="indexRef" @hide="onHide"/>

    <painel-component>
        <div class="clearfix" v-if="create">
            <button-route class="float-end" :items="itemsTwo"/>
        </div>

        <form v-if="viewPage">
            <row-component>
                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <label>Cliente:</label>
                    <input-component disabled v-model="formData.client_name"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Código:</label>
                    <input-component disabled v-model="formData.code"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component
                        class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Link:</label>
                    <div class="input-group" @click="copyLink(formData.urlPrivate)">
                        <input-component @focus="$event.target.select()" v-model="formData.urlPrivate"
                                         readonly :type="'text'" ref="select"/>
                        <span role="button" class="input-group-text">
                            <i :class="['fa-solid', copy? 'fa-check':'fa-copy']"></i>
                        </span>
                    </div>
                </row-col-component>
            </row-component>

            <div class="py-3 mb-5">
                <div class="clearfix">
                    <div class="btn-group float-end" role="group">
                        <button-submit tooltip="Imprimir" @click="print" icon="fa-solid fa-print"/>
                        <button-submit tooltip="Adicionar" @click="addBath" icon="fa-solid fa-plus"/>
                    </div>
                </div>

                <div class="alert alert-secondary my-3" role="alert" v-if="formData.baths.length === 0">
                    Nenhum banho cadastrado
                </div>

                <div class="card bg-light-subtle mt-3" v-else
                     v-for="(data, index) in formData.baths" :key="index" :id="data.id">
                    <div class="card-count">{{ index + 1 }}</div>

                    <div class="py-3 px-0 p-lg-3">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col" style="width: 10%">Imagem</th>
                                <th scope="col" style="width: 15%">Grupo de Banho <br> Origem</th>
                                <th scope="col" style="width: 15%">Código <br> Preço Bruto UN</th>
                                <th scope="col" style="width: 15%">Quant. Bruto <br> Quantidade</th>
                                <th scope="col" style="width: 15%">Peso UN <br> Total Peso</th>
                                <th scope="col" style="width: 20%">Ações</th>
                            </tr>
                            </thead>
                            <tbody class="table-group-divider">
                            <tr>
                                <td data-label="Imagem">
                                    <div class="w-auto position-relative">
                                        <div class="btn-avatar-view">
                                            <button-submit @click="showImg(index)" icon="fa-solid fa-camera-retro"/>
                                        </div>
                                        <img class="avatar-form" v-if="data.photo" :src="data.photo" alt="">
                                        <img class="avatar-form" v-else src="@/assets/avatar.png" alt="">
                                        <label @click="getIdBath(data.id)" :for="'bath' + index"
                                               class="position-absolute camera m-2">
                                            <i role="button" class="fa-solid fa-camera"></i>
                                            <input @change="onFileChange" class="d-none" type="file" :id="'bath' + index">
                                        </label>
                                    </div>
                                </td>
                                <td data-label="Grupo de Banho / Origem">
                                    {{ data.name_group }} <br> {{ data.origem }}
                                </td>
                                <td data-label="Código / Preço Bruto UN">
                                    {{ data.code }} <br> {{ data.price_bruto }}
                                </td>
                                <td data-label="Quant. Bruto / Quantidade">
                                    {{ data.gross_amount }} <br> {{ data.the_amount }}
                                </td>
                                <td data-label="Peso UN / Total Peso">
                                    {{ data.kilo }} <br> {{ data.kilo_total }}
                                </td>
                                <td data-label="Ações">
                                    <button-submit @click="editBath(data.id)" icon="fa-solid fa-pen-to-square"/>
                                    <button-delete type="button" @click="destroy(data)"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" @submit="submit" class="w-100" name="Salvar"
                                   icon="fa-solid fa-floppy-disk"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsOne"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonDelete from "@/components/buttons/ButtonDelete";

import {useToast} from "vue-toastification";
import swal from "sweetalert";
import VueEasyLightbox from "vue-easy-lightbox";
import {ref} from "vue";

const toast = useToast()
export default {
    name: "StockClientUpdate",
    components: {
        VueEasyLightbox,
        ButtonDelete,
        ButtonRoute,
        ButtonSubmit,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("updateNamePage", "Estoque Cliente Editar")
        this.checkACL()
        this.loadItems()
    },

    setup() {
        const visibleRef = ref(false)
        const indexRef = ref(0)
        const imgs = []
        const showImg = (index) => {
            indexRef.value = index
            visibleRef.value = true
        }
        const onHide = () => visibleRef.value = false
        return {
            visibleRef,
            indexRef,
            imgs,
            showImg,
            onHide
        }
    },

    data() {
        return {
            itemsOne: {
                route: "/stock-client/search",
                icon: "fa-solid fa-rotate-left",
                name: "Voltar"
            },

            itemsTwo: {
                route: "/stock-client/create",
                icon: "fa-solid fa-circle-plus",
            },

            create: false,

            formData: {
                id: this.id,
                client_id: "",
                location_id: "",
                code: "",
                code_url: "",
                edit: "",
                client_name: "",
                printUrl: "",
                urlPrivate: "",
                addition: "",
                baths: []
            },

            bath_photo: {
                id: '',
                photo: []
            },

            copy: false,

            viewPage: false,
            sendForm: false,
        }
    },

    methods: {
        submit() {

        },

        loadItems() {
            this.$store.dispatch("loadStock", this.id)
                .then((r) => {
                    this.formData = r
                }).catch(() => {
                toast.error("Erro desconhecido", {timeout: 1500})
            })
        },

        destroy(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyStockBath', item.id).then(() => {
                        this.loadItems()
                        toast.success('Cadastro deletado com sucesso', {timeout: 1500});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1500});
                    })
                }
            });
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 21)
                .then((r) => {
                    if (r.data.indexOf('stock-client-edit') !== -1) {
                        this.viewPage = true

                        if (r.data.indexOf('stock-client-create') !== -1) {
                            this.create = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        print() {
            window.open(this.formData.printUrl, "_blank")
        },

        copyLink(link) {
            this.$refs.select.focus
            navigator.clipboard.writeText(link)
            this.copy = true
            setTimeout(() => {
                this.copy = false
            }, 1500)
        },

        addBath() {
            this.$store.commit("STORAGE", this.formData.addition)
            this.$router.push({name: "stock-client-create-bath", params: {id: this.id}})
        },

        editBath(id) {
            this.$router.push({name: "stock-client-edit-bath", params: {id: this.id, bath: id}})
        },

        getIdBath(id) {
            this.bath_photo.id = id
        },

        onFileChange(event) {
            let files = event.target.files || event.dataTransfer.files
            if (!files.length)
                return
            this.bath_photo.photo = files[0]
            this.submitPhotoBath()
        },

        submitPhotoBath() {
            this.$store.dispatch('updateStockBathPhoto', this.bath_photo)
                .then(() => {
                    this.loadItems()
                    toast.success("Foto atualizada com sucesso", {timeout: 2000});
                }).catch(() => {
                this.loadItems()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },
    }
}
</script>

<style scoped>
.avatar-form::before,
.avatar-form::after {
    display: none;
}

.avatar-form {
    width: 150px;
    height: 200px;
    border-radius: 0;
}

.camera {
    right: 0;
    bottom: -5px;
}

.card-count {
    width: 80px;
    height: 45px;
    margin: 1rem;
    color: #FFFFFF;
    padding: 5px 10px;
    font-weight: bold;
    background-color: #9FA692;
}

.btn-avatar-view {
    opacity: 0;
    width: 150px;
    z-index: 100;
    height: 200px;
    display: grid;
    position: absolute;
    place-items: center;
    transition: all 0.2s ease-in-out;
    background-color: rgba(0, 0, 0, 0.3);
}

.btn-avatar-view:hover {
    opacity: 1;
}

@media (max-width: 991px) {
    .avatar-form {
        height: 150px;
        margin: 20px 0;
    }
}

@media (max-width: 430px) {
    .avatar-form {
        height: 150px;
        margin: 20px 0;
    }

    .camera {
        bottom: -15px;
    }

    .avatar-form img {
        height: 150px;
        display: block;
    }
}
</style>