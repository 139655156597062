import axios from "axios";
import {URL_BASE} from "@/configs/constants";

const RESOURCE = 'order-service-bath'

export default {

    loadBaths(context, params) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}`, {params})
                .then((response) => {
                    context.commit('LOAD_BATH', response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    loadBath(context, id) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE}${RESOURCE}/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    storeOrderServiceBathAddObsInternal(context, formData) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE}order-service-bath-add-obs-internal`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit('LOADER', false)
            })
        })
    },

    updateOrderServiceBathStatus(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE}order-service-bath-status/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },
}