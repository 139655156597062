<template>
    <painel-component>
        <error-component :errors="errors" v-if="showError"/>

        <form v-if="viewPage">
            <div class="clearfix">
                <button-route class="float-end my-2" :items="itemsAdd"/>
            </div>

            <div class="aux my-4">
                <div class="avatar-form">
                    <img v-if="formData.photo" :src="formData.photo" alt="" class="avatar__image">
                    <img v-else src="@/assets/avatar.png" alt="" class="avatar__image">
                </div>
                <p>{{ formData.name }}</p>
            </div>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.name}">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.sexo}">
                    <label>Sexo:</label>
                    <Multiselect v-model="formData.sexo" :options="multiselectSexo" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.status}">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectStatus" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.email}">
                    <label>Login:</label>
                    <input-component v-model="formData.email" :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.group_id}">
                    <label>Grupo:</label>
                    <Multiselect v-model="formData.group_id" :options="multiselectUserGroups"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Alterar Senha:</label>
                    <div class="form-check form-switch">
                        <input @click="passwordToAlter" class="form-check-input" type="checkbox" role="switch"
                               id="switch">
                    </div>
                </row-col-component>
            </row-component>

            <row-component v-if="updatePassword">
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.password}">
                    <label>Senha:</label>
                    <input-component v-model="formData.password" :type="'password'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.passwordconfirm}">
                    <label>Confirmação De Senha:</label>
                    <input-component v-model="formData.passwordconfirm" :type="'password'"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" @submit="submit" class="w-100" name="Salvar"
                                   icon="fa-solid fa-floppy-disk"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/forms/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: 'UserUpdate',
    components: {
        ErrorComponent,
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Usuário Editar')
        this.$store.dispatch('loadMultiSelectUserGroups')
        this.checkACL()
        this.loadItems()
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: '/user/search',
                icon: 'fa-solid fa-rotate-left'
            },

            itemsAdd: {
                name: '',
                route: '/user/create',
                icon: 'fa-solid fa-circle-plus'
            },

            formData: {
                id: this.id,
                name: '',
                sexo: '',
                status: '',
                email: '',
                group_id: '',
                password: '',
                updatepassword: '',
                passwordconfirm: ''
            },

            updatePassword: false,

            viewPage: false,

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {

        loadItems() {
            this.$store.dispatch('loadUser', this.id)
                .then((r) => {
                    this.formData = r
                    this.formData.updatepassword = false
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateUser', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro atualizado com sucesso', {timeout: 1500})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 2)
                .then((r) => {
                    if (r.data.indexOf('user-edit') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        passwordToAlter() {
            this.updatePassword = !this.updatePassword
            this.formData.updatepassword = this.updatePassword
        },

    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        selectStatus() {
            return this.$store.state.var.selectStatus
        },

        multiselectSexo() {
            return this.$store.state.var.multiselectSexo
        },

        multiselectUserGroups() {
            return this.$store.state.var.multiselectUserGroups
        },
    }
}
</script>

<style scoped>
.form-check-input:checked{
    background-color: #9FA692;
    border-color: #9FA692;
}

.form-check-input:focus{
    border-color: #9FA692
}

.form-switch .form-check-input {
    width: 3em;
    height: 2em;
}
</style>