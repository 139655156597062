import actions from "@/store/modules/mooring/actions";
import getters from "@/store/modules/mooring/getters";
import mutations from "@/store/modules/mooring/mutations";
import state from "@/store/modules/mooring/state";

export default {
    actions,
    getters,
    mutations,
    state
}