<template>
    <painel-component class="position-relative">
        <div @click="$emit('close')" class="btn-close"></div>

        <error-component :errors="errors" v-if="showError"/>
        <row-component>
            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>OS:</label>
                <input-component disabled :type="'text'" v-model="os"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-3 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>Desconto / Forma de Pagamento:</label>
                <Multiselect v-model="formData.type" :options="multiselectDiscountPayment"
                             :noOptionsText="noOptionsText" :noResultsText="noResultText"
                             :searchable="true"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                <div v-if="parseInt(formData.type) === 1">
                    <label>Motivo Desconto:</label>
                    <input-component :type="'text'" v-model="formData.name"/>
                </div>

                <div v-else>
                    <label>Forma de Pagamento:</label>
                    <Multiselect v-model="formData.payment_id" :options="multiselectPaymentMethods"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText"
                                 :searchable="true"/>
                </div>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-3 col-xl-2 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>Valor:</label>
                <input-component maxlength="12" v-money="money" :type="'text'" v-model="formData.price"/>
            </row-col-component>
        </row-component>

        <row-component>
            <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <button-submit @submit="submit" :disabled="sendForm" class="w-100" icon="fa-solid fa-circle-plus"
                               name="Adicionar"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import RowColComponent from "@/components/row/RowColComponent";
import RowComponent from "@/components/row/RowComponent";
import InputComponent from "@/components/forms/InputComponent";
import PainelComponent from "@/components/dashboard/PainelComponent";
import Multiselect from "@vueform/multiselect";
import {useToast} from "vue-toastification";
import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ErrorComponent from "@/components/alerts/ErrorComponent";

const toast = useToast()
export default {
    name: 'OSCreateDiscountPayment',
    components: {
        ErrorComponent,
        ButtonSubmit,
        PainelComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect
    },

    directives: {
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'OS Adicionar Desconto / Forma de Pagamento')
        this.$store.dispatch('loadMultiSelectPaymentMethods')
        this.loadItems()
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: '',
                icon: 'fa-solid fa-rotate-left'
            },

            formData: {
                id: this.id,
                payment_id: '',
                name: '',
                type: '1',
                price: ''
            },

            os: '',
            money: MONEY,

            errors: [],
            sendForm: false,
            showError: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadOrderService', this.id)
                .then((r) => {
                    this.os = r.os
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeOrderServiceDiscountPayment', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        this.$emit('close', 1)
                        toast.success('Cadastro adicionado com sucesso', {timeout: 1500})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        multiselectDiscountPayment() {
            return this.$store.state.var.multiselectDiscountPayment
        },

        multiselectPaymentMethods() {
            return this.$store.state.var.multiselectPaymentMethods
        }
    }
}
</script>

<style scoped>
.btn-close {
    top: 1%;
    right: 1%;
    cursor: pointer;
    position: absolute;
}
</style>