<template>
    <div class="modal-window top-50 start-50 translate-middle" v-if="window_update || window_low">
        <EntranceExitForecastUpdate :id="id_update" v-if="window_update" @close-modal="closeUpdate"/>
        <EntranceExitForecastLow :id="id_low" v-if="window_low" @close-modal="closeLow"/>
    </div>

    <painel-component>
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Código:</label>
                    <input-component v-model="formData.code" :type="'text'" placeholder="Código"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Conta Bancária:</label>
                    <Multiselect label="name" v-model="formData.bank_accounts_id" :options="multiselectAccountBank"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Conta Bancária"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Tipo de Conta:</label>
                    <Multiselect v-model="formData.account_types_id" :options="multiselectAccountTypeAPI"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Tipo de Conta"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Entrada/Saida:</label>
                    <Multiselect v-model="formData.type" :options="multiselectEntranceExit"
                                 :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Entrada/Saida"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Mês:</label>
                    <Multiselect v-model="formData.month" :options="multiselectMonth" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Mês"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Ano:</label>
                    <Multiselect v-model="formData.year" :options="multiselectYear()" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Ano"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Antecipado:</label>
                    <Multiselect v-model="formData.attended" :options="multiselectYesNo" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Ano"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 mt-4 mt-xl-0">
                    <button-submit @submit="submit" class="me-1" name="Pesquisar" icon="fa-solid fa-magnifying-glass"/>
                    <button-route v-if="buttonCheck.create" class="ms-1" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>

        <table class="table mt-3">
            <thead>
            <tr class="border-top">
                <th scope="col" style="width: 15%">Conta Bancária</th>
                <th scope="col" style="width: 10%">Tipo de conta</th>
                <th scope="col" style="width: 10%">Entrada/Saída</th>
                <th scope="col" style="width: 10%">Vencimento</th>
                <th scope="col" style="width: 10%">Valor</th>
                <th scope="col" style="width: 15%" v-if="buttonCheck.edit || buttonCheck.delete">Ações</th>
            </tr>
            </thead>
            <tbody class="table-group-divider">
            <tr v-for="(data, index) in items.data" :key="index">
                <td data-label="Conta Bancária">{{ data.bank_accounts_name }}</td>
                <td data-label="Tipo de conta">{{ data.account_types_name }}</td>
                <td data-label="Entrada/Saída">{{ statusType(data.type) }}</td>
                <td data-label="Vencimento">{{ data.due_date }}</td>
                <td data-label="Valor">{{ data.price }}</td>
                <td data-label="Ações" v-if="buttonCheck.edit || buttonCheck.delete">
                    <button-submit tooltip="Editar" class="bg-success border-success" v-if="buttonCheck.edit"
                                   @click="openUpdate(data.id)" icon="fa-solid fa-pen-to-square"/>
                    <button-delete v-if="buttonCheck.delete" @click="destroy(data)"/>
                    <button-submit tooltip="Baixar" v-if="buttonCheck.edit" @click="openLow(data.id)" icon="fa-solid fa-arrow-down"/>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="entrance_exit_forecast.itens" :offset="3" @paginate="loadItems"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import RowComponent from "@/components/row/RowComponent";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import Multiselect from "@vueform/multiselect";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import {useToast} from "vue-toastification";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import InputComponent from "@/components/forms/InputComponent";
import swal from "sweetalert";
import EntranceExitForecastUpdate from "@/views/update/EntranceExitForecastUpdate.vue";
import EntranceExitForecastLow from "@/views/update/EntranceExitForecastLow.vue";

const toast = useToast()
export default {
    name: 'EntranceExitForecastSearch',
    components: {
        EntranceExitForecastLow,
        EntranceExitForecastUpdate,
        InputComponent,
        ButtonPagination,
        ButtonDelete,
        Multiselect,
        ButtonRoute,
        RowComponent,
        ButtonSubmit,
        RowColComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Entrada/Saída (Previsão)')
        this.$store.dispatch('loadMultiSelectAccountBank')
        this.$store.dispatch('loadMultiSelectAccountType')
        this.checkACL()
        this.loadItems(1)
    },

    data() {
        return {
            itemsRoute: {
                name: 'Adicionar',
                route: '/entrance-exit-forecast/create',
                icon: 'fa-solid fa-circle-plus'
            },

            formData: {
                code: '',
                bank_accounts_id: '',
                account_types_id: '',
                type: '',
                month: '',
                year: this.currentYear(),
                business: '',
                attended: ''
            },

            items: {},

            id_low: '',
            id_update: '',
            window_low: false,
            window_update: false,

            buttonCheck: {
                create: false,
                edit: false,
                delete: false,
                low: false
            }

        }
    },

    methods: {
        multiselectYear() {
            let date = new Date()
            let year = date.getFullYear() + 2
            let years = []
            for (let i = 2021; i < year; i++) {
                years.push(i)
            }
            return years
        },

        // Usar no formData
        currentYear() {
            let date = new Date()
            return date.getFullYear()
        },

        submit() {
            this.loadItems(1)
            this.searchSubmit = true
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadForecastInflowsOutflows', {...this.params, page})
                    .then((r) => {
                        this.items = r.itens

                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (this.items.data.length === 0) {
                                toast.info('Nada foi encontrado com os parâmetros informados!', {timeout: 1500})
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 13)
                .then((r) => {
                    if (r.data.indexOf('entrance-exit-preview-search') !== -1) {
                        if (r.data.indexOf('entrance-exit-preview-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('entrance-exit-preview-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('entrance-exit-preview-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }

                        if (r.data.indexOf('entrance-exit-preview-low') !== -1) {
                            this.buttonCheck.low = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        destroy(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name}, ${this.me.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyEntranceExitPreview', item.id).then(() => {
                        this.loadItems(1)
                        toast.success('Cadastro deletado com sucesso', {timeout: 1500});
                    })
                        .catch(() => {
                            toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1500});
                        })
                }
            });
        },

        statusString(value) {
            if (parseInt(value) === 1)
                return 'Ativo'
            return 'Inativo'
        },

        statusType(value) {
            if (parseInt(value) === 1)
                return 'Entrada'
            return 'Saida'
        },

        closeUpdate() {
            this.window_update = false
        },

        closeLow() {
            this.window_low = false
        },

        openUpdate(id) {
            this.window_update = true
            this.id_update = id
        },

        openLow(id) {
            this.window_low = true
            this.id_low = id
        }
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        multiselectEntranceExit() {
            return this.$store.state.var.multiselectEntranceExit
        },

        multiselectMonth() {
            return this.$store.state.var.multiselectMonth
        },

        multiselectYesNo() {
            return this.$store.state.var.multiselectYesNo
        },

        multiselectAccountBank() {
            return this.$store.state.var.multiselectAccountBank
        },

        multiselectAccountTypeAPI() {
            return this.$store.state.var.multiselectAccountTypeAPI
        },

        entrance_exit_forecast() {
            return this.$store.state.entrance_exit_forecast.items
        },

        params() {
            return {
                page: this.entrance_exit_forecast.current_page,
                code: this.formData.code,
                bank_accounts_id: this.formData.bank_accounts_id,
                account_types_id: this.formData.account_types_id,
                type: this.formData.type,
                month: this.formData.month,
                year: this.formData.year,
                business: this.formData.business,
                attended: this.formData.attended
            }
        }
    }
}
</script>

<style scoped>

.modal-window {
    width: 50%;
    z-index: 11;
    position: fixed;
    min-height: 40%;
    overflow-y: auto;
    padding: 60px 20px 0;
    background-color: #FFFFFF;
    box-shadow: 10px 10px 23px 0 rgb(204 204 204);
}

@media (max-width: 1366px) {
    .modal-window {
        width: 60%;
        height: 70%;
    }
}

@media (max-width: 991px) {
    .modal-window {
        width: 90%;
        height: 70%;
    }
}

@media (max-width: 428px) {
    .modal-window {
        width: 95%;
        height: 70%;
    }
}

</style>