<template>
    <div class="modal-window top-50 start-50 translate-middle"
         v-if="obs_modal || table_modal || bath_modal || pay_modal || pay_modal_create || sector_modal || close_modal || additional_services_modal || group_cataloging_editable || group_cataloging || group_cataloging_closure || group_cataloging_inspection">

        <div class="edit-bath" v-if="bath_modal">
            <OSUpdateBath v-if="bath_modal" :items="bath_items" :id="this.id" @close="closeBath"/>
        </div>

        <div class="edit-pay" v-if="pay_modal">
            <OSUpdateDiscountPayment v-if="pay_modal" :id="this.id" :items="pay_items" @close="closePay"/>
        </div>

        <div class="edit-pay" v-if="pay_modal_create">
            <OSCreateDiscountPayment v-if="pay_modal_create" :id="this.id"  @close="closePayCreate"/>
        </div>

        <div class="add-table" v-if="table_modal">
            <div class="btn-close" @click="table_modal = !table_modal"></div>
            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Tabela:</label>
                    <Multiselect v-model="edit_table.table" :options="multiselectTableService"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <button-submit @submit="submitTable" icon="fa-solid fa-floppy-disk" name="Salvar"/>
                </row-col-component>
            </row-component>
        </div>

        <div class="add-obs" v-if="obs_modal">
            <div @click="clsObs" class="btn-close"></div>

            <form>
                <row-component>
                    <row-col-component
                            class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <label v-if="obs">Observações:</label>
                        <label v-if="obsInt">Observações Internas:</label>
                        <text-area v-model="add_obs.obs" rows="5"/>
                    </row-col-component>
                </row-component>
            </form>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <button-submit @submit="submitObs" v-if="obs" icon="fa-solid fa-plus" name="Adicionar"/>
                    <button-submit @submit="submitObsInt" v-if="obsInt" icon="fa-solid fa-plus" name="Adicionar"/>
                </row-col-component>
            </row-component>
        </div>

        <div class="send-sector" v-if="sector_modal">
            <div role="button" class="btn-close" @click="sectorModal"></div>

            <div class="mt-3">
                <row-component>
                    <template v-for="(data, index) in sectors" :key="index">
                        <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                           v-if="parseInt(formData.status) !== data.id">
                            <div class="d-flex align-items-center bg-body-secondary p-3 position-relative">
                                <div class="ms-3 w-75">
                                    <p class="mb-1 text-truncate">{{ data.name }}</p>
                                </div>
                                <div class="position-absolute end-0 me-1 me-lg-3">
                                    <button-submit @click="submitStatus(data.id)" icon="fa-solid fa-paper-plane"/>
                                </div>
                            </div>
                        </row-col-component>
                    </template>
                </row-component>
            </div>
        </div>

        <div class="close-bath" v-if="close_modal">
            <div role="button" class="btn-close" @click="close_modal = false"></div>

            <div class="mt-3">
                <row-component>
                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <label>Com Defeito:</label>
                        <input-component v-model="close_data.the_amount_broked" :type="'number'"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <label>Faltando Pedras:</label>
                        <input-component v-model="close_data.the_amount_missing_stones" :type="'number'"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <label>Perda:</label>
                        <input-component v-model="close_data.miss" :type="'number'"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <button-submit @click="submitClose" class="w-100" name="Salvar" icon="fa-solid fa-floppy-disk"/>
                    </row-col-component>
                </row-component>
            </div>
        </div>

        <div class="additional-services" v-if="additional_services_modal">
            <div class="btn-close" @click="closeAddService"></div>
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Serviço:</label>
                    <Multiselect v-model="add_services.service_id" :options="multiselectService"
                                 @select="loadPriceService(add_services.service_id)"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Quantidade:</label>
                    <input-component @keyup="sumAddService" v-model="add_services.the_amounnt"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Preço:</label>
                    <input-component v-money="money" @keyup="sumAddService" v-model="add_services.price"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Preço Total:</label>
                    <input-component v-model="add_services.price_total" disabled/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <button-submit v-if="store_service" @submit="submitAdditionalService" icon="fa-solid fa-plus"
                                   name="Adicionar"/>
                    <button-submit v-else @submit="submitAdditionalServiceEdit" icon="fa-solid fa-floppy-disk"
                                   name="Salvar"/>
                </row-col-component>
            </row-component>
        </div>

        <div class="cataloguing" v-if="group_cataloging_editable">
            <div role="button" @click="groupCatalogingEditClose" class="btn-close"></div>

            <row-component class="mb-4">
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Selecionar Grupo:</label>
                    <Multiselect ref="GroupCatalogingEditable" :options="multiselectGroupMasterComputed"
                                 @select="handleClick" @deselect="handleClick" @clear="handleClick" @close="handleClick"
                                 mode="tags" :close-on-select="false" :create-option="true" :groups="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <button-submit @click="printGroupCatalogingEditable(this.$refs.GroupCatalogingEditable.textValue)" name="Imprimir" icon="fa-solid fa-print"/>
        </div>

        <div class="cataloguing" v-if="group_cataloging">
            <div role="button" @click="groupCatalogingClose" class="btn-close"></div>

            <row-component class="mb-4">
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Selecionar Grupo:</label>
                    <Multiselect ref="GroupCataloging" :options="multiselectGroupMasterComputed"
                                 @select="handleClickTwo" @deselect="handleClickTwo" @clear="handleClickTwo" @close="handleClickTwo"
                                 mode="tags" :close-on-select="false" :create-option="true" :groups="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <button-submit @click="printGroupCataloging(this.$refs.GroupCataloging.textValue)" name="Imprimir" icon="fa-solid fa-print"/>
        </div>

        <div class="cataloguing" v-if="group_cataloging_closure">
            <div role="button" @click="groupCatalogingClosureClose" class="btn-close"></div>

            <row-component class="mb-4">
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Selecionar Grupo:</label>
                    <Multiselect ref="GroupCatalogingClosure" :options="multiselectGroupMasterComputed"
                                 @select="handleClickThree" @deselect="handleClickThree" @clear="handleClickThree" @close="handleClickThree"
                                 mode="tags" :close-on-select="false" :create-option="true" :groups="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <button-submit @click="printGroupCatalogingClosure(this.$refs.GroupCatalogingClosure.textValue)" name="Imprimir" icon="fa-solid fa-print"/>
        </div>

        <div class="cataloguing" v-if="group_cataloging_inspection">
            <div role="button" @click="groupCatalogingInspectionClose" class="btn-close"></div>

            <row-component class="mb-4">
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Selecionar Grupo:</label>
                    <Multiselect ref="GroupCatalogingInspection" :options="multiselectGroupMasterComputed"
                                 @select="handleClickFour" @deselect="handleClickFour" @clear="handleClickFour" @close="handleClickFour"
                                 mode="tags" :close-on-select="false" :create-option="true" :groups="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <button-submit @click="printGroupCatalogingInspection(this.$refs.GroupCatalogingInspection.textValue)" name="Imprimir" icon="fa-solid fa-print"/>
        </div>
    </div>

    <div class="modal-w position-fixed start-0 top-0 d-grid" v-if="os_additional.show">
        <error-component :errors="os_additional.errors" v-if="os_additional.showError"/>

        <div class="modal-p p-4 pt-5 position-relative" v-if="os_additional.show">
            <div @click="osAdditionalClose" role="button" class="btn-close"></div>

            <h6>Adicionais</h6>
            <form>
                <div class="bg-body-secondary p-2 mb-3">
                    <label class="mb-3 text-uppercase">Caneta de ródio:</label>
                    <row-component>
                        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <label>Quantidade:</label>
                            <input-component @keyup="calcAdditionalRhodium"
                                             v-model="os_additional.formData.the_amount_rhodium_pen" type="number"/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <label>Preço:</label>
                            <input-component @keyup="calcAdditionalRhodium"
                                             v-model="os_additional.formData.price_rhodium_pen" v-money="money"/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <label>Preço Final:</label>
                            <input-component disabled v-model="os_additional.formData.price_result_rhodium_pen"
                                             v-money="money"/>
                        </row-col-component>
                    </row-component>
                </div>

                <div class="bg-body-secondary p-2 mb-3">
                    <label class="mb-3 text-uppercase">Colagem:</label>
                    <row-component>
                        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <label>Quantidade:</label>
                            <input-component @keyup="calcAdditionalCollage"
                                             v-model="os_additional.formData.the_amount_collage" type="number"/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <label>Preço:</label>
                            <input-component @keyup="calcAdditionalCollage"
                                             v-model="os_additional.formData.price_collage" v-money="money"/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <label>Preço Final:</label>
                            <input-component disabled v-model="os_additional.formData.price_result_collage"
                                             v-money="money"/>
                        </row-col-component>
                    </row-component>
                </div>

                <div class="bg-body-secondary p-2 mb-3">
                    <label class="mb-3 text-uppercase">Resina:</label>
                    <row-component>
                        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <label>Quantidade:</label>
                            <input-component @keyup="calcAdditionalResin"
                                             v-model="os_additional.formData.the_amount_resin" type="number"/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <label>Preço:</label>
                            <input-component @keyup="calcAdditionalResin" v-model="os_additional.formData.price_resin"
                                             v-money="money"/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <label>Preço Final:</label>
                            <input-component disabled v-model="os_additional.formData.price_result_resin"
                                             v-money="money"/>
                        </row-col-component>
                    </row-component>
                </div>

                <div class="bg-body-secondary p-2 mb-3">
                    <label class="mb-3 text-uppercase">Reparo/Outros:</label>
                    <row-component>
                        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <label>Quantidade:</label>
                            <input-component @keyup="calcAdditionalRepair"
                                             v-model="os_additional.formData.the_amount_repair_others" type="number"/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <label>Preço:</label>
                            <input-component @keyup="calcAdditionalRepair"
                                             v-model="os_additional.formData.price_repair_others" v-money="money"/>
                        </row-col-component>

                        <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                            <label>Preço Final:</label>
                            <input-component disabled v-model="os_additional.formData.price_result_repair_others"
                                             v-money="money"/>
                        </row-col-component>
                    </row-component>
                </div>

                <row-component>
                    <row-col-component class-row-col="col-12">
                        <button-submit v-if="!os_additional.edit" :disabled="sendForm" @click="storeAdditional"
                                       name="Adicionar" icon="fa-solid fa-plus"/>
                        <button-submit v-else :disabled="sendForm" @click="updateAdditional" name="Salvar"
                                       icon="fa-solid fa-floppy-disk"/>
                    </row-col-component>
                </row-component>
            </form>
        </div>
    </div>

    <vue-easy-lightbox :visible="visibleRef" :imgs="formData.photosModal" :index="indexRef" @hide="onHide"/>

    <painel-component>
        <error-component :errors="errors" v-if="showError"/>

        <div class="clearfix" style="padding-right: 5px">
            <div class="float-end">
                <span class="badge text-bg-warning p-3 mb-3">Ouro dia: R${{ formData.priceOuro }}</span>
            </div>
        </div>

        <div class="tabs effect-3" v-if="viewPage">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="fa-solid fa-gem"></i>
                <p>Banho</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="fa-solid fa-circle-check"></i>
                <p>Fechamento</p>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3">
            <span>
                <i class="fa-solid fa-truck-fast"></i>
                <p>Envio</p>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3">
            <span>
                <i class="fa-solid fa-comment"></i>
                <p>Observações</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="d-flex">
                                    <span class="badge text-bg-primary me-2">
                                        Status: {{ statusString(formData.status) }}
                                    </span>
                                    <span class="badge text-bg-success me-2">
                                        Total Peso OS: {{ formData.pesoTotal }}
                                    </span>
                                    <span class="badge text-bg-secondary  me-2">{{ formData.tableOs }}</span>
                                </div>
                            </row-col-component>

                            <row-col-component
                                    class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="btn-group float-end" role="group">
                                    <button-submit @click="editTable" tooltip="Editar Tabela" icon="fa-solid fa-table"/>
                                    <button-submit @click="sectorModal" tooltip="Enviar para setor" icon="fa-solid fa-paper-plane"/>
                                    <button-submit @click="routeAdd" tooltip="Adicionar" icon="fa-solid fa-circle-plus"/>

                                    <div class="dropdown">
                                        <button class="btn rounded-0 my-background btn-secondary dropdown-toggle"
                                                type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="fa-solid fa-print"></i>
                                        </button>
                                        <ul class="dropdown-menu">
                                            <li><a target="_blank" class="dropdown-item"
                                                   :href="formData.urlPrintOS">
                                                Ficha OS
                                            </a></li>
                                            <li><a @click="group_cataloging = true" class="dropdown-item">
                                                Catalogação
                                            </a></li>
                                            <li><a @click="group_cataloging_editable = true" class="dropdown-item">
                                                Catalogação (Editável Por grupo)
                                            </a></li>
                                            <li><a @click="group_cataloging_closure = true" class="dropdown-item">
                                                Catalogação (Fechamento)
                                            </a></li>
                                            <li><a @click="group_cataloging_inspection = true" class="dropdown-item">
                                                Catalogação (Inspeção)
                                            </a></li>
                                            <li><a target="_blank" class="dropdown-item"
                                                   :href="formData.urlPrintOSBath">
                                                Banho
                                            </a></li>
                                        </ul>
                                    </div>

                                    <button-submit @click="routeSearch" tooltip="Voltar" icon="fa-solid fa-rotate-left"/>
                                </div>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <label>Link:</label>
                                <div class="input-group" @click="copyLink(formData.urlPrivate)">
                                    <input-component @focus="$event.target.select()" v-model="formData.urlPrivate" readonly :type="'text'" ref="select"/>
                                    <span role="button" class="input-group-text">
                                        <i :class="['fa-solid', copy? 'fa-check':'fa-copy']"></i>
                                    </span>
                                </div>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                                <label>OS:</label>
                                <input-component v-model="formData.os" disabled :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Data:</label>
                                <input-component v-model="formData.date_hour_create" disabled :type="'text'"/>

                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>Cliente:</label>
                                <input-component v-model="formData.client_name" disabled :type="'text'"/>
                            </row-col-component>
                        </row-component>
                    </form>

                    <div class="p-3 mb-5">
                        <div class="clearfix">
                            <div class="btn-group float-end" role="group">
                                <button-submit tooltip="Deletar todos serviços" icon="fa-solid fa-trash" @click="destroyAllBath"/>
                                <button-submit tooltip="Serviço por grupo de banho" icon="fa-solid fa-square-plus" @click="routeServiceGroupOS"/>
                                <button-submit tooltip="Estoque cliente" @click="routeStockBathOS" icon="fa-solid fa-user-plus"/>
                                <button-submit tooltip="Baixar imagens" @click="downloadAll" icon="fa-solid fa-circle-down"/>
                                <button-submit tooltip="Adicionar Peça" @click="routeAddBath" icon="fa-solid fa-plus"/>
                            </div>
                        </div>

                        <div class="alert alert-secondary my-3" role="alert" v-if="formData.baths.length === 0">
                            Nenhum banho cadastrado
                        </div>

                        <div class="card bg-light-subtle mt-3" v-else
                             v-for="(data, index) in formData.baths" :key="index" :id="'services' + data.id">
                            <div class="card-count">{{ index + 1 }}</div>

                            <div class="py-3 px-0 p-lg-3">
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col" style="width: 10%">Imagem</th>
                                        <th scope="col" style="width: 15%">Grupo de Banho <br> Origem</th>
                                        <th scope="col" style="width: 15%">Código <br> Preço Bruto UN</th>
                                        <th scope="col" style="width: 15%">Quant. Bruto <br> Quantidade</th>
                                        <th scope="col" style="width: 15%">Peso UN <br> Total Peso</th>
                                        <th scope="col" style="width: 20%">Ações</th>
                                    </tr>
                                    </thead>
                                    <tbody class="table-group-divider">
                                    <tr>
                                        <td data-label="Imagem">
                                            <div class="w-auto position-relative">
                                                <div class="btn-avatar-view">
                                                    <button-submit @click="showImg(index)" icon="fa-solid fa-camera-retro"/>
                                                </div>
                                                <img @click="showImg(index)" class="avatar-form" v-if="data.photo" :src="data.photo" alt="">
                                                <img @click="showImg(index)" class="avatar-form" v-else src="@/assets/avatar.png" alt="">
                                                <label @click="getIdBath(data.id)" :for="'bath' + index" class="position-absolute camera m-2">
                                                    <i role="button" class="fa-solid fa-camera"></i>
                                                    <input @change="onFileChange" class="d-none" type="file" :id="'bath' + index">
                                                </label>
                                            </div>
                                        </td>
                                        <td data-label="Grupo de Banho / Origem">
                                            {{ data.name_group }} <br> {{ data.origem }}
                                        </td>
                                        <td data-label="Código / Preço Bruto UN">
                                            {{ data.code }} <br> {{ data.price_bruto }}
                                        </td>
                                        <td data-label="Quant. Bruto / Quantidade">
                                            {{ data.gross_amount }} <br> {{ data.the_amount }}
                                        </td>
                                        <td data-label="Peso UN / Total Peso">
                                            {{ data.kilo }} <br> {{ data.kilo_total }}
                                        </td>
                                        <td data-label="Ações">
                                            <button-submit @click="routeAddBathService(data.id)" icon="fa-solid fa-circle-plus" tooltip="Adicionar" class="bg-success border-success me-1"/>
                                            <button-submit tooltip="Editar" @click="editBath(data)" icon="fa-solid fa-pen-to-square"/>
                                            <button-delete @click="destroyBath(data)"/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="accordion p-3" :id="'accordionExample' + index" v-if="data.services.length > 0">
                                <div class="accordion-item" :id="'services' + data.id">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" :data-bs-target="'#collapseTwo' + index"
                                                aria-expanded="false" aria-controls="collapseTwo">
                                        </button>
                                    </h2>
                                    <div :id="'collapseTwo' + index" class="accordion-collapse collapse"
                                         :data-bs-parent="'#accordionExample' + index">
                                        <div class="accordion-body">
                                            <div v-for="(dataTwo, indexTwo) in data.services" :key="indexTwo"
                                                 :id="'services' + dataTwo.id" class="bg-body-secondary mb-3 p-3">

                                                <div class="clearfix">
                                                    <div class="float-end">
                                                        <router-link tooltip="Editar" class="btn btn-success my-3"
                                                                     :to="{name: 'os-bath-service-edit',
                                                             params: {id: this.id,
                                                             id_bath: data.id,
                                                             id_table: this.formData.table,
                                                             id_service: dataTwo.id}}">
                                                            <i class="fa-solid fa-pen-to-square"></i>
                                                        </router-link>

                                                        <button-delete @click="destroyBathService(dataTwo)"/>
                                                    </div>
                                                </div>

                                                <div class="table">
                                                    <strong>Serviço: {{ dataTwo.name_service }}</strong>
                                                </div>
                                                <hr>
                                                <table class="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 15%">Base</th>
                                                        <th scope="col" style="width: 15%">Preço</th>
                                                        <th scope="col" style="width: 15%">Cor</th>
                                                        <th scope="col" style="width: 15%">Preço</th>
                                                        <th scope="col" style="width: 15%">Verniz</th>
                                                        <th scope="col" style="width: 10%">Preço</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody class="table-group-divider">
                                                    <tr>
                                                        <td data-label="Base">{{ dataTwo.name_base }}</td>
                                                        <td data-label="Preço">R$ {{ dataTwo.price_base }}</td>
                                                        <td data-label="Cor">{{ dataTwo.name_cor }}</td>
                                                        <td data-label="Preço">R$ {{ dataTwo.price_cor }}</td>
                                                        <td data-label="Verniz">{{ dataTwo.name_verniz }}</td>
                                                        <td data-label="Preço">R$ {{ dataTwo.price_verniz }}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>

                                                <table class="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 20%">Quantidade</th>
                                                        <th scope="col" style="width: 20%">Peso</th>
                                                        <th scope="col" style="width: 20%">Total Peso</th>
                                                        <th scope="col" style="width: 20%">Milésimos</th>
                                                        <th scope="col" style="width: 20%">M.O.</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody class="table-group-divider">
                                                    <tr>
                                                        <td data-label="Quantidade">{{ dataTwo.the_amount }}</td>
                                                        <td data-label="Peso">{{ dataTwo.kilo }}</td>
                                                        <td data-label="Total Peso">{{ dataTwo.kilo_total }}</td>
                                                        <td data-label="Milésimos">{{ dataTwo.layer }}</td>
                                                        <td data-label="M.O.">{{ dataTwo.thousandth }}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>

                                                <table class="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 20%">Preço do Serviço</th>
                                                        <th scope="col" style="width: 20%">Custo do Banho por KG</th>
                                                        <th scope="col" style="width: 30%">
                                                            Custo do Serviço (MO + ML)
                                                        </th>
                                                        <th scope="col" style="width: 10%">Subtotal</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody class="table-group-divider">
                                                    <tr>
                                                        <td data-label="Preço do Serviço">{{ dataTwo.price_kilo }}</td>
                                                        <td data-label="Custo do Banho por KG">
                                                            {{ dataTwo.price_bath_kg }}
                                                        </td>
                                                        <td data-label="Custo do Serviço (MO + ML)">
                                                            {{ dataTwo.price_ml_mo }}
                                                        </td>
                                                        <td data-label="Subtotal">{{ dataTwo.price_subtotal }}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="tab-item-2">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Peças
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse"
                                 data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="p-3 mb-5">
                                        <div class="alert alert-secondary my-3" role="alert"
                                             v-if="formData.bathsClose.length === 0">
                                            Nenhum fechamento
                                        </div>

                                        <div class="card bg-light-subtle mt-3" v-else
                                             v-for="(data, index) in formData.bathsClose" :key="index">
                                            <div class="card-count">{{ index + 1 }}</div>

                                            <div class="py-3 px-0 p-lg-3">
                                                <table class="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 16.6%">Imagem</th>
                                                        <th scope="col" style="width: 16.6%">Grupo de Banho</th>
                                                        <th scope="col" style="width: 16.6%">Bruto</th>
                                                        <th scope="col" style="width: 16.6%">Quantidade</th>
                                                        <th scope="col" style="width: 16.6%">Peso</th>
                                                        <th scope="col" style="width: 16.6%">Total Peso</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody class="table-group-divider">
                                                    <tr>
                                                        <td class="position-relative" data-label="Imagem">
                                                            <div class="btn-avatar-view">
                                                                <button-submit @click="showImg(index)" icon="fa-solid fa-camera-retro"/>
                                                            </div>
                                                            <img class="avatar-form" v-if="data.photo" :src="data.photo" alt="">
                                                            <img class="avatar-form" v-else src="@/assets/avatar.png" alt="">
                                                        </td>
                                                        <td data-label="Grupo de Banho">{{ data.name_group }}</td>
                                                        <td data-label="Bruto">{{ data.price_bruto }}</td>
                                                        <td data-label="Quantidade">{{ data.the_amount }}</td>
                                                        <td data-label="Peso">{{ data.kilo }}</td>
                                                        <td data-label="Total Peso">{{ data.kilo_total }}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>

                                                <table class="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 23.3%">Preço Bruto</th>
                                                        <th scope="col" style="width: 23.3%">Código</th>
                                                        <th scope="col" style="width: 23.3%">Origem</th>
                                                        <th scope="col" style="width: 30%">Descrição</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody class="table-group-divider">
                                                    <tr>
                                                        <td data-label="Preço Bruto">{{ data.price_bruto }}</td>
                                                        <td data-label="Código">{{ data.code }}</td>
                                                        <td data-label="Origem">{{ data.origem }}</td>
                                                        <td data-label="Descrição">{{ data.desc }}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>

                                                <div class="accordion" :id="'accordionExample' + index"
                                                     v-if="data.services.length > 0">
                                                    <div class="accordion-item" :id="'services' + data.id">
                                                        <h2 class="accordion-header">
                                                            <button class="accordion-button collapsed" type="button"
                                                                    data-bs-toggle="collapse"
                                                                    :data-bs-target="'#collapseTwo' + index"
                                                                    aria-expanded="false" aria-controls="collapseTwo">
                                                            </button>
                                                        </h2>
                                                        <div :id="'collapseTwo' + index"
                                                             class="accordion-collapse collapse"
                                                             :data-bs-parent="'#accordionExample' + index">
                                                            <div class="accordion-body">
                                                                <div v-for="(dataTwo, indexTwo) in data.services"
                                                                     :key="indexTwo"
                                                                     :id="'services' + dataTwo.id"
                                                                     class="bg-body-secondary mb-5 p-3">

                                                                    <div class="table d-flex justify-content-between">
                                                                        <strong>
                                                                            Serviço: {{ dataTwo.name_service }}
                                                                        </strong>
                                                                        <button-submit icon="fa-solid fa-pen-to-square" tooltip="Alterar perda" @click="loadBathClose(dataTwo.id)"/>
                                                                    </div>
                                                                    <hr>
                                                                    <table class="table table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th scope="col" style="width: 15%">Base</th>
                                                                            <th scope="col" style="width: 15%">
                                                                                Preço
                                                                            </th>
                                                                            <th scope="col" style="width: 15%">Cor</th>
                                                                            <th scope="col" style="width: 15%">
                                                                                Preço
                                                                            </th>
                                                                            <th scope="col" style="width: 15%">
                                                                                Verniz
                                                                            </th>
                                                                            <th scope="col" style="width: 10%">
                                                                                Preço
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody class="table-group-divider">
                                                                        <tr>
                                                                            <td data-label="Base">
                                                                                {{dataTwo.name_base}}
                                                                            </td>
                                                                            <td data-label="Preço">R$
                                                                                {{ dataTwo.price_base }}
                                                                            </td>
                                                                            <td data-label="Cor">
                                                                                {{ dataTwo.name_cor }}
                                                                            </td>
                                                                            <td data-label="Preço">R$
                                                                                {{ dataTwo.price_cor }}
                                                                            </td>
                                                                            <td data-label="Verniz">
                                                                                {{ dataTwo.name_verniz }}
                                                                            </td>
                                                                            <td data-label="Preço">R$
                                                                                {{ dataTwo.price_verniz }}
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>

                                                                    <table class="table table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th scope="col" style="width: 20%">
                                                                                Quantidade
                                                                            </th>
                                                                            <th scope="col" style="width: 20%">Peso</th>
                                                                            <th scope="col" style="width: 20%">Total
                                                                                Peso
                                                                            </th>
                                                                            <th scope="col" style="width: 20%">
                                                                                Milésimos
                                                                            </th>
                                                                            <th scope="col" style="width: 20%">M.O.</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody class="table-group-divider">
                                                                        <tr>
                                                                            <td data-label="Quantidade">
                                                                                {{ dataTwo.the_amount }}
                                                                            </td>
                                                                            <td data-label="Peso">
                                                                                {{ dataTwo.kilo }}
                                                                            </td>
                                                                            <td data-label="Total Peso">
                                                                                {{ dataTwo.kilo_total }}
                                                                            </td>
                                                                            <td data-label="Milésimos">
                                                                                {{ dataTwo.layer }}
                                                                            </td>
                                                                            <td data-label="M.O.">
                                                                                {{ dataTwo.thousandth }}
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>

                                                                    <table class="table table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th scope="col" style="width: 20%">Preço do
                                                                                Serviço
                                                                            </th>
                                                                            <th scope="col" style="width: 20%">Custo do
                                                                                Banho por KG
                                                                            </th>
                                                                            <th scope="col" style="width: 30%">Custo do
                                                                                Serviço (MO +
                                                                                ML)
                                                                            </th>
                                                                            <th scope="col" style="width: 10%">
                                                                                Subtotal
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody class="table-group-divider">
                                                                        <tr>
                                                                            <td data-label="Preço do Serviço">
                                                                                {{ dataTwo.price_kilo }}
                                                                            </td>
                                                                            <td data-label="Custo do Banho por KG">
                                                                                {{ dataTwo.price_bath_kg }}
                                                                            </td>
                                                                            <td data-label="Custo do Serviço (MO + ML)">
                                                                                {{ dataTwo.price_ml_mo }}
                                                                            </td>
                                                                            <td data-label="Subtotal">
                                                                                {{ dataTwo.price_subtotal }}
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>

                                                                    <table class="table table-striped">
                                                                        <thead>
                                                                        <tr>
                                                                            <th scope="col" style="width: 33.3%">Com
                                                                                Defeitos
                                                                            </th>
                                                                            <th scope="col" style="width: 33.3%">
                                                                                Faltando Pedras
                                                                            </th>
                                                                            <th scope="col" style="width: 33.3%">
                                                                                Perdas
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody class="table-group-divider">
                                                                        <tr>
                                                                            <td data-label="Com Defeitos">
                                                                                {{dataTwo.the_amount_broked ? dataTwo.the_amount_broked : "0"}}
                                                                            </td>
                                                                            <td data-label="Faltando Pedras">
                                                                                {{ dataTwo.the_amount_missing_stones ? dataTwo.the_amount_missing_stones : "0" }}
                                                                            </td>
                                                                            <td data-label="Perdas">
                                                                                {{ dataTwo.miss ? dataTwo.miss : "0" }}
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>

                                                                    <div class="d-flex justify-content-between mt-5 mb-3">
                                                                        <strong>Adicionais:</strong>
                                                                        <div>
                                                                            <button-delete v-if="dataTwo.additional"
                                                                                           @click="destroyAdditional(dataTwo.additional)"/>
                                                                            <button-submit v-if="!dataTwo.additional"
                                                                                           icon="fa-solid fa-plus"
                                                                                           name="Adicionais"
                                                                                           @click="osAdditional(dataTwo.id)"/>
                                                                            <button-submit v-else
                                                                                           icon="fa-solid fa-pen-to-square"
                                                                                           name="Adicionais (Editar)"
                                                                                           @click="osAdditionalEdit(dataTwo.additional.id)"/>
                                                                        </div>
                                                                    </div>

                                                                    <table class="table table-striped"
                                                                           v-if="dataTwo.additional">
                                                                        <thead>
                                                                        <tr>
                                                                            <th scope="col" style="width: 16.6%">
                                                                                Quantidade (Ródio)
                                                                            </th>
                                                                            <th scope="col" style="width: 16.6%">Preço
                                                                                (Ródio)
                                                                            </th>
                                                                            <th scope="col" style="width: 16.6%">Preço
                                                                                Total (Ródio)
                                                                            </th>

                                                                            <th scope="col" style="width: 16.6%">
                                                                                Quantidade (Colagem)
                                                                            </th>
                                                                            <th scope="col" style="width: 16.6%">Preço
                                                                                (Colagem)
                                                                            </th>
                                                                            <th scope="col" style="width: 16.6%">Preço
                                                                                Total (Colagem)
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody class="table-group-divider">
                                                                        <tr>
                                                                            <td data-label="Quantidade (Ródio)">
                                                                                {{ dataTwo.additional.the_amount_rhodium_pen }}
                                                                            </td>
                                                                            <td data-label="Preço (Ródio)">
                                                                                R${{ dataTwo.additional.price_rhodium_pen }}
                                                                            </td>
                                                                            <td data-label="Preço Total (Ródio)">
                                                                                R${{ dataTwo.additional.price_result_rhodium_pen }}
                                                                            </td>
                                                                            <td data-label="Quantidade (Colagem)">
                                                                                {{ dataTwo.additional.the_amount_collage }}
                                                                            </td>
                                                                            <td data-label="Preço (Colagem)">
                                                                                R${{ dataTwo.additional.price_collage }}
                                                                            </td>
                                                                            <td data-label="Preço Total (Colagem)">
                                                                                R${{ dataTwo.additional.price_result_collage }}
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>

                                                                    <table class="table table-striped"
                                                                           v-if="dataTwo.additional">
                                                                        <thead>
                                                                        <tr>
                                                                            <th scope="col" style="width: 16.6%">
                                                                                Quantidade (Resina)
                                                                            </th>
                                                                            <th scope="col" style="width: 16.6%">Preço
                                                                                (Resina)
                                                                            </th>
                                                                            <th scope="col" style="width: 16.6%">Preço
                                                                                Total (Resina)
                                                                            </th>

                                                                            <th scope="col" style="width: 16.6%">
                                                                                Quantidade
                                                                                (Reparos/Outros)
                                                                            </th>
                                                                            <th scope="col" style="width: 16.6%">Preço
                                                                                (Reparos/Outros)
                                                                            </th>
                                                                            <th scope="col" style="width: 16.6%">Preço
                                                                                Total
                                                                                (Reparos/Outros)
                                                                            </th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody class="table-group-divider">
                                                                        <tr>
                                                                            <td data-label="Quantidade (Resina)">
                                                                                {{ dataTwo.additional.the_amount_resin }}
                                                                            </td>
                                                                            <td data-label="Preço (Resina)">
                                                                                R${{ dataTwo.additional.price_resin }}
                                                                            </td>
                                                                            <td data-label="Preço Total (Resina)">
                                                                                R${{ dataTwo.additional.price_result_resin }}
                                                                            </td>
                                                                            <td data-label="Quantidade (Reparos/Outros)">
                                                                                {{ dataTwo.additional.the_amount_repair_others }}
                                                                            </td>
                                                                            <td data-label="Preço (Reparos/Outros)">
                                                                                R${{ dataTwo.additional.price_repair_others }}
                                                                            </td>
                                                                            <td data-label="Preço Total (Reparos/Outros)">
                                                                                R${{ dataTwo.additional.price_result_repair_others }}
                                                                            </td>
                                                                        </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Fechamento
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse show"
                                 data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="card my-5 p-3" v-if="formData.bathsLoss">
                                        <h6 class="text-uppercase">Perdas Banho</h6>
                                        <hr>

                                        <table class="table table-striped bg-body-secondary">
                                            <thead>
                                            <tr>
                                                <th scope="col" style="width: 25%">Total Com Defeito</th>
                                                <th scope="col" style="width: 25%">Total Faltando Pedra</th>
                                                <th scope="col" style="width: 25%">Total Perdas</th>
                                                <th scope="col" style="width: 25%">Sugestão desconto perda</th>
                                            </tr>
                                            </thead>
                                            <tbody class="table-group-divider">
                                            <tr>
                                                <td data-label="Total Com Defeito">
                                                    {{ formData.bathsLoss.total_the_amount_broked }}
                                                </td>
                                                <td data-label="Total Faltando Pedra">
                                                    {{ formData.bathsLoss.total_the_amount_missing_stones }}
                                                </td>
                                                <td data-label="Total Perdas">
                                                    {{ formData.bathsLoss.total_miss }}
                                                </td>
                                                <td data-label="Sugestão desconto perda">R$
                                                    {{ formData.bathsLoss.total_miss_value }}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div  class="card my-5 p-3" v-if="formData.bathServicesAdd">
                                        <h6 class="text-uppercase">Serviços Adicionais Total Banho</h6>
                                        <hr>

                                        <table class="table table-striped bg-body-secondary mt-3">
                                            <thead>
                                            <tr>
                                                <th scope="col" style="width: 25%">Quantidade Total Ródio</th>
                                                <th scope="col" style="width: 25%">Quantidade Total Colagem</th>
                                                <th scope="col" style="width: 25%">Quantidade Total Resina</th>
                                                <th scope="col" style="width: 25%">Quantidade Total
                                                    Reparos/Outros
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody class="table-group-divider">
                                            <tr>
                                                <td data-label="Quantidade Total Ródio">
                                                    {{ formData.bathServicesAdd.total_the_amount_rhodium_pen }}
                                                </td>
                                                <td data-label="Quantidade Total Colagem">
                                                    {{ formData.bathServicesAdd.total_the_amount_collage }}
                                                </td>
                                                <td data-label="Quantidade Total Resina">
                                                    {{ formData.bathServicesAdd.total_the_amount_resin }}
                                                </td>

                                                <td data-label="Quantidade Total Reparos/Outros">
                                                    {{ formData.bathServicesAdd.total_the_amount_repair_others }}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                        <table class="table table-striped bg-body-secondary" v-if="formData.bathServicesAdd">
                                            <thead>
                                            <tr>
                                                <th scope="col" style="width: 25%">Valor Total Ródio</th>
                                                <th scope="col" style="width: 25%">Valor Total Colagem</th>
                                                <th scope="col" style="width: 25%">Valor Total Resina</th>
                                                <th scope="col" style="width: 25%">Valor Total Reparos/Outros</th>
                                            </tr>
                                            </thead>
                                            <tbody class="table-group-divider">
                                            <tr>
                                                <td data-label="Quantidade Total Ródio">
                                                    {{ formData.bathServicesAdd.total_price_result_rhodium_pen }}
                                                </td>
                                                <td data-label="Quantidade Total Colagem">
                                                    {{ formData.bathServicesAdd.total_price_result_collage }}
                                                </td>
                                                <td data-label="Quantidade Total Resina">
                                                    {{ formData.bathServicesAdd.total_price_result_resin }}
                                                </td>

                                                <td data-label="Quantidade Total Reparos/Outros">
                                                    {{ formData.bathServicesAdd.total_price_result_repair_others }}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="card my-5 p-3">
                                        <h6 class="text-uppercase">Serviços Adicionais OS</h6>
                                        <hr>

                                        <div class="clearfix my-3">
                                            <button-submit @click="additional_services_modal = true" class="float-end" icon="fa-solid fa-plus"/>
                                        </div>

                                        <table class="table table-striped bg-body-secondary">
                                            <thead>
                                            <tr>
                                                <th scope="col" style="width: 20%">Criador</th>
                                                <th scope="col" style="width: 20%">Quantidade</th>
                                                <th scope="col" style="width: 20%">Preço UN</th>
                                                <th scope="col" style="width: 20%">Preço Total</th>
                                                <th scope="col" style="width: 20%">Ações</th>
                                            </tr>
                                            </thead>
                                            <tbody class="table-group-divider">
                                            <tr v-for="(data, index) in formData.serviceAdds" :key="index">
                                                <td data-label="Criador">{{ data.creator_user_name }}</td>
                                                <td data-label="Quantidade">{{ data.the_amounnt }}</td>
                                                <td data-label="Preço UN">{{ data.price }}</td>
                                                <td data-label="Preço Total">{{ data.price_total }}</td>
                                                <td data-label="Ações">
                                                    <button class="btn btn-success" @click="editAdditionalService(data)">
                                                        <i class="fa-solid fa-pen-to-square"></i>
                                                    </button>
                                                    <button-delete @click="destroyAdditionalService(data)"/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="table-responsive-xl">
                                        <table class="table table-striped bg-body-secondary change my-5"
                                               v-if="formData.closureValue">
                                            <thead>
                                            <tr>
                                                <th scope="col" style="font-size:12px; width: 3%;">Grupo</th>
                                                <th scope="col" style="font-size:12px; width: 3%">Qtde. Kilo</th>
                                                <th scope="col" style="font-size:12px; width: 2%">M.L</th>
                                                <th scope="col" style="font-size:12px; width: 2%">M.O</th>
                                                <th scope="col" style="font-size:12px; width: 3%">R$ Metal</th>
                                                <th scope="col" style="font-size:12px; width: 3%">Tipo Banho</th>
                                                <th scope="col" style="font-size:12px; width: 3%">Cor Banho</th>
                                                <th scope="col" style="font-size:12px; width: 3%">Preço Kilo</th>
                                                <th scope="col" style="font-size:12px; width: 3%">Valor Banho</th>
                                                <th scope="col" style="font-size:12px; width: 4%">Valor Base</th>
                                                <th scope="col" style="font-size:12px; width: 3%">Valor Cor</th>
                                                <th scope="col" style="font-size:12px; width: 5%">Valor Verniz</th>
                                                <th scope="col" style="font-size:12px; width: 5%">Pr. Total Item</th>
                                                <th scope="col" style="font-size:12px; width: 5%">Custo Grama</th>
                                            </tr>
                                            </thead>

                                            <tbody class="table-group-divider">
                                            <tr v-for="(data, index) in formData.closureValue" :key="index">
                                                <td style="font-size:13px;" data-label="Grupo">{{ data.nameBathGroup }}</td>
                                                <td style="font-size:13px;" data-label="Qtde. Kilo">{{ data.totalKilo }}</td>
                                                <td style="font-size:13px;" data-label="M.L">{{ data.thousandth }}</td>
                                                <td style="font-size:13px;" data-label="M.O">{{ data.layer }}</td>
                                                <td style="font-size:13px;" data-label="R$ Metal">{{ data.price_kilo }}</td>
                                                <td style="font-size:13px;" data-label="Tipo Banho">{{ data.nameService }}</td>
                                                <td style="font-size:13px;" data-label="Cor Banho">{{ data.name_cor }}</td>
                                                <td style="font-size:13px;" data-label="Preço Kilo">{{ data.price_kilo_total }}</td>
                                                <td style="font-size:13px;" data-label="Valor Banho">{{ data.price_bath_total }}</td>
                                                <td style="font-size:13px;" data-label="Valor Base">{{ data.value_base_total }}</td>
                                                <td style="font-size:13px;" data-label="Valor Cor">{{ data.value_cor_total }}</td>
                                                <td style="font-size:13px;" data-label="Valor Verniz">{{ data.value_verniz_total }}</td>
                                                <td style="font-size:13px;" data-label="Pr. Total Item">{{ data.totalItem }}</td>
                                                <td style="font-size:13px;" data-label="Custo Grama">{{ data.totalIGR }}</td>
                                            </tr>
                                            </tbody>
                                            <tbody class="table-dark">
                                            <tr>
                                                <td style="font-size:13px;" colspan="2" >Peso Total: {{ formData.closureValue.totalKilOS }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="card my-4 p-3">
                                        <table class="table table-striped mb-4">
                                            <thead>
                                            <tr>
                                                <th scope="col" style="width: 25%">Total Banho</th>
                                                <th scope="col" style="width: 25%">Total Envio</th>
                                                <th scope="col" style="width: 25%">Total Serviços Adicionais Banho</th>
                                                <th scope="col" style="width: 25%">Total Serviços Adicionais OS</th>
                                            </tr>
                                            </thead>
                                            <tbody class="table-group-divider">
                                            <tr>
                                                <td data-label="Total Banho">{{formData.totalValueBath}}</td>
                                                <td data-label="Total Envio">{{formData.totalValueSend}}</td>
                                                <td data-label="Total Serviços Adicionais Banho">{{formData.totalValueBanhoServiceAdd}}</td>
                                                <td data-label="Total Serviços Adicionais OS">{{formData.totalValueAddServiceOS}}</td>
                                            </tr>
                                            </tbody>
                                        </table>

                                        <hr>

                                        <div class="clearfix my-3">
                                            <button-submit @click="createPay" class="float-end" icon="fa-solid fa-plus" name="Adicionar"/>
                                        </div>

                                        <div class="alert alert-secondary my-3" role="alert"
                                             v-if="formData.paymentsDiscount.length === 0 && formData.paymentsForm.length === 0">
                                            Nenhum desconto ou forma de pagamento cadastrado
                                        </div>

                                        <table class="table table-striped mb-4" v-else>
                                            <thead>
                                            <tr>
                                                <th scope="col">Desconto / Forma de Pagamento</th>
                                                <th scope="col">Nome</th>
                                                <th scope="col">Valor</th>
                                                <th scope="col">Data</th>
                                                <th scope="col" style="width: 13%">Ações</th>
                                            </tr>
                                            </thead>

                                            <tbody class="table-group-divider">
                                            <tr v-for="(data, index) in formData.paymentsForm" :key="index">
                                                <td data-label="Desconto / Forma de Pagamento">
                                                    {{ paymentsString(data.type) }}
                                                </td>
                                                <td data-label="Nome">{{ data.payment_name }}</td>
                                                <td data-label="Valor">{{ data.price }}</td>
                                                <td data-label="Data">{{ data.dateHour }}</td>
                                                <td data-label="Ações">
                                                    <button-submit @click="editPay(data)" icon="fa-solid fa-pen-to-square" tooltip="Editar" class="bg-success border-success d-inline-block"/>
                                                    <button-delete @click="destroyDiscountPayment(data)"/>
                                                </td>
                                            </tr>

                                            <tr v-for="(data, index) in formData.paymentsDiscount" :key="index">
                                                <td data-label="Desconto / Forma de Pagamento">
                                                    {{ paymentsString(data.type) }}
                                                </td>
                                                <td data-label="Nome">{{ data.name }}</td>
                                                <td data-label="Valor">{{ data.price }}</td>
                                                <td data-label="Data">{{ data.dateHour }}</td>
                                                <td data-label="Ações">
                                                    <button-submit @click="editPay(data)" icon="fa-solid fa-pen-to-square" class="bg-success border-success d-inline-block"/>
                                                    <button-delete @click="destroyDiscountPayment(data)"/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                        <table class="table table-striped mb-4">
                                            <tbody class="table-dark">
                                            <tr>
                                                <td>Total OS: R$ {{ formData.totalOS }}</td>
                                                <td>Total Desconto: R$ {{ formData.totalPaymentDiscount }}</td>
                                                <td>Subtotal: R${{ formData.totalOSDiscount }}</td>
                                                <td colspan="2">Total Pagamento: R$ {{ formData.totalPaymentForm }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="tab-item-3">
                    <div class="clearfix my-3">
                        <button-submit v-if="formData.send.length === 0" @click="routeAddShipping" class="float-end" icon="fa-solid fa-plus" name="Adicionar"/>
                        <button-submit v-else @click="routeEditShipping" class="float-end" icon="fa-solid fa-pen-to-square" name="Editar"/>
                    </div>

                    <div class="alert alert-secondary my-3" role="alert" v-if="formData.send.length === 0">
                        Nenhum banho cadastrado
                    </div>

                    <table class="table table-striped" v-for="(data, index) in send" :key="index">
                        <thead>
                        <tr>
                            <th colspan="2" scope="col" style="width: 20%">Tipo de Envio</th>
                            <th colspan="2" scope="col" style="width: 20%">Envio</th>
                            <th colspan="2" scope="col" style="width: 20%">Preço</th>
                            <th colspan="2" scope="col" style="width: 20%">Peso</th>
                        </tr>
                        </thead>
                        <tbody class="table-group-divider">
                        <tr>
                            <td colspan="2" data-label="Tipo de Envio">{{ deliveryTypeString(data.type) }}</td>
                            <td colspan="2" data-label="Envio">{{ deliveryString(data.send) }}</td>
                            <td colspan="2" data-label="Preço">R$ {{ data.price }}</td>
                            <td colspan="2" data-label="Peso">{{ data.kilo }}</td>
                        </tr>
                        </tbody>
                        <tfoot v-if="formData.send.address" style="background-color: rgba(0, 0, 0, 0.05);">
                        <tr>
                            <th scope="col" style="width: 10%">{{ data.cep }}</th>
                            <th scope="col" style="width: 10%">{{ data.city }} - {{ data.state }}</th>
                            <th scope="col" style="width: 20%">{{ data.district }}</th>
                            <th scope="col" colspan="2" style="width: 20%">{{ data.address }}</th>
                            <th scope="col" style="width: 10%">{{ data.number }}</th>
                            <th scope="col" style="width: 10%">{{ data.country }}</th>
                        </tr>
                        </tfoot>
                    </table>
                </section>

                <section id="tab-item-4">
                    <div class="p-3 mb-5">
                        <label>Observações:</label>
                        <div class="clearfix">
                            <button-submit @click="addObs(1)" class="float-end" icon="fa-solid fa-plus" name="Adicionar"/>
                        </div>

                        <div v-if="formData.obsOs.length === 0" class="alert alert-secondary my-3" role="alert">
                            Nenhuma observação cadastrada
                        </div>

                        <div v-else style="height: 450px" class="overflow-y-scroll bg-body-secondary p-3 my-3">
                            <div class="bg-white p-3 my-3" v-for="(data, index) in formData.obsOs" :key="index">
                                <row-component>
                                    <row-col-component
                                            class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="mb-3">
                                            <img v-if="data.photo" width="35" class="img-fluid rounded-circle" :src="data.photo" alt="">
                                            <img v-else width="35" class="img-fluid rounded-circle" src="@/assets/avatar.png" alt="">
                                            {{ data.creator_user_name }} - {{ data.date_create }} {{ data.hour_create }}
                                        </div>
                                        <p>{{ data.obs }}</p>
                                    </row-col-component>
                                </row-component>
                            </div>
                        </div>

                        <hr>

                        <label>Observações Internas:</label>
                        <div class="clearfix">
                            <button-submit @click="addObs(2)" class="float-end" icon="fa-solid fa-plus" name="Adicionar"/>
                        </div>

                        <div v-if="formData.obsInternal.length === 0" class="alert alert-secondary my-3" role="alert">
                            Nenhuma observação interna cadastrada
                        </div>

                        <div v-else style="height: 450px" class="overflow-y-scroll bg-body-secondary p-3 my-3">
                            <div class="bg-white p-3 my-3" v-for="(data, index) in formData.obsInternal" :key="index">
                                <row-component>
                                    <row-col-component
                                            class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="mb-3">
                                            <img v-if="data.photo" width="35" class="img-fluid rounded-circle" :src="data.photo" alt="">
                                            <img v-else width="35" class="img-fluid rounded-circle" src="@/assets/avatar.png" alt="">
                                            {{ data.creator_user_name }} - {{ data.date_create }} {{ data.hour_create }}
                                        </div>
                                        <p>{{ data.obs }}</p>
                                    </row-col-component>
                                </row-component>
                            </div>
                        </div>

                        <label>Histórico:</label>

                        <div class="alert alert-secondary alert-dismissible fade show"
                             v-if="formData.historics.length === 0">
                            Não há nenhum histórico
                        </div>

                        <div v-else style="height: 450px" class="overflow-y-scroll bg-body-secondary p-3 my-3">
                            <div class="card p-3 bg-light my-3 overflow-auto"
                                 v-for="(data, index) in formData.historics"
                                 :key="index">
                                <div>
                                    <img v-if="data.user_photo" width="25" class="mx-1 rounded-circle" :src="data.user_photo" alt="">
                                    <img v-else width="25" class="mx-1 rounded-circle" src="@/assets/avatar.png" alt="">
                                    {{ data.name_user }}

                                    <p class="d-inline-block mx-3">
                                        <i class="fa-solid fa-calendar"></i>
                                        {{ data.date_create }}
                                    </p>
                                    <p class="d-inline-block mx-3">
                                        <i class="fa-solid fa-clock"></i>
                                        {{ data.hour_create }}
                                    </p>
                                </div>
                                <p>{{ statusHistoric(data.status) }},
                                    Plataforma: {{ data.platform }},
                                    Navegador: {{ data.browser }},
                                    Endereço IP: {{ data.ip }}</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import InputComponent from "@/components/forms/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/forms/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {useToast} from "vue-toastification";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import OSUpdateBath from "@/views/update/OSUpdateBath";
import OSUpdateDiscountPayment from "@/views/update/OSUpdateDiscountPayment";
import swal from "sweetalert";
import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import axios from "axios";
import VueEasyLightbox from 'vue-easy-lightbox'

const toast = useToast()
import { ref } from 'vue';
import OSCreateDiscountPayment from "@/views/create/OSCreateDiscountPayment.vue";


export default {
    name: 'OSUpdate',
    components: {
        OSCreateDiscountPayment,
        OSUpdateDiscountPayment,
        OSUpdateBath,
        ButtonDelete,
        ErrorComponent,
        ButtonSubmit,
        TextArea,
        Multiselect,
        RowColComponent,
        RowComponent,
        InputComponent,
        PainelComponent,
        VueEasyLightbox
    },

    directives: {
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'OS Editar')
        this.checkACL()
        this.loadItems()
    },

    setup() {
        const visibleRef = ref(false)
        const indexRef = ref(0)
        const imgs = []
        const showImg = (index) => {
            indexRef.value = index
            visibleRef.value = true
        }
        const onHide = () => visibleRef.value = false
        return {
            visibleRef,
            indexRef,
            imgs,
            showImg,
            onHide,
        }
    },

    data() {
        return {
            formData: {
                os: '',
                client_id: '',
                payment_date: '',
                table: '',
                status: '',
                code_url: '',
                historics: [],
                baths: [],
                obsOs: [],
                bathsClose: [],
                obsInternal: [],
                paymentsForm: [],
                send: [],
                serviceAdds: [],
                bathServicesAdd: "",
                bathsLoss: "",
                paymentsDiscount: [],
                urlPrivate: '',
                pesoTotal: '',
                tableOs: '',
                urlPrintOS: '',
                priceOuro: '',
                client_name: '',
                date_hour_create: '',
                urlPrintOSBath: '',
                urlPrintOSCataloging: '',
                downloadPhotoZip: '',
                urlPrintOSEditableCataloging: '',
                photosModal: []
            },

            money: MONEY,

            add_obs: {
                id: this.id,
                obs: ''
            },

            obs: false,
            obsInt: false,
            obs_modal: false,

            edit_table: {
                id: this.id,
                table: ''
            },

            table_modal: false,

            bath_photo: {
                id: '',
                photo: []
            },

            bath_items: [],
            bath_modal: false,

            pay_items: [],
            pay_modal: false,
            pay_modal_create: false,

            copy: false,

            send: [],

            index: "",

            sector_modal: false,
            sectors: [
                {name: "Enviar para: cadastrado", id: 1},
                {name: "Enviar para: cliente", id: 2},
                {name: "Enviar para: separação", id: 4},
                {name: "Enviar para: amarração", id: 5},
                {name: "Enviar para: banho", id: 6},
                {name: "Enviar para: inspeção", id: 7},
                {name: "Enviar para: o envio", id: 8},
                {name: "Enviar para: fechamento", id: 9}
            ],

            close_modal: false,
            number: "",
            close_data: {
                id: "",
                the_amount_broked: "",
                the_amount_missing_stones: "",
                kilo_of_bath: "",
                miss: ""
            },

            additional_services_modal: false,
            store_service: true,
            add_services: {
                id: this.id,
                service_id: "",
                the_amounnt: "",
                price: "",
                price_total: ""
            },

            multiselectGroupMaster:[],
            group_cataloging_inspection: false,
            group_cataloging_closure: false,
            group_cataloging_editable: false,
            group_cataloging: false,

            os_additional: {
                show: false,
                errors: [],
                showError: false,
                edit: false,
                formData: {
                    bath_service_id: "",
                    the_amount_rhodium_pen: 0,
                    price_rhodium_pen: "",
                    price_result_rhodium_pen: "",
                    the_amount_collage: 0,
                    price_collage: "",
                    price_result_collage: "",
                    the_amount_resin: 0,
                    price_resin: "",
                    price_result_resin: "",
                    the_amount_repair_others: 0,
                    price_repair_others: "",
                    price_result_repair_others: ""
                }
            },

            errors: [],
            sendForm: false,
            viewPage: false,
            showError: false
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadOrderService', this.id)
                .then((r) => {
                    this.formData = r
                    this.loadSend(r.send)
                    this.$store.dispatch('loadMultiSelectClassification')
                    this.$store.dispatch('loadMultiSelectService', 2)
                    this.multiselectBathMasterFunction()
                    this.scrollSection()
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        scrollSection() {
            if (this.checkedTab === true) {
                setTimeout(() => {
                    window.location.href = `#services${this.section_id}`
                    this.$store.commit("SECTION_ID", "")
                }, 1000)
            }
        },

        loadSend(data) {
            if (data.address)
                return this.send.push({...data.send, ...data.address[0]})
            return this.send.push(data)
        },

        loadBathClose(id) {
            this.$store.dispatch("loadOrderServiceBathServiceClose", id)
                .then((r) => {
                    this.close_data = r
                    this.close_data.id = id
                    this.close_modal = true
                }).catch(() => {
                toast.error('Erro desconhecido!', {timeout: 1500})
            })
        },

        submitClose() {
            this.$store.dispatch('updateOrderServiceBathServiceClose', this.close_data)
                .then(() => {
                    this.showError = false
                    this.errors = []
                    this.loadItems()
                    this.close_modal = false
                    toast.success('Atualizado com sucesso', {timeout: 1500})
                }).catch((e) => {
                this.showError = true
                this.errors = e.response.data.errors
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateProduct', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro atualizado com sucesso', {timeout: 1500})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 18)
                .then((r) => {
                    if (r.data.indexOf('order-service-edit') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        statusString(value) {
            switch (parseInt(value)) {
                case 1:
                    return 'Cadastrado';
                case 2:
                    return 'Enviado Para Cliente';
                case 3:
                    return 'Aguardando Aprovação Iwassa';
                case 4:
                    return 'Separação';
                case 5:
                    return 'Amarração';
                case 6:
                    return 'Banho';
                case 7:
                    return 'Inspeção';
                case 8:
                    return 'Envio';
                case 9:
                    return 'Fechamento';
                case 10:
                    return 'Finalizado';
                case 11:
                    return 'Aprovado pelo Cliente';
                default:
                    break;
            }
        },

        deliveryTypeString(value) {
            switch (parseInt(value)) {
                case 1:
                    return 'Retirar no local';
                case 2:
                    return 'Endereço de cadastro';
                case 3:
                    return 'Novo Endereço';
                default:
                    break;
            }
        },

        deliveryString(value) {
            switch (parseInt(value)) {
                case 1:
                    return 'Retirar no local';
                case 2:
                    return 'Moto Boy';
                case 3:
                    return 'Sedex';
                case 4:
                    return 'PAC';
                default:
                    break;
            }
        },

        statusHistoric(value) {
            switch (parseInt(value)) {
                case 1:
                    return 'Cadastrado';
                case 2:
                    return 'Enviado Para Cliente';
                case 3:
                    return 'Aguardando Aprovação Iwassa';
                case 4:
                    return 'Separação';
                case 5:
                    return 'Amarração';
                case 6:
                    return 'Banho';
                case 7:
                    return 'Inspeção';
                case 8:
                    return 'Envio';
                case 9:
                    return 'Fechamento';
                case 10:
                    return 'Finalizado';
                case 11:
                    return 'Aprovado pelo Cliente';
                default:
                    break;
            }
        },

        paymentsString(value) {
            if (parseInt(value) === 1)
                return 'Desconto'
            return 'Forma de pagamento'
        },

        routeAdd() {
            this.$router.push({name: 'os-create'})
        },

        routeSearch() {
            this.$router.push({name: 'os-search'})
        },

        routeAddBath() {
            this.$router.push({name: 'os-bath-create', params: {id: this.id}})
        },

        routeAddShipping() {
            this.$router.push({name: 'os-shipping-create', params: {id: this.id}})
        },

        routeEditShipping() {
            this.$router.push({name: 'os-shipping-edit', params: {id: this.id}})
        },

        routeAddBathService(id) {
            this.$router.push({
                name: 'os-bath-service-create',
                params: {id: this.id, id_bath: id, id_table: this.formData.table}
            })
        },

        printOS() {
            let link = this.formData.urlPrintOS
            window.open(link, '_blank')
        },

        copyLink(link) {
            this.$refs.select.focus
            navigator.clipboard.writeText(link)
            this.copy = true
            setTimeout(() => {
                this.copy = false
            }, 1500)
        },

        submitObs() {
            this.$store.dispatch('storeOrderServiceObs', this.add_obs)
                .then(() => {
                    toast.success('Observação adicionada com sucesso', {timeout: 1500})
                    this.loadItems()
                    this.clsObs()
                    this.add_obs.obs = ''
                }).catch(() => {
                toast.error('Erro ao adicionar observação', {timeout: 1500})
                this.loadItems()
                this.clsObs()
                this.add_obs.obs = ''
            })
        },

        submitObsInt() {
            this.$store.dispatch('storeOrderServiceObsInt', this.add_obs)
                .then(() => {
                    toast.success('Observação interna adicionada com sucesso', {timeout: 1500})
                    this.loadItems()
                    this.clsObs()
                    this.add_obs.obs = ''
                }).catch(() => {
                toast.error('Erro ao adicionar observação interna', {timeout: 1500})
                this.loadItems()
                this.clsObs()
                this.add_obs.obs = ''
            })
        },

        submitTable() {
            this.$store.dispatch('updateOrderServiceTable', this.edit_table)
                .then(() => {
                    toast.success('Tabela atualizada com sucesso', {timeout: 1500})
                    this.loadItems()
                    this.table_modal = false
                }).catch(() => {
                toast.error('Erro ao atualizar tabela', {timeout: 1500})
                this.loadItems()
                this.table_modal = false
            })
        },

        getIdBath(id) {
            this.bath_photo.id = id
        },

        onFileChange(event) {
            let files = event.target.files || event.dataTransfer.files
            if (!files.length)
                return
            this.bath_photo.photo = files[0]
            this.submitPhotoBath()
        },

        submitPhotoBath() {
            this.$store.dispatch('updateOrderServiceBathPhoto', this.bath_photo)
                .then(() => {
                    this.loadItems()
                    this.bath_photo.id = ""
                    this.bath_photo.photo = ""
                    toast.success("Foto atualizada com sucesso", {timeout: 2000});
                }).catch(() => {
                this.loadItems()
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        destroyDiscountPayment(item) {
            let params = {id: this.id, id_pay: item.id}

            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar este item, ${this.me.name} ? `,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyOrderServiceDiscountPayment', params).then(() => {
                        this.loadItems()
                        toast.success('Cadastro deletado com sucesso', {timeout: 1500});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1500});
                    })
                }
            });
        },

        destroyBath(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar este item, ${this.me.name} ?`,
                closeOnClickOutside: false,
                content: {
                    element: "img",
                    attributes: {
                        src: item.photo
                    }
                },
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyOrderServiceDeleteBath', item.id).then(() => {
                        this.loadItems()
                        toast.success('Cadastro deletado com sucesso', {timeout: 1500});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1500});
                    })
                }
            });
        },

        destroyBathService(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar este serviço, ${item.name_service} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyOrderServiceDeleteBathService', item.id).then(() => {
                        this.loadItems()
                        toast.success('Cadastro deletado com sucesso', {timeout: 1500});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1500});
                    })
                }
            });
        },

        addObs(type) {
            this.obs_modal = true

            if (type === 1)
                this.obs = true

            if (type === 2)
                this.obsInt = true
        },

        clsObs() {
            this.obs_modal = false
            this.obs = false
            this.obsInt = false
        },

        editTable() {
            this.table_modal = true
            this.edit_table.table = this.formData.table
        },

        editBath(items) {
            this.bath_items = items
            this.bath_modal = true
        },

        closeBath() {
            this.bath_modal = false
            this.bath_items = []
            this.loadItems()
        },

        createPay(){
            this.pay_modal_create = true
        },

        editPay(items) {
            this.pay_items = items
            this.pay_modal = true
        },

        closePay() {
            this.pay_modal = false
            this.pay_items = []
            this.loadItems()
        },

        closePayCreate() {
            this.pay_modal_create = false
            this.loadItems()
        },

        sectorModal() {
            this.sector_modal = !this.sector_modal
        },

        submitStatus(status) {
            let params = {
                id: this.id,
                status: status
            }

            this.$store.dispatch('updateOrderServiceUpdateStatus', params)
                .then(() => {
                    this.showError = false
                    this.errors = []
                    this.loadItems()
                    this.sector_modal = false
                    toast.success('Status atualizado com sucesso', {timeout: 1500})
                }).catch((e) => {
                this.showError = true
                this.errors = e.response.data.errors
            })
        },

        closeAddService() {
            this.add_services.id = this.id
            this.add_services.price = ""
            this.add_services.price_total = ""
            this.add_services.the_amounnt = ""
            this.add_services.service_id = ""
            this.additional_services_modal = false
            this.store_service = true
        },

        editAdditionalService(data) {
            this.add_services.id = data.id
            this.add_services.price = data.price
            this.add_services.price_total = data.price_total
            this.add_services.the_amounnt = data.the_amounnt
            this.add_services.service_id = data.service_id
            this.additional_services_modal = true
            this.store_service = false
        },

        loadPriceService(id) {
            this.$store.dispatch("loadGetServicePrice", id)
                .then((r) => {
                    this.add_services.price = r.price
                })
        },

        sumAddService() {
            let price = this.add_services.price
            let amount = this.add_services.the_amounnt

            price = price.replace(/\./g, '').replace(',', '.')

            return this.add_services.price_total = (price * amount).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },

        submitAdditionalService() {
            this.$store.dispatch('storeOrderServiceAdditional', this.add_services)
                .then(() => {
                    toast.success('Serviço adicional cadastrado com sucesso', {timeout: 1500})
                    this.loadItems()
                    this.additional_services_modal = false
                }).catch(() => {
                toast.error('Erro ao adicionar cadastro', {timeout: 1500})
                this.loadItems()
                this.additional_services_modal = false
            })
        },

        submitAdditionalServiceEdit() {
            this.$store.dispatch('updateOrderServiceAdditional', this.add_services)
                .then(() => {
                    toast.success('Serviço adicional atualizado com sucesso', {timeout: 1500})
                    this.loadItems()
                    this.additional_services_modal = false
                }).catch(() => {
                toast.error('Erro ao atualizar cadastro', {timeout: 1500})
                this.loadItems()
                this.additional_services_modal = false
            })
        },

        destroyAdditionalService(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar este item, ${this.me.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyOrderServiceAdditional', item.id).then(() => {
                        this.loadItems()
                        toast.success('Cadastro deletado com sucesso', {timeout: 1500});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1500});
                    })
                }
            });
        },

        sumSub(a, b) {
            a = parseFloat(a.replace(/\./g, '').replace(',', '.'))
            b = parseFloat(b.replace(/\./g, '').replace(',', '.'))
            let result = a - b

            return Math.abs(result).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },

        sumPayments(params) {
            let values = params
            let sum = 0
            for (let i = 0; i < values.length; i++) {
                let price = parseFloat(values[i].price.replace(/\./g, '').replace(',', '.'))
                sum += price
            }

            return sum.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },

        sumOs(params) {
            let service = []
            let sum = 0

            params.forEach((element) => {
                if (element.services.length > 0)
                    service = element.services
            })

            for (let i = 0; i < service.length; i++) {
                if (service.length <= 1) {
                    sum = service[i].price_subtotal
                } else {
                    sum += parseFloat(service[i].price_subtotal.replace(/\./g, '').replace(',', '.'))
                }
            }

            return sum.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },

        downloadAll() {
            let url = this.formData.downloadPhotoZip
            window.open(url, "_blank")
        },

        handleClick() {
            const textValue = this.$refs.GroupCatalogingEditable.textValue;
            const group = this.multiselectGroupMaster[0];

            if (textValue) {
                const selectedValues = textValue.split(',');
                const allSelected = selectedValues.length === group.options.length;
                group.label = allSelected ? "Desselecionar todos" : "Selecionar todos";
            } else {
                group.label = "Selecionar todos"
            }
        },

        handleClickTwo() {
            const textValue = this.$refs.GroupCataloging.textValue;
            const group = this.multiselectGroupMaster[0];

            if (textValue) {
                const selectedValues = textValue.split(',');
                const allSelected = selectedValues.length === group.options.length;
                group.label = allSelected ? "Desselecionar todos" : "Selecionar todos";
            } else {
                group.label = "Selecionar todos"
            }
        },

        handleClickThree() {
            const textValue = this.$refs.GroupCatalogingClosure.textValue;
            const group = this.multiselectGroupMaster[0];

            if (textValue) {
                const selectedValues = textValue.split(',');
                const allSelected = selectedValues.length === group.options.length;
                group.label = allSelected ? "Desselecionar todos" : "Selecionar todos";
            } else {
                group.label = "Selecionar todos"
            }
        },

        handleClickFour() {
            const textValue = this.$refs.GroupCatalogingInspection.textValue;
            const group = this.multiselectGroupMaster[0];

            if (textValue) {
                const selectedValues = textValue.split(',');
                const allSelected = selectedValues.length === group.options.length;
                group.label = allSelected ? "Desselecionar todos" : "Selecionar todos";
            } else {
                group.label = "Selecionar todos"
            }
        },

        groupCatalogingEditClose(){
            this.group_cataloging_editable = false
            this.$refs.GroupCatalogingEditable.textValue = ref(null)
            this.handleClick()
        },

        printGroupCatalogingEditable(groups) {
            let formData = {
                id: this.id,
                code: this.formData.code_url,
                groups: groups
            }

            const BASE_URL = axios.defaults.baseURL
            const RESOURCE = `/private/order-service-print-os-editable-cataloging-group/`
            const URL = `${BASE_URL}${RESOURCE}${formData.id}/${formData.code}?groups=${formData.groups}`

            window.open(URL, "_blank")
            this.group_cataloging_editable = false
        },

        groupCatalogingClose(){
            this.group_cataloging = false
            this.$refs.GroupCataloging.textValue = ref(null)
            this.handleClickTwo()
        },

        printGroupCataloging(groups) {
            let formData = {
                id: this.id,
                code: this.formData.code_url,
                groups: groups
            }

            const BASE_URL = axios.defaults.baseURL
            const RESOURCE = `/private/order-service-print-os-cataloging-group/`
            const URL = `${BASE_URL}${RESOURCE}${formData.id}/${formData.code}?groups=${formData.groups}`

            window.open(URL, "_blank")
            this.group_cataloging = false
        },


        groupCatalogingClosureClose(){
            this.group_cataloging_closure = false
            this.$refs.GroupCatalogingClosure.textValue = ref(null)
            this.handleClickThree()
        },

        printGroupCatalogingClosure(groups) {
            let formData = {
                id: this.id,
                code: this.formData.code_url,
                groups: groups
            }

            const BASE_URL = axios.defaults.baseURL
            const RESOURCE = `/private/order-service-print-os-cataloging-closure-group/`
            const URL = `${BASE_URL}${RESOURCE}${formData.id}/${formData.code}?groups=${formData.groups}`

            window.open(URL, "_blank")
            this.group_cataloging_closure = false
        },

        groupCatalogingInspectionClose(){
            this.group_cataloging_inspection = false
            this.$refs.GroupCatalogingInspection.textValue = ref(null)
            this.handleClickFour()
        },

        printGroupCatalogingInspection(groups) {
            let formData = {
                id: this.id,
                code: this.formData.code_url,
                groups: groups
            }

            const BASE_URL = axios.defaults.baseURL
            const RESOURCE = `/private/order-service-print-os-cataloging-inspection-group/`
            const URL = `${BASE_URL}${RESOURCE}${formData.id}/${formData.code}?groups=${formData.groups}`

            window.open(URL, "_blank")
            this.group_cataloging_inspection = false
        },

        routeStockBathOS() {
            this.$router.push({name: "stock-create-bath-os", params: {id: this.id, id_client: this.formData.client_id}})
        },

        routeServiceGroupOS() {
            this.$router.push({name: 'os-service-group-create', params: {id: this.id}})
        },

        calcAdditionalRhodium() {
            let price = this.os_additional.formData.price_rhodium_pen
            let amount = this.os_additional.formData.the_amount_rhodium_pen

            price = price.replace(/\./g, '').replace(',', '.')

            return this.os_additional.formData.price_result_rhodium_pen = (price * amount).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },

        calcAdditionalCollage() {
            let price = this.os_additional.formData.price_collage
            let amount = this.os_additional.formData.the_amount_collage

            price = price.replace(/\./g, '').replace(',', '.')

            return this.os_additional.formData.price_result_collage = (price * amount).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },

        calcAdditionalResin() {
            let price = this.os_additional.formData.price_resin
            let amount = this.os_additional.formData.the_amount_resin

            price = price.replace(/\./g, '').replace(',', '.')

            return this.os_additional.formData.price_result_resin = (price * amount).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },

        calcAdditionalRepair() {
            let price = this.os_additional.formData.price_repair_others
            let amount = this.os_additional.formData.the_amount_repair_others

            price = price.replace(/\./g, '').replace(',', '.')

            return this.os_additional.formData.price_result_repair_others = (price * amount).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        },

        osAdditionalClose() {
            this.os_additional.show = false
            this.os_additional.formData = {
                bath_service_id: "",
                the_amount_rhodium_pen: 0,
                price_rhodium_pen: "",
                price_result_rhodium_pen: "",
                the_amount_collage: 0,
                price_collage: "",
                price_result_collage: "",
                the_amount_resin: 0,
                price_resin: "",
                price_result_resin: "",
                the_amount_repair_others: 0,
                price_repair_others: "",
                price_result_repair_others: ""
            }
        },

        osAdditional(id) {
            this.os_additional.formData.bath_service_id = id
            this.os_additional.show = true
            this.$store.dispatch("loadOrderServiceBathAddServiceAdditionalGet")
                .then((r) => {
                    this.os_additional.formData.price_rhodium_pen = r.price_rhodium_pen
                    this.os_additional.formData.price_collage = r.price_collage
                    this.os_additional.formData.price_resin = r.price_resin
                    this.os_additional.formData.price_repair_others = r.price_repair_others
                })
        },

        osAdditionalEdit(id) {
            this.$store.dispatch("loadOrderServiceBathAddServiceAdditionalShow", id)
                .then((r) => {
                    this.os_additional.formData = r
                    this.os_additional.show = true
                    this.os_additional.edit = true
                })
        },

        storeAdditional() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeOrderServiceBathAddServiceAdditionalAdd', this.os_additional.formData)
                    .then(() => {
                        this.os_additional.showError = false
                        this.os_additional.show = false
                        this.sendForm = false
                        this.os_additional.errors = []
                        this.loadItems()
                        toast.success('Cadastro adicionado com sucesso', {timeout: 1500})
                    }).catch((e) => {
                    console.error(e)
                    this.os_additional.showError = true
                    this.sendForm = false
                    this.os_additional.errors = e.response.data.errors
                })
            }
        },

        updateAdditional() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('updateOrderServiceBathAddServiceAdditionalUpdate', this.os_additional.formData)
                    .then(() => {
                        this.os_additional.showError = false
                        this.os_additional.show = false
                        this.sendForm = false
                        this.os_additional.errors = []
                        this.loadItems()
                        toast.success('Cadastro atualizado com sucesso', {timeout: 1500})
                    }).catch((e) => {
                    console.error(e)
                    this.os_additional.showError = true
                    this.sendForm = false
                    this.os_additional.errors = e.response.data.errors
                })
            }
        },

        destroyAdditional(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar este item, ${this.me.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyOrderServiceBathAddServiceAdditionalDelete', item.id).then(() => {
                        this.loadItems()
                        toast.success('Cadastro deletado com sucesso', {timeout: 1500});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1500});
                    })
                }
            });
        },

        multiselectBathMasterFunction(){
            this.$store.dispatch('loadMultiSelectBathGroup')
                .then((response)=>{
                    this.multiselectGroupMaster.push({
                        label: "Selecionar tudo",
                        options: response
                    })
                })
        },

        destroyAllBath(item) {
            swal({
                title: 'Atenção!',
                text: `Iremos remover todos os serviços de todos as peças, deseja realmente prosseguir?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('orderServiceBathServiceDeleteAll', this.id).then(() => {
                        this.loadItems()
                        toast.success('Cadastro deletado com sucesso', {timeout: 1500});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1500});
                    })
                }
            });
        },
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        checkedTab() {
            return this.$store.state.var.checked
        },

        section_id() {
            return this.$store.state.var.section_id
        },

        multiselectTableService() {
            return this.$store.state.var.multiselectTableService
        },

        multiselectService() {
            return this.$store.state.var.multiselectService
        },

        multiselectGroupMasterComputed() {
            return this.multiselectGroupMaster
        },
    },
}
</script>

<style scoped>
html {
    scroll-behavior: smooth !important;
}

.tabs > input,
.tabs > span,
.effect-3 .line {
    width: calc(100% / 4);
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 25%;
}

#tab-3,
#tab-3 + span,
#tab-3:checked ~ .line{
    left: 50%;
}

#tab-4,
#tab-4 + span,
#tab-4:checked ~ .line{
    left: 75%;
}

.modal-window {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
}

.add-table,
.add-obs,
.send-sector,
.close-bath,
.additional-services,
.cataloguing {
    width: 50%;
    padding: 2%;
    position: relative;
    background-color: #FFFFFF;
    box-shadow: 5px 10px 12px 0 #A6A6A6;
    -webkit-box-shadow: 5px 10px 12px 0 #A6A6A6;
}

.cataloguing {
    width: 30%;
    height: auto;
}

.close-bath {
    width: 30%;
}

.edit-pay {
    width: 70%;
    height: auto;
}

.add-table .btn-close,
.add-obs .btn-close,
.send-sector .btn-close,
.close-bath .btn-close,
.add-email .btn-close,
.additional-services .btn-close,
.cataloguing .btn-close {
    top: 3%;
    right: 1%;
    position: absolute;
}

.avatar-form::before,
.avatar-form::after {
    display: none;
}

.avatar-form {
    width: 150px;
    height: 200px;
    border-radius: 0;
}

.btn-avatar-view {
    opacity: 0;
    width: 150px;
    z-index: 100;
    height: 200px;
    display: grid;
    position: absolute;
    place-items: center;
    transition: all 0.2s ease-in-out;
    background-color: rgba(0, 0, 0, 0.3);
}

.btn-avatar-view:hover {
    opacity: 1;
}

.camera {
    right: 0;
    bottom: -5px;
}

.table {
    --bs-table-bg: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;;
}

.card-count {
    width: 80px;
    height: 45px;
    margin: 1rem;
    color: #FFFFFF;
    padding: 5px 10px;
    font-weight: bold;
    background-color: #9FA692;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    background-color: #f0f0f0;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #FFFFFF !important;
    border-color: #9FA692 !important;
    background-color: #9FA692 !important;
    cursor: pointer;
}

.my-background {
    border-color: #9FA692;
    background-color: #9FA692;
}

.my-background:active,
.my-background:focus,
.my-background:hover {
    border-color: #9FA692 !important;
    background-color: #9FA692 !important;
}

.modal-w {
    width: 100vw;
    z-index: 101;
    height: 100vh;
    place-items: center;
    background: rgba(0, 0, 0, 0.7);
}

.modal-p {
    width: 50%;
    height: auto;
    overflow-y: auto;
    background: #FFFFFF;
}

.modal-p .btn-close {
    top: 10px;
    right: 20px;
    position: absolute;
}

@media (max-width: 991px) {
    .edit-bath {
        height: 100%;
        overflow: auto;
    }

    .avatar-form {
        height: 150px;
        margin: 20px 0;
    }

    .modal-p {
        width: 80%;
        height: 800px;
        overflow-y: auto;
    }
}

@media (max-width: 768px) {
    .add-table,
    .add-obs,
    .edit-pay,
    .close-bath,
    .send-sector,
    .cataloguing {
        width: 70%;
    }
}

@media (max-width: 430px) {
    .avatar-form {
        height: 150px;
        margin: 20px 0;
    }

    .camera {
        bottom: -15px;
    }

    .avatar-form img {
        height: 150px;
        display: block;
    }

    .add-table,
    .add-obs,
    .edit-pay,
    .close-bath,
    .cataloguing {
        width: 95%;
    }

    .modal-p {
        width: 95%;
    }

    .send-sector {
        width: 95%;
        padding: 10% 2%;
    }
}
</style>