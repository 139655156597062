<template>
    <div class="modal-window top-50 start-50 translate-middle" v-if="modal">
        <div role="button" class="btn-close" @click="modal = !modal"></div>

        <row-component>
            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <div class="form-check form-check-inline">
                    <input v-model="formDataClient.type" class="form-check-input" type="radio" value="1" name="docs"
                           id="type-one">
                    <label class="form-check-label" for="type-one">CPF</label>
                </div>
                <div class="form-check form-check-inline">
                    <input v-model="formDataClient.type" class="form-check-input" type="radio" value="2" name="docs"
                           id="type-two" checked>
                    <label class="form-check-label" for="type-two">CNPJ</label>
                </div>
            </row-col-component>
        </row-component>

        <row-component>
            <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <label>Nome:</label>
                <input-component v-model="formDataClient.name" :type="'text'"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                               v-if="formDataClient.type === '2'">
                <label>CNPJ:</label>
                <input-component v-model="formDataClient.cnpj" :type="'text'"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" v-else>
                <label>CPF:</label>
                <input-component v-model="formDataClient.cpf" :type="'text'"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-0 mt-lg-4">
                <button-submit @submit="submitSearch" icon="fa-solid fa-magnifying-glass"/>
            </row-col-component>
        </row-component>

        <div class="mt-4">
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   v-for="(data, index) in order_service_client.data" :key="index">
                    <div class="d-flex align-items-center bg-body-secondary p-2 position-relative">
                        <div class="h-100 avatar__image__div">
                            <img class="avatar__image" v-if="data.photo" :src="data.photo" alt="">
                            <img class="avatar__image" v-else src="@/assets/avatar.png" alt="">
                        </div>
                        <div class="ms-3 w-75">
                            <p class="mb-1 text-truncate">{{ data.name }}</p>
                            <p class="mb-1">{{ data.cnpj }} {{ data.cpf }}</p>
                        </div>
                        <div class="position-absolute end-0 me-1 me-lg-3">
                            <button-submit @click="selectClient(data)" icon="fa-solid fa-plus"/>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <button-pagination :pagination="order_service_client" :offset="3" @paginate="loadItems"/>
        </div>
    </div>

    <painel-component>
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>OS:</label>
                    <input-component v-model="formData.os" placeholder="OS"/>
                </row-col-component>

                <!--<row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" placeholder="Nome"/>
                </row-col-component>-->

                <row-col-component class-row-col="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                    <label>Cliente:</label>
                    <div class="input-group mb-3" style="height: 42px">
                        <input v-model="name" disabled type="text" class="form-control" placeholder="Cliente">
                        <button-submit @click="modal = true" icon="fa-solid fa-magnifying-glass"/>
                    </div>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="multiselectStatusOS" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true" placeholder="Status"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submit" class="me-1" name="Pesquisar" icon="fa-solid fa-magnifying-glass"/>
                    <button-route v-if="buttonCheck.create" class="ms-1" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>

        <table class="table mt-3">
            <thead>
            <tr class="border-top">
                <th scope="col" style="width: 20%">OS</th>
                <th scope="col" style="width: 25%">Cliente</th>
                <th scope="col" style="width: 20%">Status</th>
                <th scope="col" style="width: 10%" v-if="buttonCheck.edit || buttonCheck.delete">Ações</th>
            </tr>
            </thead>
            <tbody class="table-group-divider">
            <tr v-for="(data, index) in order_service.data" :key="index">
                <td data-label="OS">{{ data.os }}</td>
                <td data-label="Cliente">{{ data.name_client }}</td>
                <td data-label="Status">{{ statusString(data.status) }}</td>
                <td data-label="Ações" v-if="buttonCheck.edit || buttonCheck.delete">
                    <button-edit v-if="buttonCheck.edit" route="os-edit" :id="data.id"/>
                    <button-delete v-if="buttonCheck.delete" @click="destroy(data)"/>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="order_service" :offset="3" @paginate="loadItems"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import ButtonEdit from "@/components/buttons/ButtonEdit";
import Multiselect from "@vueform/multiselect";
import InputComponent from "@/components/forms/InputComponent";
import {useToast} from "vue-toastification";
import ButtonPagination from "@/components/buttons/ButtonPagination.vue";
import swal from "sweetalert";

const toast = useToast()
export default {
    name: 'OSSearch',
    components: {
        ButtonPagination,
        InputComponent,
        Multiselect,
        ButtonEdit,
        ButtonDelete,
        ButtonRoute,
        ButtonSubmit,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch('updateNamePage', 'OS Pesquisa')
        this.checkACL()
        this.loadItems(1)
        this.loadClients(1)
    },

    data() {
        return {
            itemsRoute: {
                name: 'Adicionar',
                route: '/os/create',
                icon: 'fa-solid fa-circle-plus'
            },

            formData: {
                os: '',
                client_id: '',
                status: ''
            },

            formDataClient: {
                page: '',
                type: '2',
                name: '',
                cnpj: '',
                cpf: ''
            },

            name: '',
            modal: false,

            buttonCheck: {
                create: false,
                edit: false,
                delete: false
            }
        }
    },

    methods: {

        submit() {
            this.loadItems(1)
            this.searchSubmit = true
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadOrderServices', {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data.length === 0) {
                                toast.info('Nada foi encontrado com order-service parâmetros informados!', {timeout: 1500})
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                })
            }
        },

        loadClients(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch('loadOrderServicesClients', {...this.params_two, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false
                            if (r.data.length === 0) {
                                toast.info('Nada foi encontrado com order-service parâmetros informados!', {timeout: 1500})
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                })
            }
        },

        destroy(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar OS: ${item.os}, ${this.me.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyOrderService', item.id).then(() => {
                        this.loadItems(1)
                        toast.success('Cadastro deletado com sucesso', {timeout: 1500});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar ${item.os} `, {timeout: 1500});
                    })
                }
            });
        },

        selectClient(data) {
            this.modal = false
            this.formData.client_id = data.id
            this.name = data.name
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 18)
                .then((r) => {
                    if (r.data.indexOf('order-service-search') !== -1) {

                        if (r.data.indexOf('order-service-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                        if (r.data.indexOf('order-service-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('order-service-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        statusString(value) {
            switch (parseInt(value)) {
                case 1:
                    return 'Cadastrado';
                case 2:
                    return 'Enviado Para Cliente';
                case 3:
                    return 'Aguardando Aprovação Iwassa';
                case 4:
                    return 'Separação';
                case 5:
                    return 'Amarração';
                case 6:
                    return 'Banho';
                case 7:
                    return 'Inspeção';
                case 8:
                    return 'Envio';
                case 9:
                    return 'Fechamento';
                case 10:
                    return 'Finalizado';
                case 11:
                    return 'Aprovado pelo Cliente';
                default:
                    break;
            }
        }
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        multiselectStatusOS() {
            return this.$store.state.var.multiselectStatusOS
        },

        order_service() {
            return this.$store.state.order_service.items
        },

        params() {
            return {
                page: this.order_service.current_page,
                os: this.formData.os,
                status: this.formData.status,
                client_id: this.formData.client_id,
            }
        },

        order_service_client() {
            return this.$store.state.order_service.clients
        },

        params_two() {
            return {
                page: this.order_service_client.current_page,
                type: this.formDataClient.type,
                name: this.formDataClient.name,
                cnpj: this.formDataClient.cnpj,
                cpf: this.formDataClient.cpf
            }
        }
    }
}
</script>

<style scoped>
.modal-window {
    z-index: 6;
    width: 80%;
    padding: 2%;
    height: 60%;
    overflow: auto;
    position: fixed;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 5px 10px 12px 0 #A6A6A6;
}

.btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}

.avatar__image__div {
    width: 10%;
}

@media (max-width: 991px) {
    .modal-window {
        width: 95%;
        height: 80%;
    }
}
</style>