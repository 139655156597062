<template>
    <button tooltip="Deletar" class="btn btn-danger mx-1">
        <i class="fa-solid fa-trash-can"></i>
    </button>
</template>

<script>
export default {
    name: 'ButtonDelete',
}
</script>