import AdminComponent from "@/components/dashboard/AdminComponent";
import ClientSearch from "@/views/search/ClientSearch";
import ClientCreate from "@/views/create/ClientCreate";
import ClientUpdate from "@/views/update/ClientUpdate";
import ClientUpdatePhoto from "@/views/update/ClientUpdatePhoto";

export default {
    path: '/client',
    component: AdminComponent,
    meta: {auth: true},
    children: [
        {path: 'search', name: 'client-search', component: ClientSearch,},
        {path: 'create', name: 'client-create', component: ClientCreate,},
        {path: 'edit/:id', name: 'client-edit', component: ClientUpdate, props: true},
        {path: 'photo/:id', name: 'client-edit-photo', component: ClientUpdatePhoto, props: true}
    ]
}