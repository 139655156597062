<template>
    <nav-component v-if="!currentPage"/>

    <div class="admin-component" v-if="!currentPage">
        <div class="top-container">
            <div class="w-50 d-flex align-items-center">
                <h1 class="text-uppercase m-0">{{ namePage }}</h1>
            </div>

            <div class="w-50">
                <div class="btn-group float-end" role="button">
                    <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        {{ me.name }}
                        <img v-if="me.photo" :src="me.photo" alt="">
                        <img v-else src="@/assets/avatar.png" alt="">
                    </div>
                    <ul class="dropdown-menu">
                        <li>
                            <router-link class="dropdown-item" :to="{name: 'user-edit-password'}">
                                <i class="fa-solid fa-user-pen"></i> Alterar Senha
                            </router-link>
                        </li>
                        <li>
                            <router-link class="dropdown-item" :to="{name: 'user-edit-photo'}">
                                <i class="fa-regular fa-images"></i> Alterar Foto
                            </router-link>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li>
                            <div @click.prevent="logout" class="dropdown-item">
                                <i class="fa-solid fa-right-from-bracket"></i> Sair
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <router-view/>
    </div>

    <router-view v-else/>
</template>

<script>
import NavComponent from "@/components/nav/NavComponent";
import swal from "sweetalert";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: 'AdminComponent',

    components: {
        NavComponent
    },

    methods: {

        logout() {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente sair do sistema ${this.me.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('logout')
                    toast.success("Logout efetuado com sucesso!", {timeout: 2000})
                    setTimeout(() => this.$router.push({name: 'login'}), 2200)
                }
            });
        },

    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        namePage() {
            return this.$store.state.var.namePage
        },

        currentPage() {
            return this.$route.name === 'login';
        }
    }
}
</script>

<style scoped>
.admin-component {
    float: right;
    height: 100vh;
    overflow: auto;
    background: #EDEDED;
    width: calc(100% - 300px);
}

.top-container {
    height: 85px;
    display: flex;
    padding: 1% 2%;
    background-color: #FFFFFF;
    box-shadow: 5px 10px 12px 0 #A6A6A6;
    -webkit-box-shadow: 5px 10px 12px 0 #A6A6A6;
}

.top-container div h1 {
    font-size: 26px;
}

.top-container .dropdown-toggle img {
    max-width: 50px;
    margin-left: 10px;
    border-radius: 50%;
    display: inline-block;
}

@media (max-width: 991px) {
    .admin-component {
        width: 100%;
    }
}

@media (max-width: 428px) {
    .top-container {
        padding: 2%;
        height: 50px;
    }

    .top-container .dropdown-toggle img {
        max-width: 35px;
    }

    .top-container div h1 {
        font-size: 13px;
    }
}
</style>