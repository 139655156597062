import actions from "@/store/modules/stock/actions";
import getters from "@/store/modules/stock/getters";
import mutations from "@/store/modules/stock/mutations";
import state from "@/store/modules/stock/state";

export default {
    actions,
    getters,
    mutations,
    state
}