<template>
    <painel-component>
        <error-component :errors="errors" v-if="showError"/>

        <form v-if="viewPage">
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.name}">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" :type="'text'"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.status}">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectStatus" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Observações:</label>
                    <text-area v-model="formData.obs" rows="5"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" @submit="submit" class="w-100" name="Adicionar"
                                   icon="fa-solid fa-circle-plus"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsRoute"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import InputComponent from "@/components/forms/InputComponent";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import RowColComponent from "@/components/row/RowColComponent";
import TextArea from "@/components/forms/TextArea";
import Multiselect from "@vueform/multiselect";
import {useToast} from "vue-toastification";
import ErrorComponent from "@/components/alerts/ErrorComponent";

const toast = useToast()
export default {
    name: 'BathGroupCreate',
    components: {
        ErrorComponent,
        Multiselect,
        TextArea,
        RowColComponent,
        ButtonSubmit,
        ButtonRoute,
        InputComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Grupo Banho Adicionar')
        this.checkACL()
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: '/bath-group/search',
                icon: 'fa-solid fa-rotate-left'
            },

            formData: {
                name: '',
                status: '',
                obs: ''
            },

            viewPage: false,

            errors: [],
            showError: false,
            sendForm: false
        }
    },

    methods: {

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeBathGroup', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro efetuado com sucesso', {timeout: 1500})
                        this.$router.push({name: 'bath-group-edit', params: {id: r}})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 6)
                .then((r) => {
                    if (r.data.indexOf('bath-group-create') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        selectStatus() {
            return this.$store.state.var.selectStatus
        },
    }
}
</script>