import actions from "@/store/modules/bath-group/actions";
import getters from "@/store/modules/bath-group/getters";
import mutations from "@/store/modules/bath-group/mutations";
import state from "@/store/modules/bath-group/state";

export default {
    actions,
    getters,
    mutations,
    state
}