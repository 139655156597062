<template>
    <painel-component>
        <error-component :errors="errors" v-if="showError"/>

        <div class="tabs effect-3" v-if="viewPage">
            <input type="radio" id="tab-1" name="tab-effect-3" checked="checked">
            <span>
                <i class="fa-solid fa-circle-info"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="fa-solid fa-envelope"></i>
                <p>Email</p>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3">
            <span>
                <i class="fa-solid fa-square-phone"></i>
                <p>Telefone</p>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3">
            <span>
                <i class="fa-solid fa-money-bill-1"></i>
                <p>Conta Bancária</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">

                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <row-col-component class-row-col="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                                <div class="form-check form-check-inline">
                                    <input v-model="formData.type" class="form-check-input" type="radio" value="1"
                                           name="doc" id="funcionario">
                                    <label class="form-check-label" for="funcionario">Funcionário</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input v-model="formData.type" class="form-check-input" type="radio" value="2"
                                           name="doc" id="pre-servico">
                                    <label class="form-check-label" for="pre-servico">Prestador de Serviço</label>
                                </div>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                                <label>Nome:</label>
                                <input-component v-model="formData.name" :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Sexo:</label>
                                <Multiselect v-model="formData.sexo" :options="multiselectSexo"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultText"
                                             :searchable="true"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>CPF:</label>
                                <input-component v-model="formData.cpf" v-mask="'###.###.###-##'" :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>RG:</label>
                                <input-component v-model="formData.rg" v-mask="['##.###.###-X', '#.###.###']"
                                                 :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Data Nasc:</label>
                                <input-component v-model="formData.birth_date" v-mask="'##/##/####'" :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Status:</label>
                                <Multiselect v-model="formData.status" :options="selectStatus"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultText"
                                             :searchable="true"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>CEP:</label>
                                <input-component @change="searchApiCEP" v-model="formData.cep" v-mask="'#####-###'"
                                                 :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Cidade:</label>
                                <input-component v-model="formData.city" :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Estado:</label>
                                <input-component v-model="formData.state" :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Bairro:</label>
                                <input-component v-model="formData.district" :type="'text'"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                                <label>Endereço:</label>
                                <input-component v-model="formData.address" :type="'text'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>País:</label>
                                <input-component v-model="formData.country" :type="'text'"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <label>Referência:</label>
                                <text-area v-model="formData.complement" rows="5"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="clearfix">
                        <button-submit @click="addEmails" class="float-end" icon="fa-solid fa-plus"/>
                    </div>
                    <div class="bg-body-secondary p-3 my-3" v-for="(data, index) in emailsAdd" :key="index">
                        <form>
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Receber notificação:</label>

                                    <div class="form-check form-check-inline ms-2">
                                        <input v-model="data.notification" class="form-check-input" type="radio"
                                               name="email" value="1" :id="'emailS' + index">
                                        <label class="form-check-label" :for="'emailS' + index">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-model="data.notification" class="form-check-input" type="radio"
                                               name="email" value="2" :id="'emailN' + index" checked>
                                        <label class="form-check-label" :for="'emailN' + index">Não</label>
                                    </div>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component v-model="data.name" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Email:</label>
                                    <input-component v-model="data.email" :type="'email'"/>
                                </row-col-component>
                            </row-component>
                        </form>
                        <div class="clearfix" v-if="emailsAdd.length > 1">
                            <button-submit @click="removeEmails(index)" class="float-end bg-danger border-0"
                                           icon="fa-solid fa-trash"/>
                        </div>
                    </div>
                </section>

                <section id="tab-item-3">
                    <div class="clearfix">
                        <button-submit @click="addPhones" class="float-end" icon="fa-solid fa-plus"/>
                    </div>
                    <div class="bg-body-secondary p-3 my-3" v-for="(data, index) in phonesAdd" :key="index">
                        <form>
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Receber notificação:</label>

                                    <div class="form-check form-check-inline ms-2">
                                        <input v-model="data.notification" class="form-check-input" type="radio"
                                               name="phone" value="1" :id="'phoneS' + index">
                                        <label class="form-check-label" :for="'phoneS' + index">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-model="data.notification" class="form-check-input" type="radio"
                                               name="phone" value="2"
                                               :id="'phoneN' + index" checked>
                                        <label class="form-check-label" :for="'phoneN' + index">Não</label>
                                    </div>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component v-model="data.name" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Telefone:</label>
                                    <input-component v-model="data.phone" v-mask="['(##) ####-####', '(##) #####-####']"
                                                     :type="'text'"/>
                                </row-col-component>
                            </row-component>
                        </form>
                        <div class="clearfix" v-if="phonesAdd.length > 1">
                            <button-submit @click="removePhones(index)" class="float-end bg-danger border-0"
                                           icon="fa-solid fa-trash"/>
                        </div>
                    </div>
                </section>

                <section id="tab-item-4">
                    <div class="clearfix">
                        <button-submit @click="addBank" class="float-end" icon="fa-solid fa-plus"/>
                    </div>
                    <div class="bg-body-secondary p-3 my-3" v-for="(data, index) in banksAdd" :key="index">
                        <form>
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Banco:</label>
                                    <Multiselect v-model="data.bank" :options="multiselectBankList" :searchable="true"
                                                 :noResultsText="noResultText" :noOptionsText="noOptionsText"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Tipo de Conta:</label>
                                    <Multiselect v-model="data.account_type" :options="multiselectAccountType"
                                                 :searchable="true" :noResultsText="noResultText"
                                                 :noOptionsText="noOptionsText"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Agência:</label>
                                    <input-component v-model="data.agency" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Conta/Dígito:</label>
                                    <input-component v-model="data.digit_account" :type="'text'"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <label>Tipo PIX:</label>
                                    <Multiselect v-model="data.pix_type" :options="multiselectPIX" :searchable="true"
                                                 :noResultsText="noResultText" :noOptionsText="noOptionsText"/>
                                </row-col-component>

                                <row-col-component v-if="data.pix_type === 1"
                                                   class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Chave:</label>
                                    <input-component v-model="data.key_pix" :type="'text'"
                                                     v-mask="['##.###.###/####-##','###.###.###-##']"/>
                                </row-col-component>

                                <row-col-component v-else-if="data.pix_type === 2"
                                                   class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Chave:</label>
                                    <input-component v-model="data.key_pix" :type="'text'"
                                                     v-mask="['(##) ####-####','(##) #####-####']"/>
                                </row-col-component>

                                <row-col-component v-else-if="data.pix_type === 3"
                                                   class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Chave:</label>
                                    <input-component :type="'email'"/>
                                </row-col-component>

                                <row-col-component v-else-if="data.pix_type === 4"
                                                   class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Chave:</label>
                                    <input-component v-model="data.key_pix" :type="'text'"/>
                                </row-col-component>

                                <row-col-component v-else
                                                   class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Chave:</label>
                                    <input-component v-model="data.key_pix" :type="'text'"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                    <div class="mt-4">
                                        <label>Conta Principal:</label>
                                        <div class="form-check form-check-inline ms-3">
                                            <input v-model="data.main_account" class="form-check-input" type="radio"
                                                   value="1" name="doc" :id="'nao' + index">
                                            <label class="form-check-label" :for="'nao' + index">Não</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input v-model="data.main_account" class="form-check-input" type="radio"
                                                   value="2" name="doc" :id="'sim' + index">
                                            <label class="form-check-label" :for="'sim' + index">Sim</label>
                                        </div>
                                    </div>
                                </row-col-component>
                            </row-component>
                        </form>
                        <div class="clearfix" v-if="banksAdd.length > 1">
                            <button-submit @click="removeBank(index)" class="float-end bg-danger border-0"
                                           icon="fa-solid fa-trash"/>
                        </div>
                    </div>
                </section>

                <row-component>
                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-submit :disabled="sendForm" @submit="submit" class="w-100" name="Adicionar"
                                       icon="fa-solid fa-circle-plus"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-route class="w-100" :items="itemsRoute"/>
                    </row-col-component>
                </row-component>
            </div>
        </div>
    </painel-component>
</template>

<script>
import InputComponent from "@/components/forms/InputComponent";
import Multiselect from "@vueform/multiselect";
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import TextArea from "@/components/forms/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import {mask} from 'vue-the-mask';
import {ESTADOS} from "@/configs/constants";
import ErrorComponent from "@/components/alerts/ErrorComponent.vue";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: 'EmployeeCreate',
    components: {
        ErrorComponent,
        ButtonRoute,
        ButtonSubmit,
        TextArea,
        InputComponent,
        Multiselect,
        PainelComponent,
        RowColComponent,
        RowComponent,
    },

    directives: {
        mask,
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Funcionário Adicionar')
        this.checkACL()
        this.nullInputArray()
    },

    data() {
        return {
            itemsRoute: {
                name: 'Voltar',
                route: '/employee/search',
                icon: 'fa-solid fa-rotate-left'
            },

            formData: {
                name: '',
                cpf: '',
                rg: '',
                birth_date: '',
                type: '1',
                status: '',
                sexo: '',
                emails: [],
                phones: [],
                bank_accounts: [],
                cep: '',
                city: '',
                state: '',
                district: '',
                address: '',
                complement: '',
                country: ''
            },

            viewPage: false,

            errors: [],
            showError: false,
            sendForm: false,
        }
    },

    methods: {

        submit() {
            if (!this.sendForm) {
                this.sendForm = true
                this.$store.dispatch('storeEmployee', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success('Cadastro efetuado com sucesso', {timeout: 1500})
                        this.$router.push({name: 'employee-edit', params: {id: r}})
                    }).catch((e) => {
                    this.showError = true
                    this.sendForm = false
                    this.errors = e.response.data.errors
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 5)
                .then((r) => {
                    if (r.data.indexOf('employee-create') !== -1) {
                        this.viewPage = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        searchApiCEP() {
            this.resetFormAddress()
            let cep = this.formData.cep.replace(/\D/g, '');
            const dateCEP = {'cep': cep}
            this.$store.dispatch('searchApiCEP', dateCEP)
                .then((r) => {
                    this.formData.city = r.data.localidade
                    this.formData.state = r.data.uf
                    this.formData.stateView = ESTADOS[r.data.uf]
                    this.formData.district = r.data.bairro
                    this.formData.address = r.data.logradouro
                    this.formData.complement = r.data.complemento
                }).catch(() => {
            })
        },

        resetFormAddress() {
            this.formData.city = ''
            this.formData.state = ''
            this.formData.stateView = ''
            this.formData.district = ''
            this.formData.address = ''
            this.formData.complement = ''
            this.formData.country = 'Brasil'
        },

        nullInputArray() {
            if (this.emailsAdd.length === 0)
                this.addEmails()
            if (this.phonesAdd.length === 0)
                this.addPhones()
            if (this.banksAdd.length === 0)
                this.addBank()
        },

        addEmails() {
            this.formData.emails.push({
                name: '',
                email: '',
                notification: '1'
            })
        },

        addPhones() {
            this.formData.phones.push({
                name: '',
                phone: '',
                notification: '1'
            })
        },

        addBank() {
            this.formData.bank_accounts.push({
                bank: '',
                account_type: '',
                agency: '',
                digit_account: '',
                pix_type: '',
                key_pix: '',
                main_account: '1'
            })
        },

        removeEmails(index) {
            this.formData.emails.splice(index, 1)
        },

        removePhones(index) {
            this.formData.phones.splice(index, 1)
        },

        removeBank(index) {
            this.formData.bank_accounts.splice(index, 1)
        }
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultText() {
            return this.$store.state.var.noResultText
        },

        selectStatus() {
            return this.$store.state.var.selectStatus
        },

        multiselectSexo() {
            return this.$store.state.var.multiselectSexo
        },

        multiselectBankList() {
            return this.$store.state.var.multiselectBankList
        },

        multiselectAccountType() {
            return this.$store.state.var.multiselectAccountType
        },

        multiselectPIX() {
            return this.$store.state.var.multiselectPIX
        },

        emailsAdd() {
            return this.formData.emails
        },

        phonesAdd() {
            return this.formData.phones
        },

        banksAdd() {
            return this.formData.bank_accounts
        },
    }
}
</script>

<style scoped>
.tabs > span,
.effect-3 .line {
    width: calc(100% / 4);
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 25%;
}

#tab-3,
#tab-3 + span,
#tab-3:checked ~ .line {
    left: 50%;
}

#tab-4,
#tab-4 + span,
#tab-4:checked ~ .line {
    left: 75%;
}
</style>